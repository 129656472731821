import types from "redux/types/types";

const initialState = {
    fetched: false,
    student: {},
    programas_inscrito: []
}

export const studentReducer = ( state= initialState, { type, payload }) =>{
    switch( type ){
        case types.setStudent: 
            return {
                ...state,
                ...payload
            }
        default: 
            return state;
    }

}