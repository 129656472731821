import axios from 'axios';
import { CREATE_CURSO } from "utils/endpoints"
import { GET_CURSOS_IN_PROGRAMA } from "utils/endpoints";
import { SEARCH_CURSO } from "utils/endpoints";

export const createCurso = ( body ) =>{
    return new Promise( (resolve, reject) => {
        axios.post( CREATE_CURSO, body)
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
}

export const getCursosInPrograma = ( programaId ) => {
    return new Promise( (resolve, reject ) => {
        axios.get( GET_CURSOS_IN_PROGRAMA + programaId)
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
}

export const searchCurso = ( body ) =>{
    const { clave, valor } = body; 
    return new Promise( (resolve, reject) => {
        axios.get( SEARCH_CURSO, {
            params: { clave, valor }
        })
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
}


export const searchCurso2 = ( searchBody ) => {
    const body = { clave: "codigo", valor: "DCS-12"}
    return new Promise( (resolve, reject) => {
        axios.get( SEARCH_CURSO, body)
        .then( res => {
            console.log( res )
            resolve( res )
        })
        .catch( err => {
            console.log( err) 
            reject( err )
        })
    })
}