import moment from 'moment'
import React from 'react'
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'

export const ResumenProceso = ({proceso, title, nombrePrograma, cantidadCursos = 0}) => {
  return (
    <Row>
      <Col md="12">
        <Card className="" >
          <CardHeader style={{ backgroundColor: '#efefef', borderColor: '#f7f7f7' }}>
                
            <Row>
              <Col xs="6" sm="6" md="8">
                <CardTitle className="p-0" tag="h5">{ title }</CardTitle>                   
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <label className="mx-5 pt-2 text-dark"> 
                <h6>Carrera: {nombrePrograma}</h6>
                <h6>Año: {proceso.anio}</h6>
                <h6>Semestre/trimestre: {proceso.semestre}</h6>
                <h6>Periodo: {proceso.periodo}</h6>
                { cantidadCursos > 0 &&
                  <h6>Cursos Asignables: {cantidadCursos}</h6>
                }
                <h6>Finaliza el: { moment(proceso.endAt).format("DD/MM/YYYY") }</h6>
            </label>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
