import axios from "axios";
import { INSCRIBIR_ESTUDIANTE } from "utils/endpoints";
import { GET_EXCEL_INSCRITOS_EN_PROGRAMA } from "utils/endpoints";
import { GET_INSCRITOS_EN_PROGRAMA } from "utils/endpoints";
import { CHANGE_INSCRIPCION_TO_ALL } from "utils/endpoints";
import { HABILITAR_INSCRIPCION_EN_PROGRAMA } from "utils/endpoints";
import moment from "moment";
import { GET_LAST_INSCRIPCION_ESTUDIANTE_BY_PROGRAMA } from "utils/endpoints";

export const habilitarInscripcionEnPrograma = ( id, activeInscripcion ) => {
    return new Promise( (resolve, reject) => {
        axios.patch( HABILITAR_INSCRIPCION_EN_PROGRAMA + id, { activeInscripcion })
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
}

export const changeInscripcionToAll = ( activeInscripcion ) => {
    return new Promise( (resolve, reject) => {
        axios.post( CHANGE_INSCRIPCION_TO_ALL + "?activeInscripcion=" + activeInscripcion )
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
}

//Obtener la info de la ultima inscripcion del estudiante en un programa
export const getInscripcionStudentByPrograma = ( studentId, programaId)=>{
    return new Promise( ( resolve, reject ) => {
        axios.get( GET_LAST_INSCRIPCION_ESTUDIANTE_BY_PROGRAMA, {
            params: { studentId, programaId }}).then( res => resolve(res))
        .catch( err => reject(err) )
    })
}

export const inscribirEstudiante = ( studentId, programaId, ciclo ) => {
    return new Promise( (resolve, reject ) => {
        axios.post( INSCRIBIR_ESTUDIANTE, 
            { studentId, programaId, ciclo }
        ).then( res => resolve(res))
        .catch( err => reject(err) )
    })
}

export const getInscritosEnPrograma = ( programaId, year, ciclo ) => {
    return new Promise( (resolve, reject ) => {
        axios.get( GET_INSCRITOS_EN_PROGRAMA, {
            params: { programaId, year, ciclo}
        }).then( res => resolve(res))
        .catch( err => reject(err))
    })
}


export const downloadExcelInscritoEnPrograma = ( programaNombre, programaId, year, ciclo )=>{
    return new Promise( (resolve, reject) => {
        axios.get( GET_EXCEL_INSCRITOS_EN_PROGRAMA, {
            responseType: 'blob',
            params:{ programaId, year, ciclo }
        }).then( response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Inscritos-${programaNombre}-${moment().format("DD/MM/YYYY")}.xlsx`); //or any other extension
            document.body.appendChild(link);
            link.click();
            resolve(response)
        })
        .catch( err => reject(err))
    });  
}