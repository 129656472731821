import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Row, Table } from 'reactstrap';
import { createAdmin, getAdmins } from 'redux/actions/admin.actions';
import { isObjEmpty } from 'utils/helpers';
import validator from 'validator';

export const Users = () => {

  const prevAdmin = { nombre:"", email:"", username:"", password: ""};

  const [ admin, setAdmin ] = useState( prevAdmin );
  const [ admins, setAdmins ] = useState([]);
  const [ errors, setErrors ] = useState({});
  const [ response, setResponse ] = useState({});


  useEffect( ()=>{
    getAdmins()
    .then( res => setAdmins( res.data.admins ))
    .catch( err => console.log(err))
  }, [])

  const onChange = ( e )=>{
    const { value, name } = e.target;
    setAdmin( ( prev ) => ({ ...prev, [name]: value }))
  }

  const handleSubmit = (e)=>{
    e.preventDefault();
    setErrors({});
    setResponse({});

    const errores = checkErrors();
    if( !isObjEmpty(errores) ){
      setErrors( errores );
      return;
    }
    
    const body = {
      nombre: admin.nombre,
      email: admin.email,
      user: {
        username: admin.username,
        password: admin.password,
        role: "role_admin"
      }
    }

    createAdmin( body )
    .then( res => setResponse({ type: "success", message: res.data.message } ))
    .catch( err => setResponse({ type: "danger", message: err.response.data.message }))

  }

  const checkErrors = ()=>{

    const errores = {};
    if( !validator.isLength( admin.nombre, { min:5, max:50 }) ){
      errores.nombre= "El nombre es obligatorio y debe tener al menos 5 caracteres y máximo 50.";
    }

    if( !validator.isLength( admin.username, { min:5, max:25 }) ){
      errores.username= "El username es obligatorio y debe tener al menos 5 caracteres y máximo 25.";
    }

    if( !validator.isLength( admin.password, { min:8, max:15 }) ){
      errores.password= "La contraseña es obligatoria y debe tener al menos 8 caracteres y máximo 15.";
    }

    if( validator.isEmpty( admin.email ) || !validator.isEmail(admin.email) ){
      errores.email= "Por favor ingresa una direccion de correo apropiada.";
    }

    return errores;
  }

  return (
    <div className="content">
      <Row>
        <Col md="8">
          <Card>
            <CardHeader>
              <CardTitle tag="h6">Administradores Creados</CardTitle>
            </CardHeader>
            <CardBody>
              <Table className="m-3" responsive dark>
                <thead className="text-secundary">
                  <tr>
                    <th>No.</th>
                    <th>Nombre</th>
                    <th>Correo</th>
                    <th>Username</th>
                  </tr>
                </thead>
                <tbody>
                  { admins.map( (a, i) => 
                    <tr key={i}>
                      <td>{i+1}</td>
                      <td>{a.nombre}</td>
                      <td>{a.email}</td>
                      <td>{a.user.username}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <CardHeader>
              <CardTitle tag="h6">Crear Nuevo Administrador</CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={ handleSubmit }>
                { response.message && <Alert color={response.type}>{ response.message }</Alert>}
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label>Username<label className="mb-0 text-danger">*</label></label>
                      <Input
                        autoComplete="false"
                        name="username" 
                        value={ admin.username || '' }
                        onChange={ onChange }
                        className="text-dark"
                        placeholder="admin1"
                        required
                        invalid={ !!errors.username }
                        type="text"/>
                      <FormFeedback>{errors.username}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label>Password<label className="mb-0 text-danger">*</label><label className="mb-0 text-danger"></label></label>
                      <Input
                        name="password" 
                        value={ admin.password || ''}
                        onChange={ onChange }
                        className="text-dark"
                        placeholder="contraseña"
                        required
                        invalid={ !!errors.password }
                        type="password"/>
                        <FormFeedback>{errors.password}</FormFeedback>
                      </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label>Nombre<label className="mb-0 text-danger">*</label></label>
                      <Input
                        name="nombre" 
                        value={ admin.nombre || '' }
                        onChange={ onChange }
                        className="text-dark"
                        placeholder="Administrador 1"
                        required
                        invalid={ !!errors.nombre }
                        type="text"/>
                      <FormFeedback>{errors.nombre}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label>Correo<label className="mb-0 text-danger">*</label></label>
                      <Input
                        name="email" 
                        value={ admin.email || '' }
                        onChange={ onChange }
                        className="text-dark"
                        placeholder="ejemplo@mail.com"
                        invalid={ !!errors.email }
                        required
                        type="email"/>
                        <FormFeedback>{errors.email}</FormFeedback>
                      </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-right" md="12" >
                    <Button 
                      type="submit"
                      size="sm" 
                      style={{ backgroundColor:"#31708f"}}>
                      Crear
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
