import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import { getStudent } from 'redux/actions/student.actions';
import { EditStudentModal } from './EditStudentModal';
import { ModalAsociarACarrera } from './ModalAsociarACarrera';

export const DetalleEstudiante = () => {
 
  const location = useLocation(); // Para acceder a la ruta completa

  //const { id } = useParams();
  const parts = location.pathname.split('/');
  const id = parts[parts.length - 1];  
  const [ student, setStudent ] = useState({});
  const [ programasInscrito, setProgramasInscrito ] = useState([]);
  const [ showModal, setShowModal ] = useState(false); 
  const [ modalEditStudent, setModalEditStudent] = useState(false);


  const obtenerEstudiante = () =>{
    getStudent( id )
    .then( res => {
      setStudent( res.data.student );
      setProgramasInscrito( res.data.student.programas_inscrito)
    })
    .catch( err => console.log( err.response.data.message ))
  }

  useEffect( ()=>{
    obtenerEstudiante();
  }, [])
  
  return (
    <div className="content">
      <Row>
          <Col md="12">
            <Card className="" >
              <CardHeader style={{ backgroundColor: '#efefef', borderColor: '#f7f7f7' }}>
                <Row>
                  <Col xs="6" sm="6" md="8">
                    <CardTitle className="p-0" tag="h5">{student.dpi} - { student.nombre }</CardTitle>                   
                  </Col>
                  <Col className="text-right"xs="6" sm="6" md="4">
                    <Button size="sm" color="success"
                      onClick={()=>setModalEditStudent(true)}>
                      Editar 
                    </Button>
                  </Col>
              </Row>
              </CardHeader>
              <CardBody>
                <label className="mx-5 pt-2 text-dark">
                    <h6>Nombre: {student.nombre}</h6>
                    <h6>DPI: {student.dpi}</h6>
                    <h6>Carnet: {student.carnet}</h6>
                    <h6>Telefono: {student.telefono}</h6>
                    <h6>Whatsapp: {student.whatsapp}</h6>
                    <h6>Correo: {student.email}</h6>
                    <h6>Dirección: {student.direccion}</h6>
                    <h6>Grado Académico: {student.grado}</h6>
                    <h6>Universidad: {student.universidad}</h6>
                </label>
               
              </CardBody>
            </Card>
          </Col>
      </Row>
      <Row>
          <Col md="12">
            <Card>
              <CardHeader style={{ backgroundColor: '#efefef', borderColor: '#f7f7f7' }}>
                <Row>
                  <Col xs="6" sm="6" md="8">
                    <CardTitle className="p-0" tag="h5">Carreras</CardTitle>                   
                  </Col>
                  <Col className="text-right"xs="6" sm="6" md="4">
                    <Button size="sm" color="success" onClick={()=>setShowModal(true)}>
                      Asociar a Carrera 
                    </Button>
                  </Col>
              </Row>
              </CardHeader>
              <CardBody className="mx-5">
                
                {  programasInscrito.map( p => 
                    <h6 key={p.id}>
                      <label className=" pt-2 text-dark"> 
                        {p.nombre}  
                      </label>
                    </h6>
                )}
              </CardBody>
            </Card>
          </Col>
      </Row>
      {/* MODAL */}
      <ModalAsociarACarrera 
            studentId = {student.id}
            studentNombre = {student.nombre} 
            showModal={showModal}
            setShowModal={setShowModal} 
            getStudent={obtenerEstudiante}
            />

      <EditStudentModal
        idStudent={student.id}
        showModal={modalEditStudent}
        setShowModal={setModalEditStudent}
        dpi={student.dpi}
        carnet={student.carnet}
      />
    </div>
  )
}
