import React, { useState } from "react";
import { Tooltip } from "reactstrap";

export const TooltipItem = ({ target, title }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
      <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target={target}
          toggle={toggle}
      >
          {title}
      </Tooltip>
    );
};