import React, { useEffect, useState } from 'react';
import { LoginNavbar } from 'components/Navbars/LoginNavbar';
import { Card, CardBody, CardHeader, Col, Input, Form, FormGroup, FormFeedback, Row, Button, Alert } from 'reactstrap';
import { gradosAcademicos } from '../utils/gradosAcademicos';
import { getProgramas } from '../redux/actions/programa.actions';
import validator from 'validator';
import { isObjEmpty } from 'utils/helpers';
import { saveRespuesta } from 'redux/actions/preinscripcion.actions';
import { getConfigPreinscripcion } from 'redux/actions/preinscripcion.actions';

export const FormPreinscripcion = () => {

  const respuestaInit = {nombre: "", email: "", programaId: "default" }; 
  const [errors, setErrors ] = useState({});
  const [ programas, setProgramas ] = useState([]);
  const [ respuesta, setRespuesta ] = useState(respuestaInit );
  const [ response, setResponse ] = useState( {});
  const [ isActiveForm, setIsActiveForm ] = useState( false );
  const dias = [ 'Domingo', 'Sábado','Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes' ];

  useEffect( ()=>{

    getConfigPreinscripcion()
    .then( res => {
      if(res.data.preinscripcionActive === true ){
        setIsActiveForm( true );
        obtenerProgramas();
      }else{
        setIsActiveForm( false );
        setResponse({type:"warning", message: "Lo sentimos, el formulario de preinscripción no se encuentra habilitado."});
      }
    })
    .catch( err => {
      setIsActiveForm( false );
      setResponse({type:"warning", message: "Lo sentimos, hubo un error al recuperar el formulario."});
    })


  }, []);

  const obtenerProgramas = ()=>{
    getProgramas()
    .then( res => {
      const programasTraidos = res.data.programas;
      const programasActivos = programasTraidos.filter( p => p.activePreinscripcion === true );
      if(programasActivos.length < 1){
        setRespuesta(prev => ({...prev, programaId: "empty"}));
      }
      setProgramas( programasActivos ); 
    })
  }

  const onChange = ( e )=>{
    const { name, value} = e.target;
    setRespuesta( prev => ({...prev, [name]: value }));
  }

  const onSubmit = ( e ) => {
    e.preventDefault();
    setResponse({ loading: true });
    setErrors({});
    const errores = checkErrors();
    if( !isObjEmpty(errores) ){
      setErrors( errores );
      setResponse({ type: "warning", message:"No se ha enviado la respuesta, por errores en el formulario, revisa tus respuestas."});
      return;
    }

    saveRespuesta( respuesta )
    .then( res => {
      setResponse({ type: 'success', message: res.data.message });
    }).catch( err => {
      setResponse({ type:'danger', message: err.response.data.message });
    })
  }

  const checkErrors = ()=>{
    const errores = {};
    if( validator.isEmpty(respuesta.nombre) || !validator.isLength( respuesta.nombre, {min: 10, max:50})){
      errores.nombre = "Por favor ingresa tu nombre completo. Mínimo 10 letras y máximo 50.";
    }
    if( validator.isEmpty(respuesta.email ) || !validator.isEmail( respuesta.email )){
      errores.email = "Por favor ingresa un correo valido para comunicarnos contigo.";
    }
    if( respuesta.programaId === "default" || respuesta.programaId === "empty" ){
      errores.programaId = "Selecciona la carrera de tu interes";
    }
    if(  respuesta.comentario && !validator.isEmpty( respuesta.comentario ) && !validator.isLength( respuesta.comentario, { max: 150 } ) ){
      errores.comentario = "El comentario es opcional pero no debe ser mayor a 150 caracteres."
    }
    return errores;
  }

  return (
    <>  
      <LoginNavbar/>
      <h3 className="text-center mt-5">Departamento de Estudios de Postgrado - CUNORI</h3>
      <div className="content" >
        <Row className="mb-5" style={{ justifyContent: 'center'}}>
          <Col lg="6" md="8" sm="11">
            <Card className="px-2 pt-3 pb-0">
              <CardHeader>
                <p>LLENE EL FORMULARIO DE PREINSCRIPCIÓN DE LOS PROGRAMAS DE POSTGRADO</p>
                <hr/>
              </CardHeader>
              {(isActiveForm === false) && <Alert color={response.type}><strong>{response.message}</strong></Alert>}
              { (isActiveForm === true) && 
              <CardBody className="px-4 mb-5">
                <Form onSubmit={onSubmit} >
                {response.message && <Alert color={response.type}><strong>{response.message}</strong></Alert>}

                  <Row>
                    <Col  md="12">
                      <FormGroup>
                        <h6 className="text-dark">NOMBRE COMPLETO<label className="text-warning m-0"><strong>*</strong></label></h6>                        
                        <Input
                          className="text-dark ml-3"
                          value={respuesta.nombre }
                          onChange={ onChange }
                          name="nombre"
                          invalid={ !! errors.nombre }
                          required/>
                            <FormFeedback >
                              { errors.nombre }
                            </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col  md="12">
                      <FormGroup className="mt-3">
                        <h6 className="text-dark">CORREO ELECTRÓNICO<label className="text-warning m-0"><strong>*</strong></label></h6>                        
                        <Input
                          className="text-dark ml-3"
                          value={respuesta.email }
                          onChange={ onChange }
                          type="email"
                          name="email"
                          invalid={ !! errors.email }
                          required/>
                          <FormFeedback >
                                      { errors.email }
                          </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mt-3"> 
                        <h6 className="text-dark">CARRERA<label className="text-warning m-0"><strong>*</strong></label></h6>
                        <Input
                          className="text-dark ml-3"
                          name="programaId"
                          value={respuesta.programaId }
                          onChange={ onChange }
                          type="select"
                          invalid={!!errors.programaId}
                        >
                          <option key="default"  value="default" disabled hidden>Selecciona la carrera de tu interes.</option>
                          <option key="empty"  value="empty" disabled hidden>No hay carreras seleccionables.</option>
                            {
                                programas.map( p => <option key={p.id} value={p.id}>{p.nombre}</option>)
                            }
                        </Input>
                        <FormFeedback>
                          { errors.programaId }
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col  md="12">
                      <FormGroup className="mt-3">
                        <h6 className="text-dark">TELÉFONO</h6>
                        <Input
                          className="text-dark ml-3"
                          value={respuesta.telefono || ""}
                          onChange={onChange}
                          name="telefono"/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col  md="12">
                      <FormGroup className="mt-3">
                        <h6 className="text-dark">DPI</h6>
                        <Input
                          className="text-dark ml-3"
                          value={respuesta.dpi || ""}
                          onChange={onChange}
                          name="dpi"/>
                          <FormFeedback >
                            { errors.dpi }
                          </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col  md="12">
                      <FormGroup className="mt-3">
                        <h6 className="text-dark" tag="h5">DIRECCIÓN O LUGAR DE RESIDENCIA</h6>
                        <Input
                          className="text-dark ml-3"
                          value={respuesta.direccion || ""}
                          onChange={onChange}
                          name="direccion"/>
                          <FormFeedback >
                            { errors.direccion }
                          </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mt-3">
                        <h6 className="text-dark" tag="h5">GRADO ACADÉMICO</h6>
                        <Input
                          className="text-dark ml-3"
                          placeholder="Selecciona el grado académico."
                          value={respuesta.grado || "default"}
                          onChange={ onChange }
                          name="grado"
                          type="select"
                        >
                          <option key="default"  value="default" >Selecciona un grado académico</option>
                            {
                                gradosAcademicos.map( g => <option key={g.value} value={g.nombre}>{g.nombre}</option>)
                            }
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mt-4">
                        <h6 className="text-dark">MODALIDAD DE ESTUDIO PREFERIDA</h6>
                        <Input
                          className="text-dark  ml-3"
                          placeholder="Selecciona el grado académico."
                          value={respuesta.modalidad  || "default"}
                          onChange={ onChange }
                          name="modalidad"
                          type="select"
                        >
                          <option key="default"  value="default" >Selecciona la modalidad de estudio.</option>
                          <option key="presencial"  value="presencial" >Presencial</option>
                          <option key="enlinea"  value="en linea" >En línea</option>
                          
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mt-4">
                        <h6 className="text-dark">DIA PREFERIDO DE CLASES</h6>
                        <Input
                          className="text-dark  ml-3"
                          placeholder="Selecciona el grado académico."
                          value={respuesta.diaClases  || "default"}
                          onChange={ onChange }
                          name="diaClases"
                          type="select"
                        >
                          <option key="default" value="default" >Selecciona el dia de estudio.</option>
                          { dias.map( d => <option key={d} value={d}>{d}</option>)}
                          
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col  md="12">
                      <FormGroup className="mt-3">
                        <h6 className="text-dark">COMENTARIO</h6>
                        <Input
                          className="text-dark ml-3"
                          type="textarea"
                          value={respuesta.comentario || ""}
                          onChange={onChange}
                          invalid={ !!errors.comentario }
                          name="comentario"/>
                          <FormFeedback>{ errors.comentario }</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  {response.message && <Alert color={response.type}><strong>{response.message}</strong></Alert>}

                  <Row className="pr-1  mt-4" style={{ justifyContent: 'flex-end'}}>
                    <Button className="primary" style={{ backgroundColor:"#31708f"}} type="submit">
                      Enviar
                    </Button>
                  </Row>
                </Form>
              </CardBody>
            }
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}
