import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { INGRESAR_NOTA } from 'utils/endpoints';
import IngresarNotasView from './IngresarNotasView';
import CustomToast from '../../CustomToast';


function IngresarNotasLogic() {
  const [carreras, setCarreras] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [ciclos, setCiclos] = useState([]);
  const [secciones, setSecciones] = useState([]);
  const [selectedCarrera, setSelectedCarrera] = useState('');
  const [selectedCurso, setSelectedCurso] = useState('');
  const [selectedCiclo, setSelectedCiclo] = useState('');
  const [selectedSeccion, setSelectedSeccion] = useState('');
  const [estudiantes, setEstudiantes] = useState([]);
  const [docente, setDocente] = useState('');
  //Extraer los textos de los input
  const [selectedCarreraText, setSelectedCarreraText] = useState('');
  const [selectedCursoText, setSelectedCursoText] = useState('');
  const [selectedCicloText, setSelectedCicloText] = useState('');
  const [selectedSeccionText, setSelectedSeccionText] = useState('');
  const [docenteText, setDocenteText] = useState('');
  //Estados de los botones
  const [cargaHabilitada, setCargaHabilitada] = useState(false);
  const [limpiarHabilitado, setLimpiarHabilitado] = useState(false);
  const [generarNotas, setGenerarNotas] = useState(false);
  const [guardarCambiosHabilitado, setGuardarCambiosHabilitado] = useState(false);
  //Editar los datos de los estudiantes
  const [estudiantesEditando, setEstudiantesEditando] = useState({});
  //Notificaciones
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showWarningToast, setShowWarningToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const [notas, setNotas] = useState([]);
  const [selectedNota, setSelectedNota] = useState({});
  const [selectedNotaText, setSelectedNotaText] = useState('');
  const esSeminario = selectedCursoText.toLowerCase().includes('seminario');

  // Función para mostrar la notificación de advertencia
  const showWarningNotification = (message) => {
    setToastMessage(message);
    setShowWarningToast(true);
    // Oculta la notificación después de un cierto tiempo
    setTimeout(() => {
      setShowWarningToast(false);
    }, 3000); // Puedes ajustar el tiempo en milisegundos
  };

  // Función para mostrar la notificación de éxito
  const showSuccessNotification = (message) => {
    setToastMessage(message);
    setShowSuccessToast(true);
    // Oculta la notificación después de un cierto tiempo
    setTimeout(() => {
      setShowSuccessToast(false);
    }, 3000); // Puedes ajustar el tiempo en milisegundos
  };

  // Función para mostrar la notificación de éxito
  const showErrorNotification = (message) => {
    setToastMessage(message);
    setShowErrorToast(true);
    // Oculta la notificación después de un cierto tiempo
    setTimeout(() => {
      setShowErrorToast(false);
    }, 3000); // Puedes ajustar el tiempo en milisegundos
  };


  const obtenerCiclos = async () => {
    return ['ANUAL','PRIMER SEMESTRE', 'SEGUNDO SEMESTRE','PRIMER TRIMESTRE', 'SEGUNDO TRIMESTRE','TERCER TRIMESTRE', 
    'PRIMER BIMESTRE', 'SEGUNDO BIMESTRE', 'TERCER BIMESTRE', 'CUARTO BIMESTRE'];
  }

  const obtenerSecciones = async () => {
    return ['SECCIÓN A', 'SECCIÓN B', 'SECCIÓN C', 'SECCIÓN D', 'SECCIÓN E'];
  }

  const obtenerNotas = async () => {
    return ['APROBADO', 'REPROBADO'];
  }

  const obtenerCarreras = async () => {
    try {
      const response = await axios.get(`${INGRESAR_NOTA}/carreras`);
      return response.data;
    } catch (error) {
      showErrorNotification('Error al obtener carreras.');
      console.error('Error al obtener carreras:', error);
      throw error;
    }
  }

  const obtenerCursos = async (carreraId) => {
    try {
      const response = await axios.get(`${INGRESAR_NOTA}/cursos`, {
        params: { carreraId },
      });
      return response.data;
    } catch (error) {
      showErrorNotification('Error al obtener cursos.');
      console.error('Error al obtener cursos:', error);
      throw error;
    }
  }

  const obtenerEstudiantesNotas = async (cursoId, ciclo, seccion) => {
    try {
      const response = await axios.get(`${INGRESAR_NOTA}/estudiantes-notas`, {
        params: { cursoId, ciclo, seccion },
      });
      return response.data;
    } catch (error) {
      showErrorNotification('Error al obtener estudiantes y notas.');
      console.error('Error al obtener estudiantes y notas:', error);
      throw error;
    }
  }
  //*********************************************************************************************************** */
  const cargarCarreras = useCallback(async () => {
    try {
      const carrerasData = await obtenerCarreras();
      setCarreras(carrerasData);
    } catch (error) {
      showErrorNotification('Error al cargar carreras.');
      console.error('Error al cargar carreras:', error);
    }
  }, []);


  const cargarCursos = async (carreraId) => {
    try {
      const cursosData = await obtenerCursos(carreraId);
      setCursos(cursosData);
    } catch (error) {
      showErrorNotification('Error al cargar cursos.');
      console.error('Error al cargar cursos:', error);
    }
  };

  const cargarCiclos = useCallback(async () => {
    const ciclosData = await obtenerCiclos();
    setCiclos(ciclosData);
  }, []);

  const cargarSecciones = useCallback(async () => {
    const seccionesData = await obtenerSecciones();
    setSecciones(seccionesData);
  }, []);

  const cargarNotas = useCallback(async () => {
    const notasData = await obtenerNotas();
    setNotas(notasData);
  }, []);

  useEffect(() => {
    cargarCarreras();
    cargarCiclos();
    cargarSecciones();
    cargarNotas();
  }, [cargarCarreras, cargarCiclos, cargarSecciones, cargarNotas]);


  const handleCarreraChange = (event) => {
    const carreraId = event.target.value;
    const selectedText = event.target.options[event.target.selectedIndex].text; // Obtener el texto seleccionado
    setSelectedCarrera(carreraId);
    setSelectedCurso('');
    setSelectedCarreraText(selectedText); // Actualizar el estado del texto seleccionado
    cargarCursos(carreraId);
  };

  const handleCursoChange = (event) => {
    const cursoId = event.target.value;
    const selectedText = event.target.options[event.target.selectedIndex].text; // Obtener el texto seleccionado
    setSelectedCurso(cursoId);
    setSelectedCursoText(selectedText); // Actualizar el estado del texto seleccionado
    handleCargarEstudiantesNotas(cursoId);
  };

  const handleCicloChange = (event) => {
    setSelectedCiclo(event.target.value);
    const selectedText = event.target.options[event.target.selectedIndex].text; // Obtener el texto seleccionado
    setSelectedCicloText(selectedText); // Actualizar el estado del texto seleccionado

  };

  const handleSeccionChange = (event) => {
    setSelectedSeccion(event.target.value);
    const selectedText = event.target.options[event.target.selectedIndex].text; // Obtener el texto seleccionado
    setSelectedSeccionText(selectedText); // Actualizar el estado del texto seleccionado
  };


  const handleNotaChange = (index, value) => {
    const estudiantesActualizados = [...estudiantes]; // Clonar el array de estudiantes
    estudiantesActualizados[index].nota = value; // Actualizar la nota del estudiante en el índice específico
    setEstudiantes(estudiantesActualizados); // Actualizar el estado de los estudiantes

    //setSelectedNota(value); // Asegúrate de actualizar el valor de selectedNota
    // Actualizar la nota en el objeto selectedNota usando el ID del estudiante como clave
    setSelectedNota((prevSelectedNota) => {
      return {
        ...prevSelectedNota,
        [estudiantes[index].id]: value,
      };
    });
  };



  const handleCargarEstudiantesNotas = async () => {
    if (selectedCurso) {
      try {
        //Se añadieron ciclo y seccion a la consulta para evitar errores al traer información
        const estudiantesNotasData = await obtenerEstudiantesNotas(selectedCurso, selectedCiclo, selectedSeccion);

        if (estudiantesNotasData.estudiantesNotas.length === 0) {
          showErrorNotification('No se encontraron estudiantes para el ciclo y la sección proporcionados.');
        }
        // Ordenar los estudiantes alfabéticamente por nombre
        const estudiantesOrdenados = estudiantesNotasData.estudiantesNotas.sort((a, b) =>
          a.nombre.localeCompare(b.nombre));

        // Construir el objeto estudiantesEditando para habilitar la edición desde el inicio
        const estudiantesEditandoInicial = estudiantesNotasData.estudiantesNotas.reduce(
          (editando, estudiante, index) => {
            editando[index] = true;
            return editando;
          }, {});


        //setEstudiantes(estudiantesNotasData.estudiantesNotas);
        setEstudiantes(estudiantesOrdenados);
        setEstudiantesEditando(estudiantesEditandoInicial);

        //Habilitar los botones
        setLimpiarHabilitado(true);
        setGenerarNotas(true);
        //setGuardarCambiosHabilitado(true);
      } catch (error) {
        showErrorNotification('Error al cargar estudiantes y notas.');
        console.error('Error al cargar estudiantes y notas:', error);
      }
    }
  };

  const handleInputChange = (index, campo, valor) => {
    const estudiantesActualizados = estudiantes.map((estudiante, i) =>
      i === index ? { ...estudiante, [campo]: valor } : estudiante
    );
    setEstudiantes(estudiantesActualizados);

  };


  const handleGuardarNotas = async () => {
    if (!docente) {
      // Mostrar la notificación de error
      showWarningNotification('El campo docente no puede estar vacío.');
      console.error('El campo docente no puede estar vacío.');
      return;
    }

    try {
      const data = {
        cursoId: selectedCurso,
        estudiantes: estudiantes.map((estudiante) => ({
          id: estudiante.id,
          dpi: estudiante.dpi,
          carne: estudiante.carne,
          nombre: estudiante.nombre,
          //nota: estudiante.nota,
          //nota: esSeminario ? selectedNota : estudiante.nota
          nota: esSeminario ? selectedNota[estudiante.id] : estudiante.nota,
        })),
        ciclo: selectedCiclo,
        seccion: selectedSeccion,
        docente: docente,
      };

      const response = await axios.post(`${INGRESAR_NOTA}/guardar-notas`, data);
      setEstudiantesEditando({}); // Limpiar los estados de edición
      setGuardarCambiosHabilitado(false); // Deshabilitar el botón de guardar cambios

      // Mostrar la notificación de éxito
      showSuccessNotification('¡Las notas se guardaron correctamente!');
      //console.log('Datos guardados en la base de datos:', response.data);
    } catch (error) {
      showErrorNotification('Error al guardar notas.');
      console.error('Error al guardar notas:', error);
    }
  };

  const limpiarCampos = () => {
    setSelectedCarrera('');
    setSelectedCurso('');
    setSelectedCiclo('');
    setSelectedSeccion('');
    setEstudiantes([]);
    setDocente('');

    // Deshabilitar los botones
    setCargaHabilitada(false);
    setLimpiarHabilitado(false);
    setGenerarNotas(false);
    //setGuardarCambiosHabilitado(false);
  };

  const handleGenerarArchivoExcel = async () => {
    if (estudiantes.length > 0) {

    }
  };

  return (
    <div className="content">
      <IngresarNotasView
        carreras={carreras}
        cursos={cursos}
        ciclos={ciclos}
        secciones={secciones}
        selectedCarrera={selectedCarrera}
        selectedCurso={selectedCurso}
        selectedCiclo={selectedCiclo}
        selectedSeccion={selectedSeccion}
        estudiantes={estudiantes}
        docente={docente}
        selectedCarreraText={selectedCarreraText}
        selectedCursoText={selectedCursoText}
        selectedCicloText={selectedCicloText}
        selectedSeccionText={selectedSeccionText}
        docenteText={docenteText}
        cargaHabilitada={cargaHabilitada}
        limpiarHabilitado={limpiarHabilitado}
        generarNotas={generarNotas}
        cargarCarreras={cargarCarreras}
        cargarCursos={cargarCursos}
        cargarCiclos={cargarCiclos}
        cargarSecciones={cargarSecciones}
        handleCarreraChange={handleCarreraChange}
        handleCursoChange={handleCursoChange}
        handleCicloChange={handleCicloChange}
        handleSeccionChange={handleSeccionChange}
        handleCargarEstudiantesNotas={handleCargarEstudiantesNotas}
        limpiarCampos={limpiarCampos}
        handleGenerarArchivoExcel={handleGenerarArchivoExcel}
        handleInputChange={handleInputChange}
        estudiantesEditando={estudiantesEditando}
        handleGuardarNotas={handleGuardarNotas}
        setDocente={setDocente}
        //guardarCambiosHabilitado={guardarCambiosHabilitado}
        notas={notas}
        selectedNota={selectedNota}
        selectedNotaText={selectedNotaText}
        handleNotaChange={handleNotaChange}
        cargarNotas={cargarNotas}
      />

      {/* Renderizar el Toast de éxito cuando sea necesario */}
      {showSuccessToast && (
        <CustomToast
          type="success"
          message={toastMessage}
          onClose={() => setShowSuccessToast(false)}
        />
      )}

      {/* Renderizar el Toast de advertencia cuando sea necesario */}
      {showWarningToast && (
        <CustomToast
          type="warning"
          message={toastMessage}
          onClose={() => setShowWarningToast(false)}
        />
      )}

      {/* Renderizar el Toast de error cuando sea necesario */}
      {showErrorToast && (
        <CustomToast
          type="danger"
          message={toastMessage}
          onClose={() => setShowErrorToast(false)}
        />
      )}
    </div>
  );

};

export default IngresarNotasLogic;
