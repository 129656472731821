import React from 'react'
import { Button, Col, FormFeedback, FormGroup, Input, Row, Spinner } from 'reactstrap'
import { idMensualidad } from 'utils/tiposSolicitudes';
import { tiposBoletas } from 'utils/tiposSolicitudes'

export const FormNuevaSolicitud = ({ 
        solicitud, onClick, onLoading, programas, onChange, errors }) => {
  
  return (
    <React.Fragment>
      <Row>        
        <Col md="12">
          <FormGroup>
            <label className="text-muted">TIPO DE SOLICITUD <label className="text-danger"><strong className="pb-0">*</strong></label></label>
            <Input className="text-dark" name="tipo" value={solicitud.tipo || "default"} onChange={onChange} type="select" invalid={!!errors.tipo}>
              <option key="default" value="default" hidden disabled>Selecciona el tipo de solicitud.</option> 
              { tiposBoletas.map( tipo => <option key={tipo.key} value={tipo.key}>{tipo.value}</option>)}
            </Input>
            <FormFeedback>{ errors.tipo }</FormFeedback>
          </FormGroup>
        </Col>
        </Row>
        <Row>
        <Col md="12">
          <FormGroup>
            <label className="text-muted">CARRERA <label className="text-danger"><strong className="pb-0">*</strong></label></label>
            <Input className="text-dark" name="programaId" value={solicitud.programaId || "default"} onChange={onChange} type="select" invalid={!!errors.programaId}>
              <option key="default" value="default" hidden disabled>Carrera de la solicitud.</option> 
              { programas.map( c => <option key={c.id} value={c.id}>{c.codigo} - {c.nombre}</option>)}
            </Input>
            <FormFeedback>{ errors.carrera }</FormFeedback>
          </FormGroup>
        </Col>
       
      </Row>
      {
        (solicitud.tipo === idMensualidad.toString()) &&
        <Row>
            <Col md="12">
            <FormGroup>
                <label className="text-muted">MONTO DE LA BOLETA DE PAGO A GENERAR <label className="text-danger"><strong className="pb-0">*</strong></label></label>
                <Input className="text-dark" name="monto" placeholder="Monto en quetzales."
                    value={solicitud.monto || ""} onChange={onChange} invalid={!!errors.monto}/>
                <FormFeedback>{ errors.monto}</FormFeedback>
            </FormGroup>
            </Col>
        </Row>
      }
      <Row>
        <Col md="12">
        <FormGroup>
            <label className="text-muted">COMENTARIO (OPCIONAL)</label>
            <Input className="text-dark" name="comentario" placeholder="Puedes dar detalles de tu solictud que facilite su entendimiento y gestión." type="textarea"
                value={solicitud.comentario || ""} onChange={onChange} invalid={!!errors.comentario}/>
            <FormFeedback>{ errors.comentario}</FormFeedback>
        </FormGroup>
        </Col>
      </Row>
     
      <Row className="mr-1" style={{ justifyContent: 'flex-end' }}>
        { onLoading && <Spinner className="mr-2"/> }
        <Button 
              onClick={onClick}
              color="primary" 
              size="sm" 
              style={{backgroundColor:"#31708f"}}>
            Enviar
        </Button>
      </Row>
    </React.Fragment>
  )
}
