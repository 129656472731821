import React, { useEffect, useState } from 'react'
import { Button, Col, ListGroupItem, Row } from 'reactstrap'
import moment from 'moment';
import { Link } from 'react-router-dom';
import { TooltipItem } from 'components/utils/TooltipItem';

export const SolicitudItem = ({ solicitud }) => {

  const styleItem = {
    borderBottomColor: '#ffff',
    backgroundColor: '#fffff',
    borderColor: '#fffff'
  }

  const [selectedEstadoText, setSelectedEstadoText] = useState(''); // Nuevo estado para almacenar el texto del estado

  useEffect(() => {
    // Recuperar el valor almacenado en el almacenamiento local
    const storedSelectedEstadoText = localStorage.getItem(`selectedEstadoText-${solicitud.id}`);
    if (storedSelectedEstadoText) {
      setSelectedEstadoText(storedSelectedEstadoText);
    }
  }, [solicitud.id]);

  const estadoText = solicitud.tipo === 'CONSTANCIAS O CERTIFICACIONES' && solicitud.status === 'REGISTRADA'
    ? `SIN ATENDER - ${selectedEstadoText}` // Estado personalizado para CONSTANCIAS O CERTIFICACIONES
    : solicitud.status === 'REGISTRADA'
      ? 'SIN ATENDER'
      : 'ATENDIDA';

  return (
    <ListGroupItem key={solicitud.id} className="mb-2 py-1" style={styleItem}>
      <Row>
        <Col>
          <strong>
            <p className="mb-0" style={{ color: '#344767' }}>
              {solicitud.tipo}
            </p>
          </strong>
          <strong>
            <label
              className={solicitud.status === 'REGISTRADA' ? 'text-danger mb-0' : 'text-info mb-0'}
            >
              {estadoText} {/* Mostrar el estadoText calculado */}
            </label>
          </strong>
          <p className="text-muted my-0">Ingresada el {moment(solicitud.createdAt).format('DD/MM/YYYY')}</p>
        </Col>
        <Col className="text-right">
          <Link to={`solicitudes/detalle/${solicitud.id}`}>
            <Button id={`ver-${solicitud.id}`} color="primary" size="sm" style={{ backgroundColor: '#f7f7f7' }}>
              <div className="stats">
                <i className="far fa-eye" style={{ color: '#344767' }}></i>
              </div>
            </Button>
            <TooltipItem target={`ver-${solicitud.id}`} title={'Atender'} />
          </Link>
        </Col>
      </Row>
    </ListGroupItem>
  )
}