import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { Alert, Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, FormFeedback, Input, Row, Table } from 'reactstrap';
import { getInscritosEnPrograma } from 'redux/actions/inscripciones.actions';
import { getPrograma } from 'redux/actions/programa.actions';
import { downloadExcelInscritoEnPrograma } from 'redux/actions/inscripciones.actions';
import { isObjEmpty } from 'utils/helpers';
import moment from 'moment';
import validator from 'validator';

export const DetalleInscripcionesCarrera = () => {

  const location = useLocation(); // Para acceder a la ruta completa

  //const { id } = useParams();
  const parts = location.pathname.split('/');
  const id = parts[parts.length - 1];
  const [ programa, setPrograma ] = useState({});
  const [ ciclo, setCiclo ] = useState("default");
  const [ anio, setAnio ] = useState(new Date().getFullYear().toString());
  const [ students, setStudents ] = useState([]);
  const [ response, setResponse ] = useState( false );
  const [ errors, setErrors ] = useState( {} );

  const arrayCiclos = ()=>{
    let ciclos = [];
    for( let i=0; i<programa.ciclos; i++){
      ciclos.push(i+1)
    }
    return ciclos;
}

  useEffect(() => {
      getPrograma( id )
      .then( res => setPrograma( res.data.programa ))
      .catch( err => console.log( err ));
  }, [id])

  const handleSearch = ( ) => {
    setResponse( false );
    const errores = checkErrors();
    if( !isObjEmpty(errores) ){
      setErrors( errores );
      return;
    }

    getInscritosEnPrograma( programa.id, anio.trim(), ciclo )
    .then( res =>{
        setStudents(res.data.students );
        setResponse( true );
    })
    .catch( err => console.log(err))
  }

  const checkErrors = ()=>{
    const errores = {};
    setErrors({});
    if( validator.isEmpty( anio.trim() ) || !validator.isInt(anio.trim(), { min: 1950, max: 2100 })){
      errores.anio = "El año debe ser entre 1950 - 2100."
  }

    if( ciclo === "default" ){
      errores.ciclo = "Por favor selecciona el ciclo académico."
    }
    return errores;
  }

  return (
    <div className="content">
         <Row>
          <Col md="12">
            <Card className="" >
              <CardHeader style={{ backgroundColor: '#efefef', borderColor: '#f7f7f7'}}>
                <Row>
                  <Col xs="6" sm="6" md="8">
                     <CardTitle className="p-0" tag="h5">{programa.codigo} - { programa.nombre }</CardTitle>                   
                   </Col>
                 </Row>
              </CardHeader>
              <CardBody>
                <label> 
                    <h6>Años (Ciclos Académicos): {programa.ciclos}</h6>
                    <h6>Unidad: {programa.unidad}</h6>
                    <h6>Extensión: {programa.extension}</h6>
                </label>
              </CardBody>
            </Card>
          </Col>
        </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h6">Ver Estudiantes Inscritos</CardTitle>
              <p className="text-muted">Descarga el listado de los estudiantes inscritos.</p>

              <Row className="ml-2" style={{ alignItems: 'flex-end'}} >
                <Col md="3" className="pl-2">
                  <FormGroup>
                    <p className="py-0">Año de Inscripción: </p>
                    <Input
                      value={anio} 
                      className="mr-1" 
                      type="text" 
                      onChange={(e)=>setAnio( e.target.value )}
                      invalid={ !!errors.anio }/>
                      <FormFeedback>{errors.anio}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="3" className="pl-2">
                  <FormGroup>
                    <p className="py-0">Ciclo académico: </p>
                    <Input
                      value={ciclo} 
                      className="mr-1" 
                      type="select" 
                      onChange={(e)=>setCiclo( e.target.value )}
                      invalid={ !!errors.ciclo }>
                      
                        <option key="default" value="default" hidden disabled>Selecciona el ciclo.</option>
                        { arrayCiclos().map( c => <option key={c} value={c}>{c}</option>) }
                    </Input>
                    <FormFeedback>{errors.ciclo}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col className="ml-0 pl-0 mb-2 text-left" >
                  <Button size="sm" color="primary" onClick={()=>{handleSearch()}}>Buscar</Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="mx-3">
                { students.length > 0 &&
                <>
                <Row>
                  <Col className="text-right" >
                    <Button size="sm" color="success" onClick={ ()=>downloadExcelInscritoEnPrograma(programa.nombre, programa.id, anio.trim(), ciclo)}>
                        Descargar Excel
                    </Button>
                  </Col>
                </Row>
                <Table className="m-3" responsive striped>
                  <thead className="text-secundary">
                    <tr>
                    <th>Nombre</th>
                    <th>DPI</th>
                    <th>Carnet</th>
                    <th>Ciclo</th>
                    <th>Fecha Inscripción</th>
                    </tr>
                  </thead>
                  <tbody>
                      { students.map( (s,i) => (
                          <tr key={i}>
                            <td>{s.nombre}</td>
                            <td>{s.dpi}</td>
                            <td>{s.carnet}</td>
                            <td>{s.InscripcionesStudent.ciclo}</td>
                            <td>{moment(s.InscripcionesStudent.fechaInscripcion).format("DD/MM/YYYY")}</td>
                          </tr>
                      )) }
                  </tbody>
                </Table>
                </>
                }
                { (students.length < 1 &&  response === true) &&
                
                  <Alert color="warning">
                  <strong>No hay respuestas.</strong>
                  </Alert>
                }
            </CardBody>
          </Card>        
        </Col>
      </Row>
    </div>
  )
}
