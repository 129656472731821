import axios from "axios"
import { SAVE_RESPUESTA_PREINSCRIPCION } from "utils/endpoints"
import { GET_EXCEL_PREINSCRIPCION } from "utils/endpoints"
import { GET_RESPUESTAS_BY_PROGRAMA } from "utils/endpoints"
import { CHANGE_ACTIVE_PREINSCRIPCION_IN_PROGRAMAS } from "utils/endpoints"
import { CHANGE_PREINSCRIPCION_ACTIVE } from "utils/endpoints"
import { GET_PREINSCRIPCION_CONFIG } from "utils/endpoints"
import moment from "moment"
import { DELETE_RESPUESTAS_BY_PROGRAMA_DATES } from "utils/endpoints"
import { DELETE_ALL_RESPUESTAS } from "utils/endpoints"
import { GET_LAST_RESPUESTAS } from "utils/endpoints"

export const saveRespuesta = ( body )=>{
    return new Promise( (resolve, reject) => {
        axios.post( SAVE_RESPUESTA_PREINSCRIPCION, body )
        .then( res => resolve(res))
        .catch( err => reject(err ))
    })
}

export const getLastRespuestas = ( programaId ) => {
    return new Promise( (resolve, reject) => {
        axios.get( GET_LAST_RESPUESTAS)
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
}

export const getRespuestasByPrograma = ( programaId ) => {
    return new Promise( (resolve, reject) => {
        axios.get( GET_RESPUESTAS_BY_PROGRAMA, { params: {programaId}})
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
}

export const getConfigPreinscripcion = ()=>{
    return new Promise(( resolve, reject) => {
        axios.get( GET_PREINSCRIPCION_CONFIG )
        .then( res=> resolve(res) )
        .catch( err => reject(err))
    })
}

export const changePreinscripcionActive = (preinscripcionActive)=>{
    return new Promise( (resolve, reject ) => {
        axios.patch( CHANGE_PREINSCRIPCION_ACTIVE, { preinscripcionActive })
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
}

export const changePreinscripcionInProgramas = (programasActivos, programasInactivos ) =>{
    return new Promise ((resolve, reject ) => {
        axios.patch( CHANGE_ACTIVE_PREINSCRIPCION_IN_PROGRAMAS,
             {programasActivos, programasInactivos} 
        ).then(res => resolve(res) )
        .catch( err=> reject(err))
    })
}

export const downloadExcelPreinscritosByPrograma = ( programaId, programaNombre)=>{
    return new Promise( (resolve, reject) => {
        axios.get( GET_EXCEL_PREINSCRIPCION, {
            responseType: 'blob',
            params:{ programaId }
        }).then( response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Preinscritos-en-${programaNombre}-${moment().format("DD/MM/YYYY")}.xlsx`); //or any other extension
            document.body.appendChild(link);
            link.click();
            resolve(response)
        })
        .catch( err => reject(err))
    });  
};

export const deleteRespuestasByProgramaAndDates = ( programaId, date1, date2 ) => {
    return new Promise(( resolve, reject ) =>{
        axios.delete( DELETE_RESPUESTAS_BY_PROGRAMA_DATES + programaId, 
            { params: { date1, date2 }})
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
}

export const deleteAll = ()=>{
    return new Promise ( (resolve, reject) => {
        axios.delete( DELETE_ALL_RESPUESTAS )
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
}

