import React, { useEffect, useState } from "react";
import { getSolicitudesByStudent } from "redux/actions/solicitud.actions";

function withSolicitudesEstudiante(ListComponent){
    return function ListComponentWithSolicitudesEstudiante({ studentId }){
        const [ solicitudes, setSolicitudes] = useState([]);
        console.log(solicitudes);
        useEffect( ()=>{
            if(studentId){
                getSolicitudesByStudent( studentId )
                .then( res => setSolicitudes(res.data))
                .catch( err => console.log( err.response.data.message ))    
            }
          }, []);
          
        return (
            solicitudes.length > 0 
                && <ListComponent solicitudes={solicitudes} title="Mis Solicitudes"/>
        );
    }
};

export {
    withSolicitudesEstudiante
};