import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Row, Spinner } from 'reactstrap'
import { gradosAcademicos } from '../utils/gradosAcademicos';
import validator from 'validator';
import { isObjEmpty } from 'utils/helpers';
import { createStudent } from 'redux/actions/student.actions';
import { CargaCSV } from 'components/estudiantes/CargaCSV';
import { BuscarEstudiante } from 'components/estudiantes/BuscarEstudiante';
import { getProgramas } from 'redux/actions/programa.actions';

export const Estudiantes = () => {


  const [ estudiante, setEstudiante ] = useState({ gradoAcademico: gradosAcademicos[0].nombre, programaId: "default" });
  const [ errors, setErrors ] = useState({});
  const [ response, setResponse ] = useState( {} );
  const [ programas, setProgramas ] = useState([]);


  useEffect(() => {
    getProgramas().then( res => setProgramas( res.data.programas ) )
  }, [])

  const onChange = ( e ) => {
    const { value, name } = e.target;
    setEstudiante( prev => ({
      ...prev,
      [name] : value
    }))
  } 

  const handleSubmit = ( e ) => {
    e.preventDefault();
    setResponse({ loading: true })
    setErrors({});

    const errores = checkErrors();
    
    if( !isObjEmpty( errores ) ){
      setErrors(errores);
      setResponse({ loading: false })
      return;
    }

    createStudent( estudiante )
    .then( res => setResponse(
      { loading: false, message: res.data.message, type:'success' }) )
    .catch( err => setResponse( 
      { loading: false, message: 'Error, no se pudo crear: ' + err.response.data.message, type: 'warning' }))
  }
  
  const checkErrors = ()=>{
    const errores = {};

    if( !validator.isLength( estudiante.nombre, { min: 5 }) ){
      errores.nombre = "El nombre es obligatorio. Y debe tener al menos 5 caracteres.";
    }else if(!validator.matches(estudiante.nombre, /^[a-zA-Z0-9\s]*$/) ){
      errores.nombre = "El nombre del estudiante solo puede contener letras mayusculas, minusculas, números y espacios";
    }

    if(!validator.isLength( estudiante.dpi, { min: 5, max:15}) ){
      errores.dpi = "El número de identificación debe tener de 5 a 15 caracteres.";
    }else if(!validator.matches(estudiante.dpi, /^[0-9]+$/)){
      errores.dpi = "El DPI o Pasaporte debe contener números enteros";
    }

    if(!validator.isLength( estudiante.carnet, {max:9}) || !validator.matches(estudiante.carnet, /^[0-9]+$/)){
      errores.carnet = "El carnet debe tener 9 digitos.";
    }

    if(!validator.isLength( estudiante.telefono, { min: 8, max:20}) ){
      errores.telefono = "El teléfono debe tener al menos 8 digitos.";
    }else if(!validator.matches(estudiante.telefono, /^[0-9]+$/)){
      errores.telefono = "El teléfono debe contener números enteros";
    }

    if( estudiante.programaId === "default"){
      errores.programaId = "Debes seleccionar una carrera para el nuevo estudiante.";
    }

    return errores;
  }

  return (
    <div className="content">
        <Row>
          <Col md="8">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Registrar nuevo estudiante</CardTitle>
              </CardHeader>
              <CardBody>
                { response.message && 
                  <Alert color={response.type}><strong>{response.message}</strong></Alert>
                }
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col  md="5">
                      <FormGroup>
                        <label>NOMBRE COMPLETO<label className="text-warning m-0"><strong>*</strong></label></label>                        
                        <Input
                          className="text-dark"
                          placeholder="Nompres y apellidos"
                          type="text"
                          name="nombre"
                          value={estudiante.nombre || ''}
                          onChange={ onChange }
                          invalid={ !! errors.nombre }
                          required/>
                            <FormFeedback >
                                        { errors.nombre }
                            </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col  md="4">
                      <FormGroup>
                        <label>DPI/PASAPORTE - (ÚNICO)<label className="text-warning m-0"><strong>*</strong></label></label>
                        <Input
                          className="text-dark"
                          placeholder="Identificador"
                          type="text"
                          name="dpi"
                          value={estudiante.dpi || ''}
                          onChange={ onChange }
                          invalid={ !! errors.dpi }
                          required
                        />
                        <FormFeedback >
                                        { errors.dpi }
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label>CARNET - (ÚNICO)</label>
                        <Input
                          className="text-dark"
                          type="text"
                          placeholder="Carnet o registro académico"
                          name="carnet"
                          value={estudiante.carnet || ''}
                          onChange={ onChange }
                          invalid={ !! errors.carnet }
                        />
                        <FormFeedback >
                                        { errors.carnet }
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>CARRERA<label className="text-warning m-0"><strong>*</strong></label></label>
                        <Input
                          className="text-dark"
                          placeholder="Selecciona la carrera a la que pertenecera el curso."
                          name="programaId"
                          value={estudiante.programaId }
                          onChange={ onChange }
                          invalid={ !! errors.programaId }
                          type="select"
                        >
                          <option key="default"  value="default" disabled hidden>Selecciona la carrera.</option>
                            {
                                programas.map( p => <option key={p.id} value={p.id}>{p.codigo}. {' '}  {p.nombre}</option>)
                            }
                        </Input>
                        <FormFeedback>
                          { errors.programaId }
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <FormGroup>
                        <label>CORREO ELECTRÓNICO</label>
                        <Input 
                            placeholder="Correo" 
                            type="email"
                            name="email"
                            value={estudiante.email || ''}
                            onChange={ onChange }
                            className="text-dark" />
                      </FormGroup>
                    </Col>
                    <Col  md="3">
                      <FormGroup>
                        <label>TELÉFONO</label>
                        <Input
                          className="text-dark"
                          placeholder="Número telefónico"
                          name="telefono"
                          value={estudiante.telefono || ''}
                          onChange={ onChange }
                          invalid={ !!errors.telefono }
                          type="text"
                        />
                        <FormFeedback >
                          { errors.telefono }
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>DIRECCIÓN</label>
                        <Input
                          className="text-dark"
                          placeholder="Dirección de residencia"
                          name="direccion"
                          value={estudiante.direccion || ''}
                          onChange={ onChange }
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>GRADO ACADÉMICO</label>
                        <Input
                          className="text-dark"
                          placeholder="Selecciona el grado académico."
                          name="gradoAcademico"
                          value={estudiante.gradoAcademico || "default"}
                          onChange={ onChange }
                          type="select"
                        >
                          <option value="default" disabled>Selecciona el grado académico.</option>
                            {
                                gradosAcademicos.map( g => <option key={g.value} value={g.nombre}>{g.nombre}</option>)
                            }
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>UNIVERSIDAD</label>
                        <Input
                          className="text-dark"
                          placeholder="Universidad"
                          name="universidad"
                          value={estudiante.universidad || ''}
                          onChange={ onChange }
                          type="text"
                          />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row >
                    { response.loading && 
                    <Spinner className="ml-3"/> }
                    <Col className=" text-dark text-right mr-3 mt-3" >
                      <Button
                        size="sm"
                        color="primary"
                        type="submit"
                      >
                        Registrar Estudiante
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>

          {/* Buscar Estudiante */}
          <Col md="4">
            <BuscarEstudiante/>
          </Col>
        

          </Row>
          <Row>          
            <CargaCSV/>
          </Row>
    </div>
  )
}
