import React, { useState } from 'react';
import { Toast, ToastHeader, ToastBody } from 'reactstrap';
import '../assets/css/CustomToast.css';

function CustomToast({ type, message, onClose }) {
  // Define las clases CSS para el color de fondo del Toast en función del tipo
  const getToastColorClass = () => {
    switch (type) {
      case 'success':
        return 'bg-success';
      case 'warning':
        return 'bg-warning';
      case 'danger':
        return 'bg-danger';
      default:
        return 'bg-info';
    }
  };

  const toastColorClass = getToastColorClass();

  return (
    //<div className={`p-3 my-2 rounded ${getToastColorClass()}`}>
      <Toast className={`custom-toast ${toastColorClass}`}>
        <ToastHeader className={`custom-toast-header ${toastColorClass}`}>
          {type === 'success' && 'Éxito'}
          {type === 'warning' && 'Advertencia'}
          {type === 'danger' && 'Error'}
        </ToastHeader>
        <ToastBody className={`custom-toast-body ${toastColorClass}`}>
          {message}
        </ToastBody>
      </Toast>
    //</div>
  );
}

export default CustomToast;
