import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, CardBody, CardHeader, Col, FormFeedback, Input, Row, Spinner } from 'reactstrap'
import { getLastRespuestas } from 'redux/actions/preinscripcion.actions';
import { downloadExcelPreinscritosByPrograma } from 'redux/actions/preinscripcion.actions';
import { getRespuestasByPrograma } from 'redux/actions/preinscripcion.actions';
import { getProgramas } from 'redux/actions/programa.actions';
import { RespuestasTable } from './RespuestasTable';

export const Respuestas = () => {

  const [ programas, setProgramas ] = useState([]);
  const [ programaId, setProgramaId ] = useState("default");
  const [ programa, setPrograma ] = useState({});
  const [ errors, setErrors ] = useState({});
  const [ respuestas, setRespuestas ] = useState([]);
  const [ response, setResponse ] = useState({});

  useEffect( ()=>{
    getProgramas()
    .then( res => setProgramas(res.data.programas ))
    .catch( err => console.log(err))
  }, [])

  useEffect( ()=>{
    getLastRespuestas()
    .then( res => setRespuestas(res.data))
    .catch( err => console.log(err))
  }, [])

  useEffect( ()=>{ setErrors({})}, [programaId])

  const handleButton = () => {
    setErrors({});
    setResponse({loading: true});
    if( programaId === "default"){
      setErrors( {programaId : "Selecciona una carrera para continuar."} );
      return ;
    }
    getRespuestasByPrograma(programaId)
    .then( res => {
      const respObtenidas  = res.data;
      if( respObtenidas.length < 1 ){
        setResponse({ type: "warning", message: "No hay respuestas con esta carrera."})
      }else{ 
        
        setResponse({})
      }
      setRespuestas(res.data);
      obtenerProgramaSeleccionado();
    })
    .catch( err => setResponse({ type: "danger", message: err.response.data.message }));
  }

  const obtenerProgramaSeleccionado = ()=>{
    const carrerra = programas.find( p => p.id == programaId );
    setPrograma( carrerra );
  }

  const handleButtonExcel = ()=>{
    setResponse({loading:true})
    downloadExcelPreinscritosByPrograma( programa.id, programa.nombre )
    .then( res => {
      setResponse({});
    })
    .catch( err => {
      setResponse({ type: "danger", message: err.response.data.message });
    })
  }

  return (
    <Row style={{ justifyContent: 'center' }}>
      <Col md= "12" >
        <Card className="px-4">
          <CardHeader className="pl-0" tag="h5"> Respuestas recibidas</CardHeader>
          <hr className="mt-2"/>
          <CardBody>
            <Row className="mb-5">
              
              <Col  xs="12" sm="12" md="8">
                { response.message && <Alert color={response.type}><strong> { response.message}</strong> </Alert>}
                <Row style={{ alignItems: 'center'}}>

                  <Col xs="8" md="9">
                    <Input
                    value={ programaId }
                    onChange={ (e) => setProgramaId( e.target.value )}
                    type="select"
                    invalid={ !! errors.programaId }>
                      <option key="default" value="default" disabled hidden>Selecciona una carrera y trae las respuestas asociadas a ella.</option>
                      { 
                        programas.map( p => 
                          <option key={p.id} value={p.id}>{p.codigo} - {p.nombre}</option>
                        )
                      }
                    </Input>
                    <FormFeedback>
                      { errors.programaId }
                    </FormFeedback>
                  </Col>
                  <Col  xs="4" md="2" className="pl-0">
                    <Button style={{ backgroundColor:"#31708f"}} 
                      className="ml-0 p-2" 
                      onClick={()=>handleButton()}
                      color="primary">Buscar</Button>
                      
                  </Col>
                  { response.loading && <Col md="1">
                    <Spinner/>
                  </Col>}
                </Row>
              </Col>
            </Row>
            
          </CardBody>
        </Card>
        {( respuestas.length) > 0 && 
        <Card>
          <CardBody>
           
            <Row className="mb-3 mt-2">
              <Col>
              { programa.id ?
                <Button color="primary" size="sm" onClick={()=>handleButtonExcel()}>
                  Descargar Excel
                </Button>
                : <h5>Últimas Respuestas</h5>   
              } 
              </Col>
            </Row>
            
                <RespuestasTable respuestas={respuestas}/>
            
          </CardBody>
        </Card>}
      </Col>
    </Row>
  )
}
