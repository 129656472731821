import React, { useState } from 'react'
import { Alert, Button, Card, CardBody, CardHeader, Row, Spinner } from 'reactstrap'
import { deleteSolicitudesAtendidas } from 'redux/actions/solicitud.actions';
import { ModalConfirmDelete } from './ModalConfirmDelete';

export const Configuracion = () => {

  const [showModal, setShowModal ] = useState(false);
  const [response, setResponse ] = useState({});


  const styleButton = {
    borderRadius: 0,
    backgroundColor: "#f8f9fa",
    color: "#344767",
    "&:hover": {
        background: "#efefef"
    }
  };

  const handleDelete = ()=>{
    setResponse({ loading: true })
    deleteSolicitudesAtendidas()
    .then( res => setResponse( {color: "success", message: res.data.message } ))
    .catch( err => setResponse({ color: "warning", message: err.response.data.message }))
  }

  return (
    <>
      <Card className="px-4">
        <CardHeader className="pl-0">
           <h5 className="mb-0" style={{color:"#394c6b"}}>Vaciar Solicitudes Atendidas</h5>
        </CardHeader>
        <hr className="mt-1 mb-0"/>
        <CardBody className="pt-0 ml-2 pb-0">
          <Row style={{ alignItems: 'center' }}>
            <p className="mb-0 mr-1">Elimina todas las solicitudes que ya fueron atendidas. </p>
            <Button onClick={()=>setShowModal(true)} color="danger" style={styleButton} size="sm">Eliminar</Button>
          </Row>
        </CardBody>
      </Card>
      <ModalConfirmDelete 
        showModal={showModal}
        setShowModal={setShowModal}>
          <div>
            { response.message && 
              <Alert color={response.color}><strong>{response.message} - Actualiza la página.</strong></Alert>}
            <p className="mb-0">Se eliminaran permanentemente todas las solicitudes atendidas. </p>
            <Button color='danger' size="sm" onClick={()=>handleDelete()}>Eliminar</Button>
            <Button size="sm" onClick={()=>setShowModal(false)}>Cerrar</Button>
            { response.loading && <Spinner className="ml-5"/>}
          </div>
      </ModalConfirmDelete>
    </>
  )
}
