import React, { useState } from 'react';
import {Button, Card, CardHeader, CardBody, Col, Modal, Row, CardTitle, Spinner, Alert } from 'reactstrap';
import moment from 'moment-timezone';
import { deleteRespuestasByProgramaAndDates } from 'redux/actions/preinscripcion.actions';

export const ModalConfirmarDeleteByPrograma = ( {showModal, setShowModal, body, programaNombre = "Carrera"}) => {

  const [response, setResponse ] = useState({});

  const handleDelete = () =>{
    setResponse({ loading: true });
    const {programaId} = body; 
    deleteRespuestasByProgramaAndDates( programaId, body.date1, body.date2 )
    .then( res => {
      if( res.data.respuesta === 0 ){
        setResponse({type: "warning", message:"No se encontraron respuestas en este rango. No se eliminó nada."})
      }else{
        setResponse({ type: "success", message: `Se han eliminado correctamente ${ res.data.respuesta} respuestas.`})
      }
      
    }).catch( err => setResponse( {type: "danger", message: err.response.data.message }));
  }

  return (
    <Modal className="modal-dialog-centered "
      size="lg"
      isOpen={showModal}
      toggle={() => setShowModal(false)}>

      <Card className="mx-2">
        <CardHeader>
            <CardTitle>
                <h6 className="text-danger">¿Estás seguro de realizar esta accion?</h6>
            </CardTitle>
        </CardHeader>
        <CardBody className="mx-5">
            { response.message && <Alert color={response.type}><strong>{ response.message }</strong></Alert>}
            <p> Se <strong> eliminarán de manera permanente </strong>
                todas las respuestas de la carrera 
                <strong> {programaNombre} </strong> 
                que hayan sido enviadas entre el 
                <strong> { moment( body.date1 ).format("DD/MM/YYYY") } </strong>
                 y el 
                <strong> { moment( body.date2 ).format("DD/MM/YYYY") } </strong>. </p>
            <Row>
              <Col md="12" className="text-right">
                { response.loading && <Spinner/>}
              </Col>
              <Col md="12" className="text-right">
                  <Button color="danger" size="sm" onClick={()=>handleDelete()}>Confirmar</Button>
              </Col>
            </Row>
        </CardBody>
      </Card>
    </Modal>
  )
}
