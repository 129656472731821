import { SearchProceso } from 'components/asignaciones/estudiante/SearchProceso';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Col, FormFeedback, Input, Row } from 'reactstrap'
import { initStudent } from 'redux/actions/student.actions';

export const Asignaciones = () => {

  const user = useSelector( state => state.auth.user );
  const fetched = useSelector( state => state.estudiante.fetched );
  const programas_inscrito = useSelector( state => state.estudiante.programas_inscrito );

  const [ errors, setErrors ] = useState({});
  const [ programas, setProgramas ] = useState([]);
  const [ programaId, setProgramaId ] = useState("default");
  const [ response, setResponse ] = useState([]);
  const [ programa, setPrograma ] = useState({});
  const [ showSearchForm, setShowSearchForm ] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!fetched){
      dispatch( initStudent( user.username ))
    }
  }, [])

  useEffect( () => {
    if(programas_inscrito){
      setProgramas(programas_inscrito);
    }
  }, [programas_inscrito]);
  
  const handleButton = () => {
    setErrors({});
    setResponse({});
    setPrograma({});
    if( programaId === "default"){
      setErrors( {programaId : "Selecciona una carrera para continuar."} );
      return ;
    }
    const selectedProgram = programas.find( p => p.id.toString() === programaId );
    setPrograma( selectedProgram );
    setShowSearchForm( true );
  }
  
  return (
    <div className="content">
        <h6>Carrera para Asignación</h6>
        <Row className="mb-3">
          <Col xs="12" sm="12" md="6">
            
          { response.message && <Alert color={response.type}>{response.message}</Alert>}
            <Row style={{ alignItems: 'center'}}>
              
              <Col xs="8" md="10">
              
                <Input
                disabled={showSearchForm}
                value={ programaId }
                onChange={ (e) => setProgramaId( e.target.value )}
                type="select"
                invalid={ !! errors.programaId }>
                  <option key="default" value="default" disabled hidden>Selecciona la carrera.</option>
                  { 
                    programas.map( p => 
                      <option key={p.id} value={p.id}>{p.codigo} - {p.nombre}</option>
                    )
                  }
                </Input>
                <FormFeedback>
                          { errors.programaId }
                </FormFeedback>
              </Col>
              <Col  xs="4" md="2" className="pl-0">
                <Button style={{ backgroundColor:"#31708f"}} 
                  disabled={showSearchForm}
                  className="ml-0 p-2" 
                  onClick={()=>handleButton()}
                  color="primary">Continuar</Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {
            showSearchForm && <SearchProceso programaId={programa.id} programaNombre={programa.nombre}/>
        }
    </div>
  )
}
