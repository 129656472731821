import React, { useState } from 'react'
import {Button, Card, CardHeader, CardBody, Col, Modal, Row, CardTitle, Alert, Spinner } from 'reactstrap';
import { deleteAll } from 'redux/actions/preinscripcion.actions';

export const ModalDeleteAll = ({showModal, setShowModal}) => {

  const [ response, setResponse ] = useState({});


  const handleDelete = ()=>{
    setResponse({ loading: true });
    deleteAll()
    .then( res => setResponse({ type: "success", message: res.data.message }))
    .catch( err => setResponse( {type: "danger", message: err.response.data.message }));
  }
  return (
    <Modal className="modal-dialog-centered "
      size="md"
      isOpen={showModal}
      toggle={() => setShowModal(false)}>

      <Card className="mx-2">
        <CardHeader>
          <CardTitle>
            <h6 className="text-danger"> CUIDADO - ¿Estás seguro de realizar esta accion?</h6>
          </CardTitle>
        </CardHeader>
        <CardBody className="mx-4">
          { response.message && <Alert color={response.type}>{ response.message }</Alert>}
           <p><strong> Se eliminarán de manera permanente todas las respuestas de todas las carreras.</strong></p>
            <Row>
              <Col md="12" className="text-right">
                { response.loading && <Spinner/>}
              </Col>
              <Col md="12" className="text-right">
                  <Button color="danger" size="sm"
                  onClick={()=>handleDelete()}>Confirmar</Button>
              </Col>
            </Row>
        </CardBody>
      </Card>
    </Modal>
  )
}
