import { TooltipItem } from 'components/utils/TooltipItem'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, ListGroupItem, Row } from 'reactstrap'
import moment from 'moment';

export const ProcesoListItem = ({proceso}) => {
  return (
    <ListGroupItem key={proceso.id} className="mb-2 py-1" style={{
        borderBottomColor: '#ffff',
        backgroundColor: '#fffff',
        borderColor: '#fffff'
    }}>
        <Row>
          <Col >
            <h6>{ proceso.anio }, Semestre {proceso.semestre}</h6>
            <p className="text-muted">Periodo: {proceso.periodo}</p>
          </Col>
          <Col className="text-right">
            <Row style={{ alignItems: 'center'}}>
              <Col className="text-right mr-2">
                <p className="text-muted my-0">Creado: { moment(proceso.createdAt).format("DD/MM/YYYY")}</p>
                <p className="text-muted my-0">Fin:  { moment(proceso.endAt).format("DD/MM/YYYY")}</p> 
              </Col>
              <div>
                <Link to={`/admin/asignaciones/proceso/${proceso.id}`}>
                    <Button id={`edit-${proceso.id}`} color="primary" size="sm" style={{backgroundColor:'#f7f7f7'}}>
                      <div className="stats" >
                        <i className="far fa-eye" style={{color:"#344767"}}></i>                                            
                      </div>
                    </Button>
                    <TooltipItem target={`edit-${proceso.id}`} title={"Ver"}/>
                </Link>
              </div>
            </Row>
          </Col>
        </Row>
      </ListGroupItem>
  )
}
