import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, CardBody, CardHeader, CardTitle, Col, FormFeedback, FormGroup, Input, Modal, Row } from 'reactstrap'
import { asociarEstudianteAPrograma } from 'redux/actions/programa.actions';
import { getProgramas } from 'redux/actions/programa.actions';
import { isObjEmpty } from 'utils/helpers';

export const ModalAsociarACarrera = ({studentId, studentNombre, showModal, setShowModal, getStudent}) => {
  
    const [ programas, setProgramas ] = useState([]);
    const [ programa, setPrograma ] = useState("default");
    const [ errors, setErrors ] = useState({});
    const [ response, setResponse ] = useState({});

    useEffect(() => {
        getProgramas().then( res => setProgramas( res.data.programas ) )
    }, [])

    const handleSubmit = ()=> {
        
        setErrors({});        
        const errores = checkErrors();
        if( !isObjEmpty( errores ) ){
            setErrors(errores);
            setResponse({ loading: false })
            return;
        }

        asociarEstudianteAPrograma( { programaId:programa, studentId })
        .then( res => {
            setResponse({ type: "success", message: res.data.message })
            getStudent();
        })
        .catch( err => setResponse({ type: "danger", message: err.response.data.message }))
    }

    const  checkErrors = ()=>{
        const errores = {};
        if( programa === "default" ){
            errores.programa = "Por favor selecciona una carrera.";
        }
        return errores;
    }
    return (
    <Modal className="modal-dialog-centered "
      size="md"
      isOpen={showModal}
      toggle={() => setShowModal(false)}>

      <Card className="card-user">
        <CardHeader>
            <CardTitle  tag="h6">Asociar estudiante -{studentNombre }- a carrera</CardTitle>
        </CardHeader>
        <CardBody>
          <Row className="mt-3 mx-3">
            <Col md="12">
              { response.message && <Alert color={response.type}>{response.message}</Alert> }
              <FormGroup>
                  <label>CARRERA<label className="text-warning mb-2"><strong>*</strong></label></label>
                  <Input
                    className="text-dark"
                    placeholder="Selecciona la carrera a la que pertenecera el curso."
                    name="programa"
                    value={ programa }
                    onChange={  (e) => setPrograma( e.target.value ) }
                    invalid={ !! errors.programa }
                    type="select">
                      <option key="default" value="default" disabled hidden>Selecciona la carrera.</option>
                      {
                        programas.map( p => <option key={p.id} value={p.id}>{p.codigo}. {' '}  {p.nombre}</option>)
                      }
                  </Input>
                  <FormFeedback>
                    { errors.programa }
                  </FormFeedback>
                  <Button onClick={()=>handleSubmit()} className="mt-3" color="primary">Asociar</Button>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Modal>
  )
}
