import React from 'react'
import moment from 'moment';
import { jsPDF} from 'jspdf';
import logo from "../../assets/img/logotipoCUNORI-2.png";
import { Button } from 'reactstrap';

export const BoletaSolicitud = ({ solicitud, estudiante, carrera  }) => {

    const pdfGenerate = () => {

        const today = moment(new Date()).format("DD/MM/YYYY");
        const doc = new jsPDF({
            format: 'a4',
            unit: "cm"}
        );
        doc.setFont('Helvetica','bold');
        doc.setFontSize(11);
        doc.addImage(logo, 'PNG',  2, 2.5, 1.9, 1.8);
        doc.text("Universidad San Carlos de Guatemala", 7.2, 3);
        doc.text(`Centro Universitario de Oriente`, 7.9, 3.5);
        doc.text(`Departamento de Estudios de Postgrado`, 6.9, 4);

        doc.setFontSize(10);
        doc.text('Detalle de Solicitud:', 2.5, 5.6);
        doc.text('Datos del Estudiante:', 2.5, 9.2);

        doc.setFont('Helvetica','normal');
        doc.text(`Generada el: ${ today }`, 8.5, 4.5);

        doc.text(`Tipo:  ${solicitud.tipo}`, 3, 6.2 );
        doc.text(`Status:  ${solicitud.status}`, 3, 6.7 );
        doc.text(`Fecha:  ${ moment(solicitud.createdAt).format("DD/MM/YYYY")}`, 3, 7.2)
        doc.text(`Monto:  Q${solicitud.monto || "Sin registro."}`, 3, 7.7 )
        doc.text(`Comentario:  ${solicitud.comentario || "Sin registro."}`, 3, 8.2, {align:"justify", lineHeightFactor: 1.5, maxWidth: 15.5});

        doc.text(`Estudiante:  ${estudiante.nombre}`, 3, 9.8 );
        doc.text(`DPI:  ${estudiante.dpi}`, 3, 10.3 );
        doc.text(`Carnet:  ${estudiante.carnet  || "Sin registro."}`, 3, 10.8 );
        doc.text(`Carrera:  ${carrera}`, 3, 11.3 );


        doc.save(`ReciboHonorario.pdf`);
    }

    return (
        <Button
                className="btn btn-fill mr-1"
                size="sm"
                color="primary"
                onClick={ pdfGenerate }>
            Descargar Solicitud
        </Button>
    )
}
