import React from 'react'
import { Card, CardBody, CardHeader, CardTitle, Modal } from 'reactstrap'

export const ModalConfirmDelete = ({ showModal, setShowModal, children }) => {
  return (
    <Modal className="modal-dialog-centered "
    size="md"
    isOpen={showModal}
    toggle={() => setShowModal(false)}>

    <Card className="mx-2">
      <CardHeader>
        <CardTitle>
          <h6 className="text-danger">¿Estás seguro de realizar esta acción?</h6>
        </CardTitle>
      </CardHeader>
      <CardBody className="mx-5">
        {children}
      </CardBody>
    </Card>
    </Modal>
  )
}
