import  types  from "../types/types";

const initialState = {
    checking: true,
    loggedIn: false,
    user: {}
}

export const authReducer = ( state = initialState, action )=>{
    
    switch (action.type) {
        case types.authSetUser:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return {
                ...state
            }
    }
}