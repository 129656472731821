import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

// Debes registrar las fuentes que desees utilizar, por ejemplo Arial.
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const GenerarCertificacion = (selectedEstudianteText, DPI, carnet, selectedCarreraText, cursos, diaLetras, mesLetras, 
  anioLetras, sumaTotalCreditos, coordinador, director) => {

  // Definir los márgenes en centímetros
  const margenIzquierdo = 0.7;
  const margenDerecho = 0.7;
  const margenSuperior = 0.7;
  const margenInferior = 0.7;
  const ancho = 21.59;
  const alto = 33;

  // Función para convertir centímetros a píxeles (utilizada para los márgenes)
  function cmToPoints(cm) {
    // 1 cm equivale a aproximadamente 28.3464567 puntos
    return cm * 28.3464567;
  }

  //console.log('Ancho de página en puntos:', cmToPoints(ancho));
  //console.log('Alto de página en puntos:', cmToPoints(alto));
  
  // Definir el contenido del documento PDF
  const docDefinition = {
    pageSize: { width: cmToPoints(ancho), height: cmToPoints(alto) },
    pageOrientation: 'portrait', // Orientación de la página (vertical)
    //pageMargins: [cmToPoints(margenIzquierdo), cmToPoints(margenSuperior), cmToPoints(margenDerecho), cmToPoints(margenInferior)],
    content: [
      {
        text: selectedEstudianteText,
        style: 'subheader',
        absolutePosition: { x: cmToPoints(14.0), y: cmToPoints(4.25) }, // Posición relativa al borde superior izquierdo
      },
      {
        text: DPI,
        style: 'subheader',
        absolutePosition: { x: cmToPoints(7.0), y: cmToPoints(5.25) }, // Posición relativa al borde superior izquierdo
      },
      {
        text: carnet,
        style: 'subheader',
        absolutePosition: { x: cmToPoints(18.0), y: cmToPoints(5.25) }, // Posición relativa al borde superior izquierdo
      },
      {
        text: selectedCarreraText,
        style: 'subheader',
        absolutePosition: { x: cmToPoints(0.8), y: cmToPoints(7.0) }, // Posición relativa al borde superior izquierdo
      },
      {
        table: {
          //headerRows: 1,
          //widths: [cmToPoints(1), cmToPoints(1.9), cmToPoints(8), cmToPoints(2.4), cmToPoints(3.6), cmToPoints(2.7)],
          widths: [cmToPoints(0.7), cmToPoints(1.6), cmToPoints(7.65), cmToPoints(2.45), cmToPoints(3.3), cmToPoints(2.4)],
          body: [
            /*[
              { text: 'No.', style: 'tableHeader' },
              { text: 'Código del Curso', style: 'tableHeader' },
              { text: 'Nombre del Curso', style: 'tableHeader' },
              { text: 'Calificación en Números', style: 'tableHeader' },
              { text: 'Calificación en Letras', style: 'tableHeader' },
              { text: 'Fecha de Aprobado', style: 'tableHeader' },
            ],*/
            ...cursos.map((curso, index) => [
              { text: index + 1, style: 'colCenter' },
              { text: curso.codigoCurso, style: 'colCenter' },
              { text: curso.nombreCurso, style: 'colLeft' },
              { text: curso.notaNumerica, style: 'colCenter' }, // Alineado a la derecha
              { text: curso.notaEnLetras, style: 'colLeft' },
              { text: curso.fechaModificacion, style: 'colCenter' }, // Alineado al centro

            ]),
          ],
          style: 'tablaSinBordes', // Establecer el estilo de la tabla
        },
        absolutePosition: { x: cmToPoints(0.9), y: cmToPoints(8.8) },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            // Establece el color de fondo de la celda a transparente
            return [0, 0, 0, 0]; // Color transparente (sin color)
          },
          hLineColor: function (i, node) {
            // Establece el color de las líneas horizontales a transparente
            return [0, 0, 0, 0]; // Color transparente (sin color)
          },
          vLineColor: function (i, node) {
            // Establece el color de las líneas verticales a transparente
            return [0, 0, 0, 0]; // Color transparente (sin color)
          },
        },
      },
      {
        text: `Créditos acumulados: ${sumaTotalCreditos}`,
        style: 'creditos',
        absolutePosition: { x: cmToPoints(0.9), y: cmToPoints(27.4) }
      },
      {
        text: `La escala de evaluación es de 0 a 100. Conforme al artículo 20 del capítulo 2 del Reglamento General de Evaluación y Promoción del Estudiante de la Universidad de San Carlos de Guatemala, en el que se modifica la nota de promoción a 61, a partir de los cursos aprobados en el 2do. semestre del año 2005 y para el postgrado la nota de promoción es de 70, según el artículo 83 literal A del reglamento de Postgrado de la Universidad. Para los usos legales que al interesado convenga, se extiende la presente en CHIQUIMULA a (los) ${diaLetras} día(s) del mes de ${mesLetras} del año ${anioLetras}.`,
        style: 'textInf',
        absolutePosition: { x: cmToPoints(0.9), y: cmToPoints(28.0) }
      },
      {
        text: coordinador,
        style: 'subheader',
        absolutePosition: { x: cmToPoints(1.7), y: cmToPoints(30.6) }      
      },
      {
        text: director,
        style: 'subheader',
        absolutePosition: { x: cmToPoints(13.5), y: cmToPoints(30.6) }      
      }

    ],
    styles: {
      header: {
        fontSize: 10,
        bold: true,
        alignment: 'center',
        margin: [0, 0, 0, 20], // Márgenes del elemento (abajo)
      },
      subheader: {
        fontSize: 9,
        bold: false,
        margin: [0, 5, 0, 5],
      },
      tableHeader: {
        fontSize: 9,
        bold: true,
        alignment: 'center',
        margin: [0, 0, 0, 0],
        verticalAlignment: 'top',
      },
      colLeft: {
        fontSize: 8,
        alignment: 'left',
        margin: [0, 0, 0, 0],
        verticalAlignment: 'top',
      },
      colCenter: {
        fontSize: 8,
        alignment: 'center',
        margin: [0, 0, 0, 0],
        verticalAlignment: 'top',
      },
      textInf: {
        fontSize: 8,
        alignment: 'justify',
        margin: [0, 0, 0, 0],
        verticalAlignment: 'top',
      },
      creditos: {
        fontSize: 8,
        alignment: 'left',
        bold: true,
        margin: [0, 0, 0, 0],
        verticalAlignment: 'top',
      },
      tablaSinBordes: {
        border: 'hidden', // Esto eliminará los bordes de la tabla
      },
    },
  };


  // Generar el PDF
  const pdfDoc = pdfMake.createPdf(docDefinition);
  pdfDoc.download(`Certificación ${selectedEstudianteText}.pdf`);
};
