import React from 'react';
import { Route, Navigate, Routes, BrowserRouter } from 'react-router-dom';
import { Login } from 'views/Login';
import AdminLayout from 'layouts/Admin';
import StudentLayout from 'layouts/Student';
import { checkForToken } from 'utils/checkForToken';
import { Home } from 'views/Home';
import { FormPreinscripcion } from 'views/FormPreinscripcion';

export const AppRouter = () => {
  checkForToken();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/preinscripcion" element={<FormPreinscripcion />} />
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="/student/*" element={<StudentLayout />} />
        <Route path="/home" element={<Home />} />
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
}
