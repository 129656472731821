import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Button, Card, CardFooter, CardBody, Col, Row, Table, CardHeader } from 'reactstrap'
import { getProcesoCursosDisponiblesYAsignadosByStudent } from 'redux/actions/asignaciones.actions';
import { asignarCursosAction } from 'redux/actions/asignaciones.actions';
import { ModalConfirmarAsignacion } from './ModalConfirmarAsignacion';
import { ModalInfo } from './ModalInfo';

export const MostrarCursos = ( { procesoProp }) => {

  const studentId = useSelector( state => state.estudiante.student.id );
  const [ cursosDisponibles, setCursosDisponibles ] = useState([]);
  const [ cursosAsignados, setCursosAsignados ] = useState([]);
  const [ proceso, setProceso ] = useState({});
  const [ cantidadSeleccionados, setCantidadSeleccioandos ] = useState(0);
  const [ showModal, setShowModal ] = useState( false );
  const [ showModalInfo, setShowModalInfo ] = useState( false );
  const [ response, setResponse ] = useState( {} );


  useEffect( ()=>{ setProceso(procesoProp)} , [procesoProp])
  
  useEffect( ()=> {
    if(proceso.id){
      getCursosDeProceso();
    }
  }, [proceso]);

  const getCursosDeProceso = ()=>{
    getProcesoCursosDisponiblesYAsignadosByStudent( studentId, proceso.id )
    .then( res => {
        setCursosDisponibles( res.data.cursosDisponibles );
        setCursosAsignados( res.data.cursosAsignados );
    }).catch( err => console.log(err ))
  }

  const handleAsignar = ()=>{
    if( proceso.finalizado === true ){
      setResponse({ type: "alert", color: "danger", message: "El periodo de asignación ha vencido, no puedes asignarte estos cursos."})
      return;
    }
    if( cantidadSeleccionados < 1 ){
      setResponse({ type: "alert", color: "danger", message: "Selecciona los cursos en los que deseas asignarte."})
      return;
    }
    
    
    setShowModal( true );
  }

  const asignarCursos = ()=>{
    const cursosSeleccionados = cursosDisponibles.filter( c => c.selected === true );
    const objeto = {
      procesoId: proceso.id,
      studentId,
      cursosId : cursosSeleccionados.map( c => c.id)
    }

    asignarCursosAction(objeto)
    .then( res => {
      getCursosDeProceso();
      setCantidadSeleccioandos(0);
      setShowModal( false );
      setShowModalInfo( true );
    })
    .catch( err => console.log(err))
  }

  const handleAddOrDelete = ( cursoId, action )=>{
    setResponse({});
    let array = cursosDisponibles; 
    if( action === "add" ){
      array = cursosDisponibles.map( c => {
        if( c.id === cursoId ){
          setCantidadSeleccioandos( prev => prev + 1);
          return ({ ...c, selected: true });
        }else{ 
          return c; 
        }
      })
    }else if(action === "delete"){
      array = cursosDisponibles.map( c => {
          if( c.id === cursoId ){
            setCantidadSeleccioandos( prev => prev - 1);
            return ({ ...c, selected: false });
          }else{ return c; }
      })
    }
    setCursosDisponibles( array );
  }

  return (
    <>
    
      <Row className="mt-5">
        <Col className="text-center">
          <h5>Cursos Disponibles</h5>
          <Card>
            { proceso.finalizado === true && 
            <CardHeader>
              <Row>
                <Col md="12">
                  <p className="text-danger">El periodo de asignación ha vencido, no puedes asignarte estos cursos.</p>
                </Col>
              </Row>
            </CardHeader>}
            <CardBody>
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Código</th>
                  <th>Curso</th>
                  <th>Carrera</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                { cursosDisponibles.map ( (c, i) => (
                <tr key={c.id} style={ c.selected ? { backgroundColor: "#e2f0fe"} : {backgroundColor: "#ffffff"}}>
                    <td>{i+1}</td>
                    <td>{c.codigo}</td>
                    <td>{c.nombre}</td>
                    <td>{ c.carrera }</td>
                    <td>
                    { c.selected ? 
                      <Button size="sm" color="warning" onClick={ ()=>handleAddOrDelete(c.id, "delete")}>
                        Quitar
                      </Button>
                    :
                      <Button size="sm" color="primary" onClick={ ()=>handleAddOrDelete(c.id, "add")}>
                          Agregar
                      </Button>
                    }
                    </td>
                </tr>
                ))}
              </tbody>
            </Table>
            <hr/>
            </CardBody>
            <CardFooter>
              <Row style={{ justifyContent: 'center'}}>
                { (response.type === "alert") && 
                  <p className="text-danger"><strong>{ response.message }</strong></p>
                }
              </Row>
              <Row className="mb-3 mx-5" style={{ justifyContent: 'center', alignItems:'center'}}>                
                  <h6 className="mb-0">Cursos Seleccionados: { cantidadSeleccionados }</h6>
                  <Button color="success" className="ml-4" size="sm" style={{ backgroundColor:"#5aaf00"}} onClick={()=>handleAsignar()}>
                    Asignar
                  </Button>
              </Row>
              
              
            </CardFooter>
          </Card>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="text-center">
          <h5>Cursos Asignados En Este Proceso</h5>
          <Card>
            <CardBody>
            <Table striped>
              <thead>
                <tr>
                  <th>Año</th>
                  <th>Semestre</th>
                  <th>Periodo</th>
                  <th>Código</th>
                  <th>Curso</th>
                  <th>Carrera</th>
                </tr>
              </thead>
              <tbody>
                { cursosAsignados.map ( (c) => (
                <tr key={c.id}>
                    
                    <td>{proceso.anio}</td>
                    <td>{proceso.semestre}</td>
                    <td>{proceso.periodo}</td>
                    <td>{c.codigo}</td>
                    <td>{c.nombre}</td>
                    <td>{ c.carrera }</td>
                </tr>
                ))}
              </tbody>
            </Table>
            <hr/>
            </CardBody>
          </Card>
        </Col>
      </Row>
    
      { showModal && <ModalConfirmarAsignacion 
        title={"Cursos Asignados Correctamente."}
        showModal={ showModal }
        setShowModal={ setShowModal }
        asignarCallback={ asignarCursos } />}

      { showModalInfo && <ModalInfo 
        showModal={showModalInfo}
        message={"Cursos asignados correctamente"}
        setShowModal={ setShowModalInfo }
      />}
    </>
  )
}
