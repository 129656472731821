//Para evitar colocar el token en cada peticion, se lo seteamos a Axios

import axios from 'axios';

export const setTokenRequest = ( token ) => {
    if( token ) {
        axios.defaults.headers.common["x-access-token"] = "Bearer "+token;
    }else{
        delete axios.defaults.headers.common["x-access-token"];
    }
}