import React from 'react'
import { Button, Col, ListGroup, ListGroupItem, Row } from 'reactstrap'

export const CursosPrerequisito = ({ handleDelete,prerequisitos = []}) => {

  return (
    <Row className="mt-3 mx-3 ">
      <Col>
        <h6 className="text-muted">Cursos Prerequisito: { prerequisitos.length}</h6>
        <ListGroup className="mt-4 mx-4" flush>
            { prerequisitos.map( c => 
            <ListGroupItem className="mb-2 mx-2  py-1" key={c.id} style={{
                backgroundColor: '#f8f9fa',
                borderColor: '#f8f9fa'
            }}>
                <Row className="mx-1" style={{alignItems: 'center', justifyContent: 'space-between'}}>
                <div>
                    <h6 className="m-0 text-muted">{c.codigo} - {c.nombre}</h6>
                </div>
                <div>
                <Button className="m-0" style={{ backgroundColor:'#f8f9fa' }} onClick={()=>handleDelete(c.id)} >
                    <div className="stats" style={{display:'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <i className="fas fa-trash text-danger my-0 mr-2 p-0"/>
                    <p className="text-danger m-0 p-0" >ELIMINAR</p>
                    </div>
                </Button>
                </div>
                </Row>
            </ListGroupItem>                      
            )}
        </ListGroup>
      </Col>
    </Row>
  )
}
