import React from 'react'
import { Card, CardBody, CardHeader, ListGroup } from 'reactstrap'
import { withSolicitudesEstudiante } from './HOCS/withSolicitudesEstudiante';
import {  withSolicitudesByStatus } from './HOCS/withSolicitudesByStatus';
import { SolicitudItem } from './SolicitudItem';

const SolicitudList = ({ solicitudes, title="Solicitudes" }) => {
  return (
    <Card>
      <CardHeader>
        <h5 className="mb-0" style={{color:"#394c6b"}}>{title}</h5>
        <hr className="mt-1"/>
      </CardHeader>
      <CardBody>
        <ListGroup flush>
            { solicitudes.map( s => <SolicitudItem key={s.id} solicitud={s}/>)}
        </ListGroup>
      </CardBody>
    </Card>
  )
}

const SolicitudListByStatus= withSolicitudesByStatus(SolicitudList);
const SolicitudListEstudiante = withSolicitudesEstudiante(SolicitudList);
export { SolicitudListByStatus, SolicitudListEstudiante };