import React, { useState } from 'react'
import { Button, Card, CardBody,  CardHeader, CardTitle, Col,  Row } from 'reactstrap'
import { ModalAgregarEstudiante } from './ModalAgregarEstudiante';

export const ProgramaEstudiantes = ({programa}) => {

    const [ showModal, setShowModal ] = useState(false); 
   
  return (
    <>
        <Row>
          <Col md="12">
            <Card className="" >
              <CardHeader style={{
                backgroundColor: '#efefef',
                borderColor: '#f7f7f7'
             }}>
                 <Row>
                     <Col xs="6" sm="6" md="8">
                        <CardTitle className="p-0" tag="h5">Estudiantes</CardTitle>                     
                     </Col>
                     <Col className="text-right"xs="6" sm="6" md="4">
                        <Button size="sm" color="success" onClick={()=>setShowModal(true)}>
                            Agregar 
                        </Button>
                     </Col>
                 </Row>
              </CardHeader>
              <CardBody>
                  <label>
                    <h6>Ver todos los estudiantes</h6>
                  </label>
                  
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* MODAL */}
        <ModalAgregarEstudiante 
            programaId = {programa.id}
            programaNombre = {programa.nombre}
            setShowModal={setShowModal} 
            showModal={showModal}/>
        
    </>
  )
}
