import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, FormFeedback, FormGroup, Input, Row } from 'reactstrap'
import { getProgramas } from 'redux/actions/programa.actions';
import { isObjEmpty } from 'utils/helpers';
import validator from 'validator';
import moment from 'moment';
import { ModalConfirmarDeleteByPrograma } from './ModalConfirmarDeleteByPrograma';
import { ModalDeleteAll } from './ModalDeleteAll';

export const ModalDetele = ({ setShowModal }) => {

  const [ programas, setProgramas ] = useState([]);
  const [ body, setBody ] = useState( { programaId: "default", date1: "", date2: "" })  
  const [ errors, setErrors ] = useState({});
  const [ programa, setPrograma ] = useState({});
  const [ showConfirmModal, setShowConfirmModal ] = useState( false );
  const [ showDeleteAllModal, setShowDeleteAllModal ] = useState( false );

  useEffect( ()=>{
    getProgramas()
    .then(res => {
        setProgramas(res.data.programas);
    })
    .catch( err => console.log(err))
  }, [] );

  const onChange = ( e ) => {
      const { name, value } = e.target;
      setBody( prev => ({ ...prev, [name]: value }));
  } 

  const handleDeleteByPrograma = ()=>{
    setErrors({});
    const errores = checkErrors();
    if( !isObjEmpty(errores) ){
        setErrors(errores);
        return;
    }

    setPrograma( programas.find( p => p.id == body.programaId ) );
    setShowConfirmModal( true );
  }

  const checkErrors = ()=>{
    const errores = {};
    if( body.programaId === "default" || body.programaId === "empty" ){
      errores.programaId = "Por favor selecciona la carrera donde deseas eliminar las respuestas."
    }

    if(!validator.isDate(body.date1)){
        errores.date1 = 'Por favor selecciona una fecha.'
    }else{
        const dateIngresada = moment(body.date1);
        const now = moment();

        if(dateIngresada > now){
            errores.date1 = 'No puedes seleccionar una fecha mayor a la fecha de hoy.'
        }
    }

    if(!validator.isDate(body.date2)){
        errores.date2 = 'Por favor selecciona una fecha.'
    }else{
        const dateIngresada = moment(body.date2);
        const now = moment();

        if(dateIngresada > now){
            errores.date2 = 'No puedes seleccionar una fecha mayor a la fecha de hoy.'
        }
    }

    if( validator.isAfter(body.date1, body.date2) ){
        errores.date1 = 'La fecha inicial debe ser menor a la fecha final'
        errores.date2 = 'La fecha final debe ser mayor a la fecha inicial.'
    }

    return errores;
  }

  return (
      <>
    <Card style={{backgroundColor:"#f9fafb"}} className="mx-3 py-3">
      
      <CardBody className="mx-2  my-0 py-0 px-4">
        <Col md="12" >
          <strong className="mb-0"> <label className="text-danger"><h6>OPCION 1 - Eliminar las respuestas en una carrera. </h6> </label> Elimina las respuestas de una carrea según el intervalo de fecha en el que fueron enviadas.</strong>
          <Row>
            <Col md="12">
              <FormGroup className="mt-3"> 
                <label className="text-dark">CARRERA<label className="text-warning m-0"><strong>*</strong></label></label>
                <Input
                className="text-dark ml-3"
                name="programaId"
                value={ body.programaId }
                onChange={ onChange }
                type="select"
                invalid={!!errors.programaId}
                    >
                        <option key="default"  value="default" disabled hidden>Selecciona la carrera.</option>
                        <option key="empty"  value="empty" disabled hidden>No hay carreras seleccionables.</option>
                        {
                            programas.map( p => <option key={p.id} value={p.id}>{p.nombre}</option>)
                        }
                </Input>
                <FormFeedback>
                        { errors.programaId }
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <label className="text-dark" >FECHA INICIAL</label>
                <Input className="text-dark ml-3" 
                    name="date1"
                    value={ body.date1 }
                    onChange={ onChange }
                    type="date"
                    invalid={ !! errors.date1 }/>
                  <FormFeedback>
                    { errors.date1 }
                  </FormFeedback>
                </FormGroup>
            </Col>
            <Col md="6">
                <FormGroup>
                <label className="text-dark">FECHA FINAL</label>
                <Input className="text-dark ml-3" 
                    name="date2"
                    value={ body.date2 }
                    onChange={ onChange }                   
                    type="date"
                    invalid={ !! errors.date2 }/>
                  <FormFeedback>
                    { errors.date2 }
                  </FormFeedback>
                </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="text-right">
                <Button size="sm" color="primary"onClick={ ()=>handleDeleteByPrograma()} style={{ backgroundColor:"#31708f"}}>Eliminar</Button>
                <Button size="sm" color="danger" onClick={()=>setShowModal(false)}>Cancelar</Button>
            </Col>
          </Row>

        </Col>
      </CardBody>
      <hr/>
      <CardBody className="mx-2  my-0 py-0 px-4">
        <Col md="12" >
          <strong className="mb-0"> <label className="text-danger"><h6>OPCION 2 - Eliminar todas las respuestas.</h6></label> Esta opción eliminará todas las respuestas de preinscripción de todas las carreras en la base de datos.</strong>
          <Row>
            <Col className="text-right">
                <Button  style={{ backgroundColor:"#31708f"}} size="sm" color="primary" onClick={()=>setShowDeleteAllModal(true)}>Eliminar</Button>
                <Button size="sm" color="danger" onClick={()=>setShowModal(false)}>Cancelar</Button>

            </Col>
          </Row>
        </Col>
      </CardBody>
      
    </Card>
    { showConfirmModal && 
        <ModalConfirmarDeleteByPrograma
            body={body}
            programaNombre={ programa.nombre }
            showModal={showConfirmModal}
            setShowModal={setShowConfirmModal}/>
    }

    {
      showDeleteAllModal && 
      <ModalDeleteAll 
        showModal={ showDeleteAllModal }
        setShowModal={setShowDeleteAllModal}/>
    }
    </>
    

  )
}
