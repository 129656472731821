import axios from "axios"
import { GET_LAST_PROCESOS_ASIGNACION } from "utils/endpoints"
import { GET_PROCESO_CURSOS_DISPONIBLES_Y_ASIGNADOS } from "utils/endpoints"
import { GET_CURSOS_ASIGNABLES_BY_PROCESO } from "utils/endpoints"
import { CHANGE_DATE_PROCESO } from "utils/endpoints"
import { GET_EXCEL_ASIGNADOS_BY_PROGRAMA } from "utils/endpoints"
import { DESASIGNAR_ESTUDIANTE_EN_CURSO } from "utils/endpoints"
import { GET_ASIGNADOS_BY_PROGRAMA } from "utils/endpoints"
import { GET_PROCESOS_BY_PROGRAMA } from "utils/endpoints"
import { CREATE_CURSO_ASIGNABLE } from "utils/endpoints"
import { ASIGNAR_CURSOS } from "utils/endpoints"
import { GET_PROCESOS_BY_YEAR_PROGRAMAID } from "utils/endpoints"
import { SEARCH_PROCESO_ASIGNACION } from "utils/endpoints"
import { CREATE_PROCESO_ASIGNACION } from "utils/endpoints"
import { GET_PROGRAMAS_BY_ASIGNACION } from "utils/endpoints"

export const getProgramasByAsignacion = (activeAsignacion)=>{
    return new Promise( (resolve, reject) =>{
        axios.get( GET_PROGRAMAS_BY_ASIGNACION, { params: { activeAsignacion }})
        .then( res => resolve(res))
        .catch( err => reject(err))
    })   
}

export const createProcesoAsignacion = ( body )=>{
    return new Promise( (resolve, reject) => {
        axios.post( CREATE_PROCESO_ASIGNACION, body )
        .then( res => resolve(res) )
        .catch( err => reject(err) )
    })
}

export const searchProcesoAsignacion = ( body ) => {
    const { anio, semestre, periodo, programaId } = body;
    return new Promise( (resolve, reject) => {
        axios.get( SEARCH_PROCESO_ASIGNACION, {
            params: { anio, semestre, periodo, programaId }
        }).then( res => resolve( res ))
        .catch( err => reject( err ))
    })
}

export const getLastProcesosAsignacion = ( limit, programaId ) => {
    return new Promise( (resolve, reject) => {
        axios.get( GET_LAST_PROCESOS_ASIGNACION, {
            params: { limit, programaId }
        }).then( res => resolve(res))
        .catch( err => reject(err))
    })
}

export const getProcesoByYearAndProgramaId = ( year, programaId ) => {
    return new Promise( ( resolve, reject ) => {
        axios.get( GET_PROCESOS_BY_YEAR_PROGRAMAID , {
            params: { year, programaId }
        }).then( res => resolve(res))
        .catch( err => reject(err))
    })
}

export const asignarCursosAction = ( body )=>{
    return new Promise( (resolve, reject ) => {
        axios.post( ASIGNAR_CURSOS, body )
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
}

export const getCursosAsignablesByProceso = ( procesoId )=>{
    return new Promise( (resolve, reject ) => {
        axios.get( GET_CURSOS_ASIGNABLES_BY_PROCESO, { params:{ procesoId } } )
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
}

export const getProcesoCursosDisponiblesYAsignadosByStudent = ( studentId, procesoId ) =>{
    return new Promise( (resolve, reject) => {
        axios.get( GET_PROCESO_CURSOS_DISPONIBLES_Y_ASIGNADOS,  
            { params : { studentId, procesoId } })
        .then( res => resolve( res ))
        .catch( err => reject( err ))
    })
}

export const createCursoAsignable = ( {procesoId, cursoId} ) => {
    return new Promise( (resolve, reject) => {
        axios.post( CREATE_CURSO_ASIGNABLE, {procesoId, cursoId} )
        .then( res => resolve( res ))
        .catch( err => reject( err ))
    })
}

export const changeDateEndAtProceso = ( procesoId, endAt ) => {
    return new Promise( (resolve, reject) => {
        axios.patch( CHANGE_DATE_PROCESO, { procesoId, endAt })
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
}

export const getProcesosByPrograma = ( programaId ) => {
    return new Promise( (resolve, reject) => {
        axios.get( GET_PROCESOS_BY_PROGRAMA, { params: { programaId } })
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
}

export const getEstudianteAsignados = ( procesoId, cursoId ) => {
    return new Promise( (resolve, reject) => {
        axios.get( GET_ASIGNADOS_BY_PROGRAMA, { params: { procesoId, cursoId } })
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
}

export const desasignarEstudianteDeCurso = ( procesoId, estudianteId, cursoId) => {
    return new Promise( (resolve, reject) => {
        axios.delete( DESASIGNAR_ESTUDIANTE_EN_CURSO,  {params: { procesoId, estudianteId, cursoId }})
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
}

export const downloadExcelAsignados = ( procesoId, cursoId )=>{
    return new Promise( (resolve, reject) => {
        axios.get( GET_EXCEL_ASIGNADOS_BY_PROGRAMA, {
            responseType: 'blob',
            params:{ procesoId, cursoId }
        }).then( response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Estudiantes-Asignados.xlsx`); //or any other extension
            document.body.appendChild(link);
            link.click();
            resolve(response)
        })
        .catch( err => reject(err))
    });  
}