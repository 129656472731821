import React from 'react'
import { useState } from 'react';
import { Alert, Card, CardBody, CardHeader } from 'reactstrap'
import { crearSolicitud } from 'redux/actions/solicitud.actions';
import { isObjEmpty } from 'utils/helpers';
import { idMensualidad } from 'utils/tiposSolicitudes';
import validator from 'validator';
import CustomToast from '../../nuevas_funciones/CustomToast';

export const NuevaSolicitud = ({ children, correo, whatsapp, studentId }) => {

  const [solicitud, setSolicitud] = useState({ tipo: "default", programaId: "default" });
  const [errors, setErrors] = useState({});
  const [onLoading, setOnLoading] = useState(false);
  const [response, setResponse] = useState({});
  //Notificaciones
  const [showWarningToast, setShowWarningToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  // Función para mostrar la notificación de advertencia
  const showWarningNotification = (message) => {
    setToastMessage(message);
    setShowWarningToast(true);
    // Oculta la notificación después de un cierto tiempo
    setTimeout(() => {
      setShowWarningToast(false);
    }, 5000); // Puedes ajustar el tiempo en milisegundos
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setSolicitud(prev => ({ ...prev, [name]: value }));
    console.log("Tipo de solicitud seleccionado:", value);
    // Verifica si el tipo de solicitud es 'CONSTANCIAS O CERTIFICACIONES' y muestra la notificación
    if (value === '3') {
      console.log("Mostrar notificación: "+ value);
      showWarningNotification('Si deseas una certificación de cursos impresa, indicalo escribiendo "IMPRESA" en el comentario.');
    }
  }

  const onClick = () => {
    setOnLoading(true);
    const errores = validations();
    if (!isObjEmpty(errores)) {
      setErrors(errores);
      setOnLoading(false);
      return;
    }

    if ((!whatsapp || whatsapp === "") && (!correo || correo === "")) {
      setResponse(
        {
          color: 'danger',
          message: "Tu solicitud no se puede enviar puesto que no has proporcionado ni tu correo, ni tu contacto de Whatsapp en tus Datos Personales."
        });
      setOnLoading(false);
      return;
    }

    let body = { ...solicitud, studentId };
    if (solicitud.tipo !== idMensualidad.toString() && solicitud.monto) {
      const { monto, ...rest } = solicitud;
      body = rest;
    }

    crearSolicitud(body)
      .then(res => {
        setResponse({ color: 'success', message: res.data.message });
        setOnLoading(false);
      }).catch(err => {
        setResponse({ color: 'danger', message: err.response.data.message });
        setOnLoading(false);
      })

  }

  const validations = () => {
    setErrors({});
    const errores = {};
    if (solicitud.tipo === "default") {
      errores.tipo = "Por favor selecciona el tipo de solicitud que deseas realizar."
    }
    if (solicitud.programaId === "default") {
      errores.programaId = "Selecciona la carrera relacionada a tu solicitud."
    }
    if ((solicitud.tipo === idMensualidad.toString())) {
      if (!solicitud.monto || validator.isEmpty(solicitud.monto) || !validator.isNumeric(solicitud.monto)) {
        errores.monto = "Por favor ingresa el monto en números para la boleta de pago."
      }
    }
    if (solicitud.comentario && !validator.isLength(solicitud.comentario, { max: 150 })) {
      errores.comentario = "El comentario no debe sobrepasar los 150 caracteres."
    }

    return errores;
  }

  return (
    <React.Fragment>
      <Card className="mx-1">
        <CardHeader>
          <h5 style={{ color: "#394c6b" }}>Nueva Solicitud</h5>
        </CardHeader>
        <CardBody className="mx-3">
          {response.message && <Alert color={response.color}><strong>{response.message}</strong></Alert>}
          {React.cloneElement(children, {
            solicitud,
            onClick,
            onChange,
            onLoading,
            errors,
          })
          }
          {showWarningToast && (
            <CustomToast
              type="warning"
              message={toastMessage}
              onClose={() => setShowWarningToast(false)}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
