import React, { useState } from 'react';
import {Button} from 'reactstrap';
const URL_API = process.env.REACT_APP_API_URL

const BackupUpload = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => setFile(e.target.files[0]);

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('backup', file);

    try {
      const response = await fetch(`${URL_API}/backup/restore`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error al restaurar el backup.');
      }

      alert('Backup restaurado exitosamente.');
    } catch (error) {
      console.error('Error:', error);
      alert('Hubo un error al restaurar el backup.');
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <Button
      size="md"
      color="primary"
       onClick={handleUpload} disabled={!file}>
        Subir y Restaurar Backup
      </Button>
    </div>
  );
};

export default BackupUpload;
