/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { SolicitudDetalleEstudiante } from "components/solicitudes/SolicitudDetalleEstudiante";
import { Asignaciones } from "views/estudiantes/Asignaciones";
import { Dashboard } from "views/estudiantes/Dashboard";
import { Datos } from "views/estudiantes/Datos";
import { Inscripciones } from "views/estudiantes/Inscripciones";
import { Solicitudes } from "views/estudiantes/Solicitudes";
//Ruta a la página para ver la información de los Cursos
import VerCursosNotasLogic from 'nuevas_funciones/estudiantes/VerCursosNotasLogic';

var routes = [
  {
    path: "/dashboard",
    name: "Inicio",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/student",
  },
  {
    path: "/asignaciones",
    name: "Asignaciones",
    icon: "nc-icon nc-paper",
    component: Asignaciones,
    layout: "/student",
  },
  {
    path: "/inscripciones",
    name: "Inscripciones",
    icon: "nc-icon nc-refresh-69",
    component: Inscripciones,
    layout: "/student",
  },
  {
    path: "/solicitudes",
    name: "Solicitudes",
    icon: "nc-icon nc-tap-01",
    component: Solicitudes,
    layout: "/student",
  },
  {
    path: "/datos",
    name: "Datos Personales" ,
    icon: "nc-icon nc-badge",
    component: Datos,
    layout: "/student",
  },
  //Rutas que no se ven en el sidebar
  {
    path: "/solicitudes/detalle/:id",
    name: "Solicitud",
    icon: "",
    component: SolicitudDetalleEstudiante,
    layout: "/student",
    hide: true
  },
  //Ruta para ver la información de los Cursos
  {
    path: "/ver-notas",
    name: "Ver Notas",
    icon: "nc-icon nc-single-copy-04",
    component: VerCursosNotasLogic,
    layout: "/student",
  },
];
export default routes;
