import { TooltipItem } from 'components/utils/TooltipItem';
import React, { useEffect, useState } from 'react'
import { Alert, Button, Row, Table } from 'reactstrap';
import { changePreinscripcionInProgramas } from 'redux/actions/preinscripcion.actions';
import { getProgramas } from 'redux/actions/programa.actions';

export const CarrerasSeleccionables = () => {
  const styleSelected = { backgroundColor: '#e2f0fe'}
  const [ carreras, setCarreras ] = useState([]);
  const [ response, setResponse ] = useState({});

  useEffect( ()=>{
      getProgramas()
      .then(res => {
        setCarreras(res.data.programas);
      })
      .catch( err => console.log(err))
  }, [] );

  const handleGuardar = ()=>{
    setResponse({});
    const programasActivos = carreras.filter( c => (c.activePreinscripcion === true ));
    const programasInactivos = carreras.filter( c => (c.activePreinscripcion === false) );
    changePreinscripcionInProgramas( programasActivos.map( c => c.id ), programasInactivos.map( c => c.id ))
    .then( res => {
        setCarreras(res.data.programas);
        setResponse({ type: "success", message:"Carreras seleccionables actualizadas correctamente."})
    })
    .catch( err =>setResponse({ type: "danger", message: err.response.data.message}))
  }

  const clickAddOrDelete = ( id, activePreinscripcion )=>{
    setCarreras( prev => prev.map( c => {
        if(c.id === id){
            return {...c, activePreinscripcion };
        }else{ return c; }
    }))
  }
    
  return (
    <>
      { response.message && <Alert color={response.type}><strong>{ response.message }</strong></Alert>}
      <Table>
        <thead>
          <tr> 
            <th>#</th>
            <th>Codigo</th>
            <th>Carrera</th>
            <th>Agregar</th>
          </tr>
        </thead>
        <tbody>
            { carreras.map( (c, i) => 
              <tr key={c.id} style={ c.activePreinscripcion ? styleSelected : {}}>
                <td>{i+1}</td>
                <td>{c.codigo}</td>
                <td>{c.nombre}</td>
                <td>{
                    c.activePreinscripcion ? 
                    <>
                      <Button id={`add-${c.id}`} color="danger" size="sm" onClick={ ()=>clickAddOrDelete(c.id, false)}>
                        <div className="stats" >
                          <i className="far fa-window-close"></i>                                        
                        </div>
                      </Button>
                      <TooltipItem target={`add-${c.id}`} title={"Quitar"}/>
                    </>
                    : 
                    <>
                      <Button id={`add-${c.id}`} color="primary" size="sm" onClick={ ()=>clickAddOrDelete(c.id, true)}>
                        <div className="stats" >
                          <i className="fas fa-plus-circle"></i>                                           
                        </div>
                      </Button>
                      <TooltipItem target={`add-${c.id}`} title={"Agregar"}/>
                    </>
                }</td>    
              </tr>)}
        </tbody>
      </Table>
      <hr className="mt-2"/>
      <Row className="mx-1" style={{justifyContent: 'flex-end'}}>
          <Button color="primary" style={{ backgroundColor:"#31708f"}} size="sm"
            onClick={()=>handleGuardar()}>Guardar</Button>
      </Row>
    </>
  )
}
