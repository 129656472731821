import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Modal, Row, Spinner } from 'reactstrap'
import { updateDPIStudent } from 'redux/actions/student.actions';
import { isObjEmpty } from 'utils/helpers';
import validator from 'validator';

export const EditStudentModal = ({ showModal, setShowModal , idStudent, dpi, carnet}) => {

  const [response, setResponse ] = useState({});
  const [student, setStudent ] = useState({ carnet, dpi });
  const [errors, setErrors ] = useState({});
  
  const onChange = ( e) =>{
    const { name, value } = e.target;
    setStudent( prev => ({ ...prev, [name]: value }))
  }   

  useEffect( ()=>{
    setStudent({ carnet, dpi });
    }, [carnet, dpi]);


  const onSubmit = ( e ) =>{
    e.preventDefault();
    setErrors({});
    setResponse({ loading : true});
    const errors = checkErrors( );
    if( !isObjEmpty(errors) ){
      setErrors( errors );
      setResponse({});
      return;
    }

    updateDPIStudent({...student, id: idStudent})
    .then( res => setResponse({type: 'success', message: 'Cambios realizados correctamente. Recargue la página y notifique al estudiante del cambio de su usuario y contraseña.'}))
    .catch( err => setResponse({ type: 'danger', message: err.response.data.message }));
  }

  const checkErrors = () =>{
    const errores = {};
    if(student.dpi === dpi && student.carnet === carnet){
        errores.dpi = "El DPI y carnet son identicos a los valores actuales, no hay necesidad de cambio."
        errores.carnet = "El DPI y carnet son identicos a los valores actuales, no hay necesidad de cambio."
    }
    
    if(!validator.isLength( student.dpi, { min: 5, max:15}) ){
      errores.dpi = "El número de identificación debe tener de 5 a 15 caracteres.";
    }else if(!validator.matches(student.dpi, /^[0-9]+$/)){
      errores.dpi = "El DPI o Pasaporte debe contener números enteros";
    }

    if(!validator.isLength( student.carnet, {max:9}) || !validator.matches(student.carnet, /^[0-9]+$/)){
      errores.carnet = "El carnet debe tener 9 digitos.";
    }

    return errores;
  }
  return (
    <Modal className="modal-dialog-centered "
        size="lg"
        isOpen={showModal}
        toggle={() => setShowModal(false)}>

        <Card className="card-user">
            <CardHeader>
                <CardTitle  tag="h6">Editar Estudiante</CardTitle>
            </CardHeader>
            <CardBody>
                <Row className="mx-3">
                    
                    <Col  md="12">
                        
                        { response.message && 
                            <Alert color={response.type}><strong>{response.message}</strong></Alert> }
                      <p>Solo puedes editar el DPI y carnet del estudiante.
                         El estudiante puede editar la demás información desde su cuenta personal.</p>
                      <p><span className="text-danger">IMPORTANTE:</span> Al editar el DPI del estudiante, su username y contraseña también cambiarán al mismo valor del nuevo DPI.</p>
                      <Form onSubmit={onSubmit}>
                        <Row>
                            <Col  md="6">
                                <FormGroup>
                                    <label>DPI/PASAPORTE<label className="text-warning m-0"><strong>*</strong></label></label>                        
                                    <Input
                                    className="text-dark"
                                    placeholder="Identificador personal."
                                    type="text"
                                    name="dpi"
                                    value={student.dpi}
                                    onChange={ onChange }
                                    invalid={ !! errors.dpi }
                                    required/>
                                        <FormFeedback>
                                                    { errors.dpi }
                                        </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col  md="6">
                                <FormGroup>
                                    <label>CARNET</label>                        
                                    <Input
                                    className="text-dark"
                                    placeholder="Carnet Universitario"
                                    type="text"
                                    name="carnet"
                                    value={student.carnet}
                                    onChange={ onChange }
                                    invalid={ !! errors.carnet }/>
                                    <FormFeedback>
                                        { errors.carnet }
                                    </FormFeedback>
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row style={{ justifyContent: 'flex-end'}}>
                          { response.loading ? <Spinner className="mr-3"/> : null }
                          <Button color="danger" type="submit" >
                            Guardar
                          </Button>
                        </Row>
                        

                    </Form>
                </Col>
              </Row>
            </CardBody>
        </Card>
    </Modal>
  )
}
