import React, { useEffect, useState } from 'react'
import { Button, Table } from 'reactstrap'
import moment from 'moment';
import { TooltipItem } from 'components/utils/TooltipItem';
import { Link } from 'react-router-dom';

export const CarreraListAsignacion = ({ programasConAsignaciones }) => {

  const [ programas, setProgramas ] = useState([]);

  useEffect( ()=>{
    setProgramas( programasConAsignaciones );
  }, [programasConAsignaciones]);

  return (
    <>
      <Table className="m-3" striped>
        <thead className="text-secundary">
            <tr>
            <th>Código</th>
            <th>Carrera</th>
            <th>Procesos activos desde</th>
            <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
          {
            programas.map( programa => (
              <tr key={programa.id}> 
                <td>{programa.codigo}</td>
                <td>{programa.nombre}</td>
                <td>{ moment( programa.fechaAsignacion ).format( "DD/MM/YYYY") }</td>
                <td >
                  <Link to={`asignaciones/programa/${programa.id}`}>
                    <Button id={`edit-${programa.id}`} color="success" size="sm">
                      <div className="stats" >
                        <i className="far fa-eye"></i>                                            
                      </div>
                        </Button>
                        <TooltipItem target={`edit-${programa.id}`} title={"Ver"}/>
                    </Link>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </>
  )
}
