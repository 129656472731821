import { BuscarCurso } from 'components/cursos/BuscarCurso'
import { NuevoCurso } from 'components/cursos/NuevoCurso'
import React from 'react'
import { Col, Row } from 'reactstrap'

export const Cursos = () => {
  return (
    <div className="content">
      <Row>
        <Col md="12">
          <NuevoCurso/>
        </Col>
      </Row>
      <BuscarCurso/>

    </div>
  )
}
