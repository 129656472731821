import React, { useState } from 'react';
import { Alert, Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap';
import { createPrograma } from 'redux/actions/programa.actions';
import { isObjEmpty } from 'utils/helpers';
import validator from 'validator';

export const NuevoPrograma = () => {

  const [ programa, setProgama ] = useState({});
  const [ programaCreado, setProgamaCreado ] = useState({});
  const [ errors, setErrors ] = useState({});
  const [ response, setResponse ] = useState({});

  const onChange = ( e ) => {
    const { value, name } = e.target;
    setProgama( prev => ({
      ...prev,
      [ name ] : value
    })) 
  } 

  const submitHandle = ( e ) => {
    e.preventDefault();
    setResponse({ loading: true })
    setProgamaCreado({});
    setErrors({});

    const errores = checkErrors();
    
    if( !isObjEmpty( errores ) ){
      setErrors(errores);
      setResponse({ loading: false })
      return;
    }

    createPrograma({ ...programa, nombre: programa.nombre.toUpperCase(), prefijo: programa.prefijo.toUpperCase() })
    .then( res => {
      setResponse({ type:"success", message: res.data.message});
      if( res.data.programa ){
        setProgama({});
        setProgamaCreado( res.data.programa );
      }

    })
    .catch(err => setResponse({ type:"danger", message: err.response.data.message }))
  } 

  const checkErrors = ()=>{
    const errores = {};

    if( !validator.isLength( programa.nombre, { min: 5 }) ){
      errores.nombre = "El nombre es obligatorio. Y debe tener al menos 5 caracteres.";
    }else if(!validator.matches(programa.nombre, /^[a-zA-Z0-9\s]*$/) ){
      errores.nombre = "El nombre de la carrera solo puede contener letras mayusculas, minusculas, numeros y espacios";
    }

    if( validator.isEmpty( programa.codigo ) ){
      errores.codigo = "El código de la carrera es obligatorio."
    }else if(!validator.matches(programa.codigo, /^[0-9-]+$/)){
      errores.codigo = "El código de la carrera solo puede contener números y guiones."
    }

    
    if(!validator.isLength( programa.prefijo, { min:3, max: 6 } ) ){
      errores.prefijo = "Es necesario un prefijo de entre 3 y 6 caracteres para los cursos a crear.";
    }else if(!validator.isAlphanumeric(programa.prefijo)){
      errores.prefijo = "El prefijo de la carrera solo puede contener letras mayusculas, minusculas y numeros";
    }    

    if( validator.isEmpty( programa.ciclos ) || !validator.isInt( programa.ciclos ) ){
      errores.ciclos = "La cantidad de años de la carrera deben ser números enteros."
    }
    
    if( validator.isEmpty( programa.unidad ) ){
      errores.unidad = "El codigo de la unidad es obligatorio."
    }else if(!validator.matches(programa.unidad, /^[0-9]+$/)){
      errores.unidad = "El codigo de la unidad solo puede contener números."
    }
    
    if( validator.isEmpty( programa.extension ) ){
      errores.extension = "El codigo de extensión de la carrera es obligatorio."
    }else if(!validator.matches(programa.extension, /^[0-9]+$/)){
      errores.extension = "El codigo de la extension solo puede contener números."
    }

    

    if( !validator.isLength( programa.descripcion, { max: 150 } ) ){
      errores.descripcion = "La descripción es opcional pero no debe ser mayor a 150 caracteres."
    }else if(!validator.matches(programa.descripcion, /^[a-zA-Z0-9\s]*$/) ){
      errores.descripcion = "La descripcion de la carrera solo puede contener letras mayusculas, minusculas, numeros y espacios";
    }


    return errores;
  }

  return (
    <div className="content">
      <Row>
        <Col md="12">
          { programaCreado.id && <Card className="" >
            <CardHeader style={{
              backgroundColor: '#efefef',
              borderColor: '#f7f7f7'}}>
                <CardTitle className="p-0" tag="h6">
                  { response.message &&  <Alert color={response.type}><strong>{response.message}</strong></Alert> }
                </CardTitle>
            </CardHeader>
            <CardBody>
                <label> 
                    <h6>Código: {programaCreado.codigo}</h6>
                    <h6>Nombre: {programaCreado.nombre}</h6>
                    <h6>Prefijo: {programaCreado.prefijo}</h6>
                    <h6>Descripción: {programaCreado.descripcion}</h6>
                    <h6>Años (Ciclos Académicos): {programaCreado.ciclos}</h6>
                    <h6>Unidad: {programaCreado.unidad}</h6>
                    <h6>Extensión: {programaCreado.extension}</h6>
                </label>
              </CardBody>
          </Card>
          }
          <Card className="card-user">
            <CardHeader>
              <CardTitle tag="h5">Registrar nuevo programa académico</CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={submitHandle}>
              { (response.type ==="danger" && response.message ) && <Alert color={response.type}><strong>{response.message}</strong></Alert> }

                  <Row>
                    <Col  md="12">
                      <FormGroup>
                        <label>NOMBRE DE CARRERA lol<label className="text-warning m-0"><strong>*</strong></label></label>                        
                        <Input
                          className="text-dark"
                          placeholder="Programa de maestria"
                          type="text"
                          name="nombre"
                          value={programa.nombre || ''}
                          onChange={ onChange }
                          invalid={ !! errors.nombre }
                          required/>
                            <FormFeedback>
                                        { errors.nombre }
                            </FormFeedback>
                      </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col  md="4">
                      <FormGroup>
                        <label>CÓDIGO (UNICO)<label className="text-warning m-0"><strong>*</strong></label></label>                        
                        <Input
                          className="text-dark"
                          placeholder="Código"
                          type="text"
                          name="codigo"
                          value={programa.codigo || ''}
                          onChange={ onChange }
                          invalid={ !! errors.codigo }
                          required/>
                            <FormFeedback>
                                        { errors.codigo }
                            </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col  md="4">
                      <FormGroup>
                        <label>PREFIJO (UNICO)<label className="text-warning m-0"><strong>*</strong></label></label>                        
                        <Input
                          className="text-dark"
                          placeholder="Prefijo para cursos."
                          type="text"
                          name="prefijo"
                          value={programa.prefijo || ''}
                          onChange={ onChange }
                          invalid={ !! errors.prefijo }
                          required/>
                            <FormFeedback>
                                        { errors.prefijo }
                            </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col  md="4">
                      <FormGroup>
                        <label>AÑOS (CICLOS académicos)<label className="text-warning m-0"><strong>*</strong></label></label>                        
                        <Input
                          className="text-dark"
                          placeholder="Cantidad de años de la carrera."
                          type="number"
                          name="ciclos"
                          value={programa.ciclos || ''}
                          onChange={ onChange }
                          invalid={ !! errors.ciclos }
                          required/>
                            <FormFeedback>
                                        { errors.ciclos }
                            </FormFeedback>
                      </FormGroup>
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col  md="6">
                      <FormGroup>
                        <label>UNIDAD<label className="text-warning m-0"><strong>*</strong></label></label>                        
                        <Input
                          className="text-dark"
                          placeholder="Unidad"
                          type="text"
                          name="unidad"
                          value={programa.unidad || ''}
                          onChange={ onChange }
                          invalid={ !! errors.unidad }
                          required/>
                            <FormFeedback>
                                        { errors.unidad }
                            </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col  md="6">
                      <FormGroup>
                        <label>EXTENSIÓN<label className="text-warning m-0"><strong>*</strong></label></label>                        
                        <Input
                          className="text-dark"
                          placeholder="Extensión"
                          type="text"
                          name="extension"
                          value={programa.extension || ''}
                          onChange={ onChange }
                          invalid={ !! errors.extension }
                          required/>
                            <FormFeedback>
                                        { errors.extension }
                            </FormFeedback>
                      </FormGroup>
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col  md="12">
                      <FormGroup>
                        <label>DESCRIPCIPÓN</label>
                        <Input
                          className="text-dark"
                          placeholder="Descripcion del programa académico..."
                          type="text"
                          name="descripcion"
                          value={programa.descripcion || ''}
                          onChange={ onChange }
                          invalid={ !! errors.descripcion }
                          
                        />
                        <FormFeedback >
                          { errors.descripcion }
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className=" text-right ml-3 mt-3">
                      <Button
                        size="md"
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        Crear Programa
                      </Button>
                    </Col>
                  </Row>
                </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
