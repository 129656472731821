import { TooltipItem } from 'components/utils/TooltipItem';
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap'
import { getCursosInPrograma } from 'redux/actions/curso.actions'

export const SelectCursosAsignables = ({ programaId, setCursosSeleccionados, error = "" }) => {

  const [ cursos, setCursos ] = useState([]);
  const [ cantidadSeleccionados, setCantidadSeleccioandos ] = useState(0);

  useEffect(()=>{
    setCantidadSeleccioandos(0);
    getCursosInPrograma( programaId )
    .then( res => setCursos( res.data.cursos ))
    .catch( err => console.log(err))
  }, [programaId])

  const handleAddOrDelete = ( cursoId, action )=>{
    let array = cursos; 
    if( action === "add" ){
        array = cursos.map( c => {
            if( c.id === cursoId ){
               setCantidadSeleccioandos( prev => prev + 1);
                return ({ ...c, selected: true });
            }else{ return c; }
        })
    }else if(action === "delete"){
        array = cursos.map( c => {
            if( c.id === cursoId ){
                setCantidadSeleccioandos( prev => prev - 1);
                return ({ ...c, selected: false });
            }else{ return c; }
        })
    }
    setCursos( array );
    setCursosSeleccionados( array );
  }

  useEffect( ()=>{
      const array = cursos.filter( c => (c.selected === true ));
      setCursosSeleccionados(array);
  }, [cursos])

  return (
    <Card>
      <CardHeader
        tag="strong" 
        className="pb-2" style={{ backgroundColor: '#efefef',borderColor: '#f7f7f7'}}>
            SELECCIONA LOS CURSOS DISPONIBLES A ASIGNACION.
      </CardHeader>
       <CardBody>
        <Row style={{justifyContent:'center'}}>
          <Col className="mb-3" md="8">
            <Table bordered hover>
              <thead>
                <tr className="text-center">
                  <th>#</th>
                  <th>Código</th>
                  <th>Nombre</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {
                  cursos.map( (c,i) => 
                    <tr key={c.id} style={ c.selected ? { backgroundColor: "#e2f0fe"} : {backgroundColor: "#ffffff"}}>
                      <th scope="row">{i+1}</th>
                      <td>{ c.codigo }</td>
                      <td>{c.nombre}</td>
                      <td>
                        { c.selected ? 
                        <>
                          <Button id={`add-${c.id}`} color="danger" size="sm" onClick={ ()=>handleAddOrDelete(c.id, "delete")}>
                            <div className="stats" >
                              <i className="far fa-window-close"></i>                                        
                            </div>
                          </Button>
                          <TooltipItem target={`add-${c.id}`} title={"Quitar"}/>
                        </>
                        :
                        <>
                          <Button id={`add-${c.id}`} color="primary" size="sm" onClick={ ()=>handleAddOrDelete(c.id, "add")}>
                            <div className="stats" >
                              <i className="fas fa-plus-circle"></i>                                           
                            </div>
                          </Button>
                          <TooltipItem target={`add-${c.id}`} title={"Agregar"}/>
                        </>
                        }
                      </td>
                    </tr>
                )}
              </tbody>
            </Table>
            <hr/>
            <p>Cursos Seleccionados: { cantidadSeleccionados } </p>
            <p className="text-danger">{error}</p>
            

         </Col>
       </Row>
    </CardBody>
     </Card>
  )
}
