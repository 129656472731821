import jwtDecode from 'jwt-decode';
import { setTokenRequest } from './setTokenRequest'
import { setCurrentUser } from 'redux/actions/auth';
import store from 'store';
import { logoutUser } from 'redux/actions/auth';

export const checkForToken = () => {

    if( localStorage.getItem('token')){
        
        const token = localStorage.getItem('token');
        setTokenRequest( token );
        const token_decode =  jwtDecode(token);
        const { username, role } = token_decode;
        store.dispatch( setCurrentUser({ username, role, loggedIn: true }));  
        
        const currenTime = Math.floor(Date.now() / 1000);
        if(token_decode.exp < currenTime){
            store.dispatch( logoutUser());
            window.location.href = "/login"
        }    
    }
}
