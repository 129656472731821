import React, { useState } from 'react';
import { Alert, Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap'
import { SelectCursosAsignables } from './SelectCursosAsignables';
import validator from 'validator';
import { isObjEmpty } from 'utils/helpers';
import { ConfirmarProcesoModal } from './ConfirmarProcesoModal';
import { createProcesoAsignacion } from 'redux/actions/asignaciones.actions';
import { searchProcesoAsignacion } from 'redux/actions/asignaciones.actions';
import { periodos } from 'utils/periodos';
import moment from 'moment-timezone';

export const NuevoProcesoForm = ({programaNombre, programaId, mostrarResultado, setFallo}) => {

  const procesoInitial = { semestre: "", anio: new Date().getFullYear(), periodo: "default", programaId, endAt: '' };
  const [ proceso, setProceso ] = useState(procesoInitial);
  const [ errors, setErrors ] = useState({});
  const [ errorCursos, setErrorCursos ] = useState("");
  const [ cursosSeleccionados, setCursosSeleccionados ] = useState([]);
  const [ showCursos, setShowCursos ] = useState(false);
  const [ showModal, setShowModal ] = useState( false );
  const [ response, setResponse ] = useState({});

  const onChange = ( e ) => {
      const { name, value } = e.target;
      setProceso( prev => ({...prev, [name]: value}))
  }


  const checkProcesoParametros = ()=>{
      setResponse({});
      setErrors({})
      const errores = checkErrors();
      if( !isObjEmpty(errores) ){
          setErrors(errores);
          return;
      }
      
      searchProcesoAsignacion( { ...proceso, programaId } )
      .then( res => {
        const exist = res.data.exist;
        if( exist === true ){
          setResponse({ type: "warning", 
          message: "Ya existe un proceso en esta carrera con el mismo año, semestre y periodo."});
        }else{
          setShowCursos(true);
        }
      }).catch( err => {
        console.log(err);
        setResponse({ type: "danger", message: err.response.data.message });
      })
      
  }

  const checkErrors = ()=>{
    const errores = {};

    if( validator.isEmpty( proceso.semestre ) || !validator.isInt(proceso.semestre, {min: 1, max: 4}) ){
        errores.semestre = "Semestre o trimestre debe ser un entero 1-4."
    }
    if( validator.isEmpty( proceso.anio.toString() ) || !validator.isInt(proceso.anio.toString(), { min: 1950, max: 2100 })){
        errores.anio = "El año debe ser entre 1950 - 2100."
    }
    if( validator.isEmpty(proceso.periodo) || proceso.periodo === "default"){
        errores.periodo = "Selecciona un periodo.";
    }
    if( !validator.isDate(proceso.endAt)) {
      errores.endAt = "Selecciona una fecha de finalización.";
    }else{
      const dateIngresada = moment(proceso.endAt);
      const now = moment();
      if( dateIngresada < now ){

      errores.endAt = `Hoy es: ${now.format('DD/MM/YYYY')} y usted esta ingresando: ${dateIngresada.format('DD/MM/YYYY')}. No puedes seleccionar una fecha anterior o igual a la actual.`;
      }
    }

    return errores;
  }

  const crearProceso = ()=>{
    setFallo({});
    createProcesoAsignacion({ 
      procesoAsignacion: {...proceso, anio: proceso.anio.toString(), endAt: moment.tz(proceso.endAt, "America/Guatemala")},
      cursosAsignables: cursosSeleccionados.map( c => c.id) 
    }).then( res => {
      mostrarResultado( {...res.data.response, programaNombre} );
    }).catch( err => {
      setFallo({type: "danger", message: err.response.data.message,  fail:true });
    });

    setShowModal(false);
    setShowCursos(false);
  }

  const showModalConfirmar = ()=>{
    setErrorCursos("");
    if( cursosSeleccionados.length < 1 ){
        setErrorCursos("Debe seleccionar al menos un curso disponible para asignación para crear este nuevo proceso de asignaciones.")
    }else{
        setShowModal(true);
    }
  }

  return (
    <>
    <Card>
      <CardHeader
        tag="h6" 
        className="py-4 mb-3" style={{ backgroundColor: '#efefef',borderColor: '#f7f7f7'}}>
            Nuevo Proceso de Asignación - {programaNombre}
      </CardHeader>
      <CardBody>
        <Form>
          <Row style={{justifyContent:'center'}}>            
            <Col md="8">

              { response.message && 
                <strong>
                  <Alert color={response.type}>{response.message}</Alert>
                </strong>
              }
              <Row className="mb-1">
                <Col md="6">      
                  <FormGroup>
                    <label><strong>AÑO</strong></label>
                    <Input
                      disabled={showCursos}
                      className="text-dark"
                      name="anio"
                      value={proceso.anio}
                      onChange={onChange}
                      type="text"
                      placeholder="Año"
                      invalid={!!errors.anio}
                      required/>
                    <FormFeedback>{errors.anio}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label><strong>SEMESTRE O TRIMESTRE</strong></label>
                    <Input      
                      disabled={showCursos}    
                      className="text-dark"
                      name="semestre"
                      value={proceso.semestre}
                      onChange={onChange}
                      type="text"
                      placeholder="1 | 2 | 3"
                      invalid={!!errors.semestre}
                      required/>
                    <FormFeedback>{errors.semestre}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col md="6">
                  <FormGroup>
                    <label><strong>PERIODO</strong></label>
                    <Input
                     disabled={showCursos}
                     className="text-dark"
                      name="periodo"
                      value={proceso.periodo}
                      onChange={onChange}
                      type="select"
                      placeholder="Periodo" 
                      invalid={!!errors.periodo}
                      required>
                        <option value={"default"} hidden disabled>Selecciona el periodo.</option>
                        { periodos.map( p => 
                          <option key={p.key} value={p.value}> {p.value} </option>)}
                      </Input>
                    <FormFeedback>{errors.periodo}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label><strong>FECHA FINALIZACIÓN (inclusiva)</strong></label>
                    <Input
                      disabled={showCursos}    
                      className="text-dark"
                      name="endAt"
                      value={proceso.endAt}
                      onChange={onChange}
                      type="date"
                      invalid={!!errors.endAt}
                      required/>
                    <FormFeedback>{errors.endAt}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row >
                { showCursos ===false  &&
                <Col className="text-right" md="12" >
                  <Button 
                    color="primary"
                    onClick={()=>checkProcesoParametros()}
                    style={{ backgroundColor:"#31708f"}} 
                    size="sm">
                    Continuar
                  </Button>
                </Col>}
              </Row>
            </Col>
          </Row>
        </Form>

      </CardBody>
    </Card>
    { showCursos && 
      <>
        <SelectCursosAsignables 
            error={errorCursos}
            programaId={programaId} 
            setCursosSeleccionados={setCursosSeleccionados}/>
    
        <Row className="m-1" style={{justifyContent: "flex-end"}}>
            <Button 
              className="mr-1"
              color="danger"
              onClick={()=>setShowCursos(false  )}>
                REGRESAR
            </Button>
            <Button 
              color="primary"
              onClick={()=>showModalConfirmar()}
              style={{ backgroundColor:"#31708f"}}>
                CONTINUAR
            </Button>
            
        </Row>
      </>
    }
    { showModal && <ConfirmarProcesoModal 
       proceso={ {...proceso, programaNombre, cursosSeleccionados }}
       showModal={showModal} 
       setShowModal={setShowModal}
       crearProceso={crearProceso}/>
    }
    </>
  )
}
