import React from 'react'
import { Button, Card, CardBody, CardHeader, Col, Modal, Row } from 'reactstrap'

export const ModalConfirmarAsignacion = ({ showModal, setShowModal, asignarCallback }) => {

  const handleClick = ()=>{
      asignarCallback();
  }
  
  return (
    <Modal className="modal-dialog-centered "
      size="sm"
      isOpen={showModal}
      toggle={() => setShowModal(false)}>
      <Card>
        <CardHeader>
            <h6>Confirma la asignación</h6>
        </CardHeader>
        <CardBody>
            <p></p>
            <Row>
              <Col className="text-right">
                <Button color="primary" size="sm" 
                    onClick={()=>handleClick()} style={{ backgroundColor:"#31708f"}}>Confirmar</Button>
                <Button color="warning" size="sm" onClick={()=>setShowModal(false)}> Cancelar  </Button>
              </Col>
            </Row>
        </CardBody>
      </Card>

    </Modal>
  )
}
