import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Input, Row, } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/css/Actas.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { es } from 'date-fns/locale';
import moment from 'moment-timezone';

function GenerarActasView({
  carreras,
  cursos,
  ciclos,
  secciones,
  selectedCarrera,
  selectedCurso,
  selectedCiclo,
  selectedSeccion,
  estudiantes,
  docente,
  fechaText,
  setFechaText,
  selectedCarreraText,
  selectedCursoText,
  selectedCicloText,
  selectedSeccionText,
  docenteText,
  cargaHabilitada,
  limpiarHabilitado,
  generarExcelHabilitado,
  cargarCarreras,
  cargarCursos,
  cargarCiclos,
  cargarSecciones,
  handleCarreraChange,
  handleCursoChange,
  handleCicloChange,
  handleSeccionChange,
  handleCargarEstudiantesNotas,
  limpiarCampos,
  handleGenerarArchivoExcel,
}) {

  return (
    <div className="content">
      <Card>
        <CardHeader>
          <CardTitle tag="h5">Obtener información del curso</CardTitle>
        </CardHeader>
        <CardBody className="pb-1">
          <Form>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>{'CARRERA  '}<label className="text-warning m-0"><strong>*</strong></label></label>
                  <br></br>
                  <Input
                    className="text-dark w-100"
                    placeholder="Nombre de la carrera"
                    type="select"
                    name="carrera"
                    value={selectedCarrera}
                    onChange={handleCarreraChange}
                    required>
                    <option value="">Seleccione una carrera</option>
                    {carreras.map((carrera) => (
                      <option key={carrera.id} value={carrera.id}>
                        {carrera.nombre}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label>{'CURSO  '}<label className="text-warning m-0"><strong>*</strong></label></label>
                  <br></br>
                  <Input
                    className="text-dark w-100"
                    placeholder="Nombre deL curso"
                    type="select"
                    name="curso"
                    value={selectedCurso}
                    onChange={handleCursoChange}
                    required>
                    <option value="">Seleccione un curso</option>
                    {cursos.map((curso) => (
                      <option key={curso.id} value={curso.id}>
                        {curso.nombre}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>{'CICLO  '}<label className="text-warning m-0"><strong>*</strong></label></label>
                  <br></br>
                  <Input
                    className="text-dark w-100"
                    placeholder="Seleccionar un ciclo"
                    type="select"
                    name="ciclo"
                    value={selectedCiclo}
                    onChange={handleCicloChange}
                    required>
                    <option value="">Seleccione un ciclo</option>
                    {ciclos.map((ciclo) => (
                      <option key={ciclo} value={ciclo}>
                        {ciclo}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label>{'SECCIÓN  '}<label className="text-warning m-0"><strong>*</strong></label></label>
                  <br></br>
                  <Input
                    className="text-dark w-100"
                    placeholder="Seleccionar una seccion"
                    type="select"
                    name="seccion"
                    value={selectedSeccion}
                    onChange={handleSeccionChange}
                    required>
                    <option value="">Seleccione una sección</option>
                    {secciones.map((seccion) => (
                      <option key={seccion} value={seccion}>
                        {seccion}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>DOCENTE</label>
                  <br></br>
                  <Input
                    className="text-dark w-100"
                    placeholder="Docente"
                    type="text"
                    name="docente"
                    value={docente}
                    //onChange={(event) => setDocente(event.target.value)} //Permitir edición del nombre
                    readOnly // Para evitar edición manual del nombre
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label>FECHA</label>
                  <br></br>
                  <DatePicker
                    className="form-control"
                    placeholderText="(dd/mm/yyyy)"
                    title="El formato de la fecha debe ser el dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy" // Formato de fecha personalizado
                    selected={fechaText ? moment.tz(fechaText.split('/').reverse().join('-'), 'America/Guatemala').toDate() : null}
                    onChange={(date) => {
                      const formattedDate = date ? moment(date).tz('America/Guatemala').format('DD/MM/YYYY') : '';
                      setFechaText(formattedDate);
                    }}
                    locale={es}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className="d-flex justify-content-center align-items-center">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleCargarEstudiantesNotas}
                    disabled={!selectedCarrera || !selectedCurso || !selectedCiclo || !selectedSeccion}>
                    Cargar datos
                  </button>
                  <button
                    className="btn btn-primary ml-5"
                    type="button"
                    onClick={limpiarCampos}
                    disabled={!limpiarHabilitado}>
                    Limpiar
                  </button>
                  <button
                    className="btn btn-primary ml-5"
                    type="button"
                    onClick={handleGenerarArchivoExcel}
                    disabled={!generarExcelHabilitado}>
                    Generar Excel
                  </button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>

      <Card>
        <CardBody className="pb-1">
          <div>
            {/* Aquí puedes mostrar la tabla con la información de estudiantes y notas */}
            {estudiantes.length > 0 && (
              <div className="estudiantes-notas text-center">
                <h2 >Estudiantes y Notas</h2>
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>DPI</th>
                      <th>CARNÉ</th>
                      <th>APELLIDOS Y NOMBRES</th>
                      <th>NOTA</th>
                    </tr>
                  </thead>
                  <tbody>
                    {estudiantes.map((estudiante) => (
                      <tr key={estudiante.id}>
                        <td>{estudiante.dpi}</td>
                        <td>{estudiante.carne}</td>
                        <td>{estudiante.nombre}</td>
                        <td>{estudiante.nota}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default GenerarActasView;
