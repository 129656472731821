import React, { useState } from 'react'
import { Alert, Button, Card, CardBody, CardHeader, Row, Spinner } from 'reactstrap'
import { deleteSolicitudAtendidasByStudent } from 'redux/actions/solicitud.actions';
import { ModalConfirmDelete } from './ModalConfirmDelete';

export const DeleteSolicitudesAtendidas = ({studentId}) => {

    const [showModal, setShowModal ] = useState(false);
    const [response, setResponse ] = useState({});

    const handleDelete = ()=>{
      setResponse({ loading: true })
      deleteSolicitudAtendidasByStudent(studentId)
      .then( res => setResponse( {color: "success", message: res.data.message } ))
      .catch( err => setResponse({ color: "warning", message: err.response.data.message }))
    }

    const styleButton = {
        borderRadius: 0,
        backgroundColor: "#f8f9fa",
        color: "#344767",
        "&:hover": {
            background: "#efefef"
        }
    };

  return (
    <>
    <Card>
      <CardHeader>
        <h5 className="mb-0" style={{color:"#394c6b"}}>Vaciar Solicitudes Atendidas</h5>
        <hr className="mb-0 mt-1"/>
      </CardHeader>
      <CardBody className="pt-2">
        <Row className="mx-3" style={{ alignItems: 'center' }}>
          <p className="mb-0 mr-1">Elimina todas tus solicitudes que ya fueron atendidas. </p>
          <Button color="danger" style={styleButton} size="sm" onClick={()=>setShowModal(true)}>Eliminar</Button>
        </Row>
      </CardBody>
    </Card>
    <ModalConfirmDelete 
        showModal={showModal}
        setShowModal={setShowModal}>
          <div>
            { response.message && 
              <Alert color={response.color}><strong>{response.message} - Actualiza la página.</strong></Alert>}
            <p className="mb-0">Se eliminaran permanentemente todas las solicitudes atendidas. </p>
            <Button color='danger' size="sm" onClick={()=>handleDelete()}>Eliminar</Button>
            <Button size="sm" onClick={()=>setShowModal(false)}>Cerrar</Button>
            { response.loading && <Spinner className="ml-5"/>}
          </div>
    </ModalConfirmDelete>
    </>
  )
}
