import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { INFO_CURSOS } from 'utils/endpoints';
import VerCursosNotasView from './VerCursosNotasView';
import CustomToast from './../CustomToast';
import { studentDPI } from '../../views/estudiantes/Dashboard';


function IngresarNotasLogic() {
  const [cursos, setCursos] = useState([]);
  
  //console.log('DPI del estudiante: ' + studentDPI);

  useEffect(() => {
    const obtenerCursosNotas = async (dpi) => {
      try {
        const response = await axios.get(`${INFO_CURSOS}/`, {
          params: { dpi },
        });

        const cursosProcesados = response.data.map((curso) => {
          const nombre = curso.nombreCurso;
          const seccion = curso.seccion;
          const docente = curso.docente;
          const nota = curso.notaCurso;

          return {
            nombre,
            seccion,
            docente,
            nota
          };
        });

        setCursos(cursosProcesados);
        //console.log('Datos de cursos obtenidos:', response.data);
      } catch (error) {
        //showErrorNotification('Error al obtener la información de los cursos.');
        console.error('Error al obtener la información de los cursos:', error);
      }
    };

    obtenerCursosNotas(studentDPI); // Llama a la función para obtener la información
    // Puedes ajustar la dependencia según tus necesidades.
    // Si deseas que la consulta se realice solo una vez, déjala vacía.
    // Si necesitas volver a consultar cuando cambia studentDPI, colócalo como dependencia: [studentDPI]
  }, []);


  return (
    <div className="content">
      <VerCursosNotasView
        cursos={cursos}
      />
    </div>
  );

};

export default IngresarNotasLogic;
