import React, { useState } from 'react'
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Modal, Row } from 'reactstrap'
import { habilitarInscripcionEnPrograma } from 'redux/actions/inscripciones.actions'

export const ModalDeshabilitarInscripcion = ({ programaNombre = "", programaId, showModal, setShowModal }) => {
  
  const [ response, setResponse ] = useState({});

  const handle = ()=>{
      habilitarInscripcionEnPrograma( programaId, false )
      .then( res => setResponse({ type: "success", message: "Inscripción deshabilitada. Por favor actualiza la página para ver los cambios." }))
      .catch( err => setResponse({ type: "danger", message: err.response.data.message }))
  }

  return (
    <Modal className="modal-dialog-centered "
      size="md"
      isOpen={showModal}
      toggle={() => setShowModal(false)}>
        <Card>
          <CardHeader>
            <Row className="mx-1 mt-2">
              <Col md="12">
                <CardTitle  tag="h6">Deshabilitar inscripción en carrera <p className="text-danger">{programaNombre}</p> </CardTitle>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="mx-5">
            { response.message && <Alert><strong>{response.message}</strong></Alert> }
            <p>¿Estás seguro de deshabilitar la inscripción en esta carrera?</p>
          </CardBody>
          <CardFooter className="text-right">
              <Button color="danger" onClick={()=>handle()}>CONFIRMAR</Button>
          </CardFooter>
        </Card>
    </Modal>
  )
}
