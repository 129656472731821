import React, { useEffect, useState } from "react";
import { getSolicitudesByStatus } from "redux/actions/solicitud.actions";

function withSolicitudesByStatus(ListComponent){
    return function ListComponentWithSolicitudesPendientes({statusKey, title}){
        const [ solicitudes, setSolicitudes] = useState([]);

        useEffect( ()=>{
            getSolicitudesByStatus(statusKey)
            .then( res => setSolicitudes(res.data))
            .catch( err => console.log( err.response.data.message ))
          }, []);

        return (
            <ListComponent solicitudes={solicitudes} title={title}/>
        );
    }
};

export { 
    withSolicitudesByStatus
};