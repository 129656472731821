import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Alert, Badge, Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'
import { getInscripcionStudentByPrograma } from 'redux/actions/inscripciones.actions';
import moment from 'moment';


export const InscripcionNoActiveCard = ({ programaNombre, programaId }) => {

  const student = useSelector( state => state.estudiante.student )
  const [ lastInscripcion, setLastInscripcion ] = useState({ inscrito: false });


  useEffect( ()=>{
      getInscripcionStudentByPrograma( student.id, programaId )
      .then( res => {
        if( res.data.response !== null ){
          setLastInscripcion( res.data.response );
        }else{
          setLastInscripcion( prev => ({...prev, exist: false }))
        }
      })
      .catch( err => console.log(err))
  }, [])



  return (
    <>
    <Card>
      <CardHeader className="pb-2" style={{
                backgroundColor: '#efefef',
                borderColor: '#f7f7f7'
             }}>
        <Row style={{ alignItems: 'center'}} >
          <Col>
            <CardTitle className="mb-1" tag="h6">{ lastInscripcion.inscrito ? "Inscripción Actual:" : "Última Inscripción:"}</CardTitle>
            { lastInscripcion.ciclo ? 
          <Row className=" mb-1"> 
            <Col>
              <strong>
                <p className="text-muted mb-0">Año de carrera (ciclo académico): { lastInscripcion.ciclo ? lastInscripcion.ciclo : "Sin registro." }  </p>
              </strong>
              <strong>
                <p className="text-muted">Fecha de inscripción: { lastInscripcion.fechaInscripcion ?  moment(lastInscripcion.fechaInscripcion).format("DD/MM/YYYY") : "Sin registro."}  </p>
              </strong>
            </Col>
          </Row>
          : 
          <strong>
                <p className="ml-3 mb-0">No existe una inscripción previa.</p>
          </strong>}
          </Col>
          <Col className="text-right">
            <Badge className="p-2" color={ lastInscripcion.inscrito ? "success" : "danger"}>
              <h6 className="m-0">{ lastInscripcion.inscrito ? "Inscrito" : "No inscrito" }</h6>
            </Badge>
          </Col>
        </Row>
        <Row>
          <Col>
            {lastInscripcion.inscrito ? 
              <strong>
                <p className="ml-3 text-success">Inscrito en el curso actual.</p>
              </strong> 
            : 
              <strong>
                <p className="ml-3 text-danger">No tienes inscripciones vigentes. No estas inscrito en el año actual.</p>
              </strong> 
            }
          </Col>
        </Row>
        

      </CardHeader>
      </Card>
      <Card>
      <CardHeader>
        <CardTitle tag="h6">Inscribirse a {programaNombre} - Año { new Date().getFullYear()}</CardTitle>
      </CardHeader>
      <CardBody>
        <Row className="ml-1">
          <Col md="6" sm="12" >
              <Alert color="primary"><strong>La inscripción está deshabilitada en esta carrera.</strong></Alert>
          </Col>    
        </Row>
      </CardBody>
    </Card>
    </>
  )
}
