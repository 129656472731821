

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const loggedIn = useSelector(state => state.auth.loggedIn);

  // Si no está logueado, redirige al login
  if (!loggedIn) {
    return <Navigate to="/login" />;
  }

 return <Component {...rest} />;
};
