import axios from "axios"
import { GET_ADMINS } from "utils/endpoints"
import { CREATE_ADMIN } from "utils/endpoints"

export const createAdmin = ( data ) => {
    return new Promise( (resolve, reject) => {
        axios.post( CREATE_ADMIN, data )
        .then( res =>resolve( res ))
        .catch( err => reject( err ))
    })
}

export const getAdmins = () => {
    return new Promise( (resolve, reject) => {
        axios.get( GET_ADMINS )
        .then( res =>resolve( res ))
        .catch( err => reject( err ))
    })
}