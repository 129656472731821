import React from 'react'
import { Col, Row, Table } from 'reactstrap'

export const CursoListSearch = ({ coincidencias }) => {
  return (
    <Row>
    <Col md="12">
      <Table className="m-3 " striped responsive dark>
        <thead className="text-secundary">
          <tr>
            <th>No.</th>
            <th>Código Curso</th>
            <th>Curso</th>
            <th>Carrera</th>
            <th>Creditos</th>
          </tr>
        </thead>
        <tbody>
          { coincidencias.map( (c, i) => 
            <tr key={c.id}>
                <td>{i+1}</td>
                <td>{c.codigo}</td>
                <td>{c.nombre}</td>
                <td>{c.programa.nombre}</td>
                <td>{c.creditos}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Col>
  </Row>
  )
}
