import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom';
import { Col, Row, Table } from 'reactstrap'
import { getCursosInPrograma } from 'redux/actions/curso.actions';

export const CursosProgama = () => {
    
  const location = useLocation(); // Para acceder a la ruta completa

  //const { id } = useParams();
  const parts = location.pathname.split('/');
  const id = parts[parts.length - 1];
  const [ cursos, setCursos ] = useState([]);
  const [ programa, setPrograma ] = useState({});

  useEffect(() => {
    getCursosInPrograma( id )
    .then( res => {
        setCursos( res.data.cursos );
        setPrograma( res.data.programa );
    })
    .catch( err => console.log(err))
  }, [])
  

  return (
    <div className="content">
        <Row>
          <Col md="12">
            <Table className="m-3 " striped responsive dark>
              <thead className="text-secundary">
                <tr>
                  <th>No.</th>
                  <th>Código Curso</th>
                  <th>Carrera</th>
                  <th>Curso</th>
                  <th>Créditos</th>
                </tr>
              </thead>
              <tbody>
                { cursos.map( (c, i) => 
                  <tr key={c.id}>
                      <td>{i+1}</td>
                      <td>{c.codigo}</td>
                      <td>{programa.nombre}</td>
                      <td>{c.nombre}</td>
                      <td>{c.creditos}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
    </div>
  )
}
