import React, { useState } from 'react'
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Form, FormGroup, Input, Modal, Row } from 'reactstrap'
import { asociarEstudianteAPrograma } from 'redux/actions/programa.actions';
import { searchStudentByCarnetOrDPI } from 'redux/actions/student.actions';

export const ModalAgregarEstudiante = ({programaId, programaNombre, showModal, setShowModal}) => {

    const [ response, setResponse ] = useState({});
    const [ resultado, setResultado ] = useState({});
    const [ keysearch, setKeySearch ] = useState("");
    const [ busqueda, setBusqueda ] = useState("")


    const handleSearch = (e)=>{
        e.preventDefault();
        setResponse({});
        setResultado({});
        setBusqueda( keysearch );
        searchStudentByCarnetOrDPI( keysearch )
        .then( res => {
          if(res.status === 204 ){
            setResponse( { ok: false } )
          }else{
            setResponse( res.data )
          }
        })
        .catch( err => console.log(err))
    }

    const asociarEstudiante = () => {
        const reqBody = { programaId , studentId: response.student.id}
        asociarEstudianteAPrograma( reqBody )
        .then( res => setResultado({ type: "success", message: res.data.message }))
        .catch( err => setResultado({ type: "danger", message: err.response.data.message }))
    }

  return (
    <Modal className="modal-dialog-centered "
              size="lg"
              isOpen={showModal}
              toggle={() => setShowModal(false)}>

            <Card className="card-user">
                <CardHeader>
                    <CardTitle  tag="h6">Agregar Estudiante a { programaNombre }</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row className="mx-3">
                    <Col  md="4">
                      <Form onSubmit={handleSearch}>
                        <FormGroup>
                            <label>Carnet o DPI</label>                        
                            <Input
                            className="text-dark"
                            placeholder="Identificador"
                            type="text"
                            name="keysearch"
                            value={keysearch || ''}
                            onChange={ (e) => setKeySearch(e.target.value) }
                            required/>
                            <Button size="sm" type="submit" color="primary">Buscar</Button>
                        </FormGroup>
                      </Form>
                    </Col>
                    <Col className="mt-4" md="8">
                        <Row >
                          <Col md="12">
                            { resultado.message && <Alert color={resultado.type}>{resultado.message}</Alert> }
                            { (response.ok ) &&

                               <Card >
                                <CardHeader style={{
                                  backgroundColor: '#efefef',
                                  borderColor: '#f7f7f7'
                               }}>
                                  <CardTitle className="p-0" tag="h6">Estudiante encontrado</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md="5">
                                            <label>
                                                <h6>Nombre:</h6>
                                                <h6>DPI:</h6>
                                                <h6>Carnet:</h6>
                                                <h6>Grado Académico:</h6>
                                                <h6>Dirección:</h6>
                                                <h6>Universidad:</h6>
                                            </label>
                                        </Col>
                                        <Col>
                                            <label>
                                                <h6> {response.student.nombre}</h6>
                                                <h6> {response.student.dpi}</h6>
                                                <h6> {response.student.carnet}</h6>
                                                <h6> {response.student.grado}</h6>
                                                <h6> {response.student.direccion}</h6>
                                                <h6> {response.student.universidad}</h6>
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-right" md="12">
                                            <Button 
                                                onClick={ ()=>asociarEstudiante()}
                                                color="success">Agregar</Button>
                                        </Col>
                                    </Row>

                                  
                                </CardBody>
                              </Card>
                            }
                            { (response.ok === false) &&
                                <Alert color="warning">No se encontró ningún estudiante con carnet o DPI: {busqueda}.</Alert>
                            }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter></CardFooter>
            </Card>
    </Modal>
  )
}
