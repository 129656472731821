import axios from "axios"
import { UPDATE_SOLICITUD } from "utils/endpoints"
import { DELETE_SOLICITUD } from "utils/endpoints"
import { DELETE_ATENDIDAS_BY_STUDENT } from "utils/endpoints"
import { DELETE_ATENDIDAS } from "utils/endpoints"
import { GET_SOLICITUDES_BY_STUDENT } from "utils/endpoints"
import { GET_SOLICITUD } from "utils/endpoints"
import { GET_SOLICITUDES_BY_STATUS } from "utils/endpoints"
import { CREATE_SOLICITUD } from "utils/endpoints"

export const crearSolicitud = ( body )=>{
    return new Promise( (resolve, reject) => {
        axios.post( CREATE_SOLICITUD, body )
        .then( res => resolve(res))
        .catch( err => reject(err ))
    })
}

export const getSolicitudesByStatus = ( status )=>{
    return new Promise( (resolve, reject) => {
        axios.get( GET_SOLICITUDES_BY_STATUS +  status )
        .then( res => resolve(res))
        .catch( err => reject(err ))
    })
}

export const getSolicitud = ( idSolicitud )=>{
    return new Promise( (resolve, reject) => {
        axios.get( GET_SOLICITUD +  idSolicitud )
        .then( res => resolve(res))
        .catch( err => reject(err ))
    })
}

export const updateSolicitud = ( idSolicitud )=>{
    return new Promise( (resolve, reject) => {
        axios.patch( UPDATE_SOLICITUD +  idSolicitud )
        .then( res => resolve(res))
        .catch( err => reject(err ))
    })
}

export const getSolicitudesByStudent = ( studentId )=>{
    return new Promise( (resolve, reject) => {
        axios.get( GET_SOLICITUDES_BY_STUDENT +  studentId )
        .then( res => resolve(res))
        .catch( err => reject(err ))
    })
}

export const deleteSolicitud = ( idSolicitud )=>{
    return new Promise( (resolve, reject) => {
        axios.delete( DELETE_SOLICITUD +  idSolicitud )
        .then( res => resolve(res))
        .catch( err => reject(err ))
    })
}

export const deleteSolicitudesAtendidas = ()=>{
    return new Promise( (resolve, reject) => {
        axios.delete( DELETE_ATENDIDAS )
        .then( res => resolve(res))
        .catch( err => reject(err ))
    })
}

export const deleteSolicitudAtendidasByStudent = ( studentId )=>{
    return new Promise( (resolve, reject) => {
        axios.delete( DELETE_ATENDIDAS_BY_STUDENT +  studentId )
        .then( res => resolve(res))
        .catch( err => reject(err ))
    })
}
