import React from 'react'
import { Col, Row } from 'reactstrap';
import { statusRegistrada } from 'utils/tiposStatus';
import { statusAtendida } from 'utils/tiposStatus';
import { SolicitudListByStatus } from 'components/solicitudes/SolicitudList';
import { Configuracion } from 'components/solicitudes/Configuracion';

export const Solicitudes = () => {

  return (
    <div className="content">
       
       {/* <Row style={{justifyContent: 'center'}}>
          <Col md="6">
            <Configuracion/>
          </Col>
        </Row> */}
        <Row  style={{ justifyContent: 'center'}}>
          <Col md="6">
            <SolicitudListByStatus 
              statusKey={statusRegistrada.key}
              title="Solicitudes Pendientes"/>
          </Col>
          <Col md="6">
          <Configuracion/>
            <SolicitudListByStatus 
              statusKey={statusAtendida.key}
              title="Solicitudes Atendidas"/>
          </Col>
        </Row>
        
      
      
    </div>
  )
}
