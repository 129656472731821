import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import { Button, Col, Row } from 'reactstrap';
import { initStudent } from 'redux/actions/student.actions';

export let studentDPI;

export const Dashboard = () => {

    const user = useSelector( state => state.auth.user );
    const student = useSelector( state => state.estudiante.student);
    const fetched = useSelector( state => state.estudiante.fetched );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    studentDPI = student.dpi;


    useEffect(() => {
      if(!fetched){
        dispatch( initStudent( user.username ))
      }
    }, [])

  return (
    <div className="content">
        <Row style={{ justifyContent: 'center' }}>
          <Col className="text-center mx-5 mt-3 py-5" md="8" style={{alignItems: 'center'}}>
            <h2 className="mb-1" >Bienvenido</h2>
            <p className="mb-0">Portal de estudiantes de postgrados.</p>
            <hr className="mt-2" />
            <h5 className="mt-5 mb-0">{ student.nombre}</h5>
            <h5 className="mb-0">{ student.dpi }</h5>
            <h5 className="mb-0">{ student.carnet }</h5>
            <div className="mt-5" />
           
          </Col>
        </Row>
        <Row className="mt-5 mx-3">
          <Col className="text-right">
            <p className="mb-0 mx-3">Mantén tus datos actualizados.</p>
            <Button 
              className="py-1 px-3" color="primary"
              onClick={()=>navigate("/student/datos")}
              size="sm" style={{backgroundColor: "#f8f9fa", color: "#344767"}}>
              Actualizar tus datos
            </Button>
          </Col>
         
        </Row>
        
    </div>
  )
}
