import React from 'react'
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, FormGroup, Input, Row, Spinner } from 'reactstrap'
import { uploadStudentsByCSV } from 'redux/actions/student.actions';

// Cargar estudiantes por medio de CSV
export const CargaCSV = () => {
  const [selectedFile, setSelectedFile] = React.useState('');
  const [response, setResponse] = React.useState({});
  
  // Verifica si se ha seleccionado un archivo
  const isFileSelected = selectedFile !== '';

  const submitFile = () => {
    if (!isFileSelected) {
      setResponse({ color: 'warning', message: 'Por favor, seleccione un archivo antes de continuar.' });
      return;
    }

    // Verificar si el archivo tiene la extensión .xlsx
    const fileExtension = selectedFile.name.split('.').pop();
    if (fileExtension !== 'xlsx') {
      setResponse({ color: 'danger', message: 'Solo se permiten archivos con extensión .xlsx.' });
      return;
    }

    setResponse({ loading: true });
    const data = new FormData();
    data.append('file', selectedFile);

    uploadStudentsByCSV(data)
      .then((res) => {
        console.log(res);
        setResponse({ color: 'success', message: res.data.message });
      })
      .catch((err) => {
        setResponse({ color: 'danger', message: `${err.response.data.message}. Se ha cancelado todo el proceso.` });
      });
  };

  return (
    <Col md="6">
      <Card>
        <CardHeader>
          <CardTitle tag="h5">Registro por carga de CSV</CardTitle>
        </CardHeader>
        <CardBody>
          {response.message && (
            <Alert color={response.color}>
              <strong>{response.message}</strong>
            </Alert>
          )}
          <Row className="mx-2">
            <p>Utilice la plantilla de carga de datos proporcionada.</p>
          </Row>
          <Row className="mx-2">
            <Col md="12">
              <FormGroup>
                <label>CARGAR ARCHIVO</label>
                <Input
                  name="filecsv"
                  className="text-dark"
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                  type="file"
                  accept=".xlsx" // Solo archivos .xlsx
                  disabled={response.loading} // Deshabilitar input mientras se carga
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="mb-3">
          <Row>
            <Col className="mt-3">
              {response.loading ? <Spinner /> : null}
            </Col>
            <Col className="text-right mr-3 mt-3">
              <Button
                size="sm"
                color="primary"
                onClick={submitFile}
                disabled={response.loading || !isFileSelected} // Deshabilitar botón si no hay archivo o se está cargando
              >
                Procesar Registro
              </Button>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </Col>
  );
};
