import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap'
import validator from 'validator';
import { isObjEmpty } from 'utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { startLogin } from 'redux/actions/auth';
import { LoginNavbar } from 'components/Navbars/LoginNavbar';
import { useNavigate } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';





export const Login = () => {


    //const [ formLogin, setFormLogin ] = useState({ email: "", password: ""});
    const [ formLogin, setFormLogin ] = useState({ email: "", password: ""});
    const [ tipo, setTipo ] = useState("default");
    const [ errors, setErrors ] = useState({});
    const [ loading, setLoading ] = useState( {} );
    const dispatch = useDispatch();
    
    const loggedIn = useSelector( state => state.auth.loggedIn );
    const user = useSelector( state => state.auth.user );
    const navigate = useNavigate();

    const { executeRecaptcha } = useGoogleReCaptcha();
  
    useEffect(() => {
        console.log("hi, I am excuting this.")
        if( loggedIn ){
            if(user.role === 'role_admin'){
                navigate("/admin/dashboard")
            }else if(user.role === 'role_student'){
                navigate("/student/dashboard")
            }
            else{
                navigate("/home")
            }
    }}, [loggedIn])
  

    const handleLogin = async (e)=>{

        e.preventDefault();
        setLoading({ loading: true })
        setErrors({});
        const errores = {};

        
        

        if( !validator.isEmail( formLogin.email ) ){
            errores.email = "Por favor ingrese un email valido";
        }
        if(  validator.isEmpty( formLogin.password )){
            errores.password = "Por favor ingrese la contraseña.";
        }
        if( tipo === "default" ){
            errores.tipo = "Por favor seleccione un tipo de usuario.";
        }
        
        if( !isObjEmpty( errores )){
            setErrors(errores);
            setLoading( { loading: false, message: "Error en el formulario."} );
            return;
        }

        

        if (!executeRecaptcha) {
            setLoading({ loading: false, message: "No se pudo obtener el token de reCAPTCHA." });
            return;
          }

          const recaptchaToken = await executeRecaptcha('login');
          

      
          const { email, password } = formLogin;
          dispatch(startLogin({ email, password, recaptchaToken }))
            .then(res => console.log(res))
            .catch(err => setLoading({ message: err.response.data.message }));
        }


    const styleDiv = { 
        marginTop: "3.5%",
        justifyContent: "center",
    }
    const style = {
        boxShadow: "0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19)",
        paddingInline: "4%"
    }

  return (
    <>  
        <LoginNavbar/>
        <h3 className="text-center mt-5">Departamento de Estudios de Postgrado</h3>

        <div className="content" >
            <Row style={styleDiv}>
                <Col xl="3" lg="4" md="6" sm="8" xs="8"> 
                    <Card  className="py-2" style={style}>
                        <CardHeader className="text-center">
                            <h4>Ingreso</h4>
                        </CardHeader>
                        <CardBody >
                            { loading.message && 
                            <Alert color="warning">{loading.message}</Alert> }
                            <Form onSubmit={handleLogin}>
                                <Row style={{backgroundColor:""}}>
                                    <Col md="12">
                                    <FormGroup>
                                        <Label for="usuario">Usuario</Label>
                                        <Input
                                        name="usuario" 
                                        placeholder="Usuario" 
                                        value={formLogin.email} 
                                        onChange={(e) => setFormLogin( prev => ({...prev, email: e.target.value}))}
                                        invalid={ errors.email ? true : false }
                                        />
                                        <FormFeedback >
                                                    { errors.email }
                                        </FormFeedback>
                                    </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                    <FormGroup>
                                        <Label for="clave">Contraseña</Label>
                                        <Input name="clave" type="password" placeholder="Contraseña"
                                        value={formLogin.password}
                                        onChange={(e) => setFormLogin( prev => ({...prev, password: e.target.value}))}
                                        invalid={!!errors.password}
                                        />
                                        <FormFeedback>
                                            { errors.password}
                                        </FormFeedback>
                                    </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                  <Col md="12">
                                    <FormGroup>
                                        <Label for="tipo">Tipo Usuario</Label>
                                        <Input name="tipo" type="select"
                                        value={tipo}
                                        onChange={(e) => setTipo( e.target.value)}
                                        invalid={!!errors.tipo}
                                        required
                                        >
                                            <option value="default" disabled hidden>Seleccione tipo de usuario.</option>
                                            <option value="encargado">Administrador</option>
                                            <option value="estudiante">Estudiante</option>
                                        </Input>
                                        <FormFeedback>
                                            { errors.tipo}
                                        </FormFeedback>
                                    </FormGroup>
                                 </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <Button size="md"
                                        type='submit'
                                        className="btn btn-primary mb-3">Ingresar</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            
        </div>

        <hr className="mt-5"/>
        <p className="text-center">Centro Universitario de Oriente - Departamento de Estudios de Postgrado - 2022</p>
    </>
  )
}
