/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import { Estudiantes } from "views/Estudiantes";
import { Programas } from "views/Programas";
import { NuevoPrograma } from "components/programas/NuevoPrograma";
import { DetalleCarrera } from "components/programas/DetalleCarrera";
import { Cursos } from "views/Cursos";
import { CursosProgama } from "components/cursos/CursosProgama";
import { Inscripciones } from "views/Inscripciones";
import { DetalleEstudiante } from "components/estudiantes/DetalleEstudiante";
import { Users } from "views/Users";
import { DetalleInscripcionesCarrera } from "components/inscripciones/DetalleInscripcionesCarrera";
import { Asignaciones } from "views/Asignaciones";
import { Preinscripciones } from "views/Preinscripciones";
import { NuevoProceso } from "components/asignaciones/NuevoProceso";
import { DetalleCarreraAsignacion } from "components/asignaciones/DetalleCarreraAsignacion";
import { DetalleProceso } from "components/asignaciones/DetalleProceso";
import { DetalleCursoProceso } from "components/asignaciones/DetalleCursoProceso";
import { Solicitudes } from "views/Solicitudes";
import { SolicitudDetalle } from "components/solicitudes/SolicitudDetalle";
import  Backup  from "views/Backup.js";
import Icons from "views/Icons.js";
//Ruta a la página para subir las actas
//import Actas from 'nuevas_funciones/admin/subirActas/Actas';
import ActasLogic from 'nuevas_funciones/admin/subirActas/ActasLogic';
//Ruta a la página para generar las actas
import GenerarActasLogic from 'nuevas_funciones/admin/generarActas/GenerarActasLogic';
//Ruta a la página ingresar notas
import IngresarNotasLogic from 'nuevas_funciones/admin/ingresarNotas/IngresarNotasLogic';
//Ruta a la página generar certificaciones
import GenerarCertificacionLogic from 'nuevas_funciones/admin/certificaciones/GenerarCertificacionLogic';

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/cursos",
    name: "Cursos",
    icon: "nc-icon nc-book-bookmark " ,
    component: Cursos,
    layout: "/admin",
  },
  {
    path: "/programas",
    name: "Carreras",
    icon: "nc-icon nc-hat-3 " ,
    component: Programas,
    layout: "/admin",
  },
  {
    path: "/estudiantes",
    name: "Estudiantes",
    icon: "nc-icon nc-single-02",
    component: Estudiantes,
    layout: "/admin",
  },
  {
    path: "/asignaciones",
    name: "Asignaciones",
    icon: "nc-icon nc-single-copy-04",
    component: Asignaciones,
    layout: "/admin",
  },
  {
    path: "/inscripciones",
    name: "Inscripciones",
    icon: "nc-icon nc-refresh-69",
    component: Inscripciones,
    layout: "/admin",
  },
  {
    path: "/preinscripcion",
    name: "Preinscripcion",
    icon: "nc-icon nc-ruler-pencil",
    component: Preinscripciones,
    layout: "/admin",
  },
  {
    path: "/solicitudes",
    name: "Solicitudes",
    icon: "nc-icon nc-paper",
    component: Solicitudes,
    layout: "/admin",
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    icon: "nc-icon nc-circle-10",
    component: Users,
    layout: "/admin",
  },
   //Ruta para Subir Acta
   {
    path: "/subir-acta",
    name: "Subir acta fin de curso",
    icon: "nc-icon nc-cloud-upload-94",
    component: ActasLogic,
    layout: "/admin",
  },
  //Ruta para Generar Acta
  {
    path: "/generar-acta",
    name: "Generar acta fin de curso",
    icon: "nc-icon nc-cloud-download-93",
    component: GenerarActasLogic,
    layout: "/admin",
  },
  //Ruta para Ingresar Notas
  {
    path: "/ingresar-nota",
    name: "Ingresar/actualizar notas",
    icon: "nc-icon nc-badge",
    component: IngresarNotasLogic,
    layout: "/admin",
  },
  //Ruta para Generar Certificaciones
  {
    path: "/generar-certificacion",
    name: "Generar Certificaciones",
    icon: "nc-icon nc-icon nc-tap-01",
    component: GenerarCertificacionLogic,
    layout: "/admin",
  },
  //Ruta para descargar y cargar backups
  {
    path: "/backup",
    name: "Backup",
    icon: "nc-icon nc-box-2",
    component: Backup,
    layout: "/admin",
  },

  //Rutas que no van en el sidebar
  {
    path: "/programas/new",
    name: "Crear Carrera",
    icon: "",
    component: NuevoPrograma,
    layout: "/admin",
    hide: true
  },
  {
    path: "/programas/:id",
    name: "Carrera",
    icon: "",
    component: DetalleCarrera,
    layout: "/admin",
    hide: true
  },
  {
    path: "/asignaciones/nuevo",
    name: "Asignacion",
    icon: "",
    component: NuevoProceso,
    layout: "/admin",
    hide: true
  },
  {
    path: "/asignaciones/proceso/:id",
    name: "Asignacion",
    icon: "",
    component: DetalleProceso,
    layout: "/admin",
    hide: true
  },
  {
    path: "/asignaciones/proceso/:procesoId/curso/:cursoId",
    name: "Asignacion",
    icon: "",
    component: DetalleCursoProceso,
    layout: "/admin",
    hide: true
  },
  {
    path: "/asignaciones/programa/:id",
    name: "Asignacion",
    icon: "",
    component: DetalleCarreraAsignacion,
    layout: "/admin",
    hide: true
  },
  {
    path: "/inscripciones/programa/:id",
    name: "Inscripción",
    icon: "",
    component: DetalleInscripcionesCarrera,
    layout: "/admin",
    hide: true
  },
  {
    path: "/programas/cursos/:id",
    name: "Cursos",
    icon: "",
    component: CursosProgama,
    layout: "/admin",
    hide: true
  },
  {
    path: "/estudiante/:id",
    name: "Estudiante",
    icon: "",
    component: DetalleEstudiante,
    layout: "/admin",
    hide: true
  },
  {
    path: "/solicitudes/detalle/:id",
    name: "Solicitud",
    icon: "",
    component: SolicitudDetalle,
    layout: "/admin",
    hide: true
  },
  /*
  {
    path: "/icons",
    name: "Icons",
    icon: "nc-icon nc-diamond",
    component: Icons,
    layout: "/admin",
  },
  */
];
export default routes;
  
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-page",
  //   name: "User Profile",
  //   icon: "nc-icon nc-single-02",
  //   component: UserPage,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "nc-icon nc-caps-small",
  //   component: Typography,
  //   layout: "/admin",
  // },
  // {
  //   pro: true,
  //   path: "/upgrade",
  //   name: "Upgrade to PRO",
  //   icon: "nc-icon nc-spaceship",
  //   component: UpgradeToPro,
  //   layout: "/admin",
  // },
  
