import React, { useEffect, useState } from 'react'
import { FormDataEstudiante } from 'components/estudiantes/FormDataEstudiante'
import { Alert, Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { useSelector } from 'react-redux';
import { initStudent } from 'redux/actions/student.actions';
import { useDispatch } from 'react-redux';
import validator from 'validator';
import { isObjEmpty } from 'utils/helpers';
import { updateStudent } from 'redux/actions/student.actions';

export const Datos = () => {

  const [ dispositions, setDispositions ] = useState({ dpi: { disabled: true } });

  const user = useSelector( state => state.auth.user );
  const student = useSelector( state => state.estudiante.student);
  const [estudiante, setEstudiante ] = useState( student || {} );
  const [errors, setErrros] = useState({});
  const [response, setResponse ] = useState({});
  const fetched = useSelector( state => state.estudiante.fetched );
  const dispatch = useDispatch  ();
  
  useEffect(() => {
    if(!fetched){
      dispatch( initStudent( user.username ));
    }
  }, []);

  useEffect(()=>{
    const { userId , ...userData } = student;
    setEstudiante(userData);
    if( student.carnet && student.carnet!=='' ){
      setDispositions(prev => ({ ...prev, carnet: { disabled: true } }) );
    }
  }, [student])

  const onChange = (e)=>{
    const { name, value } = e.target;
    setEstudiante( prev => ({ ...prev, [name]: value }));
  }

  const handleClick = ()=>{
    setResponse({ loading: true });
    const errores = validations();
    if( !isObjEmpty( errores ) ){
      setErrros(errores);
      setResponse({});
      return;
    }
    updateStudent( estudiante )
    .then( res => {
      console.log(res);
      setResponse({ color: "success", message: "Datos actualizados correctamente."});
      dispatch( initStudent( user.username ));      
    }).catch( err => {
      setResponse({ color: "success", message: err.response.data.message });
    })
  }

  const validations = ()=>{
    setErrros({});
    const errores = {};

    if( !validator.isLength( estudiante.nombre, { min: 5 }) ){
      errores.nombre = "El nombre es obligatorio. Y debe tener al menos 5 caracteres.";
    }else if(!validator.matches(estudiante.nombre, /^[a-zA-Z\s]*$/)){
      errores.nombre = "El nombre solo puede contener letras mayusculas, minusculas y espacios";
    }

    if(!validator.isLength( estudiante.dpi, { min: 5, max:20}) ){
      errores.dpi = "El número de identificación debe tener de 5 a 20 caracteres.";
    }
    if( estudiante.email && !validator.isEmail( estudiante.email ) ){
      errores.email = "Por favor ingresa un correo electrónico válido."
    }

    if( estudiante.telefono && !validator.isLength( estudiante.telefono, { min: 8, max:20}) ){
      errores.telefono = "El teléfono debe tener al menos 8 dígitos.";
    }else if(!validator.matches(estudiante.telefono, /^[0-9]+$/)){
      errores.telefono = "El teléfono debe tener numeros enteros";
    }

    if( estudiante.whatsapp && !validator.isLength( estudiante.whatsapp, { min: 8, max:20}) ){
      errores.whatsapp = "El número de Whatsapp debe tener al menos 8 dígitos.";
    }else if(!validator.matches(estudiante.whatsapp, /^[0-9]*$/)){
      errores.whatsapp = "El registro de whatsapp debe tener números enteros";
    }

    if( !validator.matches(estudiante.direccion, /^[a-zA-Z0-9-\s]*$/) ){
      errores.direccion = "La direccion solo puede contener letras mayusculas, minusculas, numeros, espacios y guiones";
    }
    

    return errores;
  }

  return (
    <div className="content">
      <Card className="mx-2">
        <CardHeader className="mx-2">
            <CardTitle tag="h6">Tus Datos Personales</CardTitle>
        </CardHeader>
        <CardBody className="mx-5 px-5">
          <p className="text-muted mb-4">Para editar carnet o DPI comunicate a las oficinas de Estudios de Postgrado.</p>
          { response.message && 
            <Alert color={response.color}><strong>{ response.message }</strong></Alert>}
          <FormDataEstudiante 
            dispositions={dispositions}
            student={estudiante} 
            onChange={onChange} 
            onClick={ handleClick }
            errors={ errors }
            loading={ response.loading }/>
        </CardBody>
      </Card>
    </div>
  )
}
