import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Row, Spinner } from 'reactstrap';
import { createCurso } from 'redux/actions/curso.actions';
import { getCursosInPrograma } from 'redux/actions/curso.actions';
import { getProgramas } from 'redux/actions/programa.actions';
import { noEsPrimero } from 'utils/helpers';
import { isObjEmpty } from 'utils/helpers';
import validator from 'validator';
import { CursosPrerequisito } from './CursosPrerequisito';

export const NuevoCurso = () => {


  const cursoInitialState = {
    nombre: "",
    codigo: "",
    creditos: "",
    programaId:"default", 
    cursosPrerequisito:[]
  };

  const [ curso, setCurso ] = useState( cursoInitialState );
  const [ cursoCreado, setCursoCreado ] = useState( {} );
  const [ ready, setReady] = useState( false );
  const [ prerequisito, setPrerequisito ] = useState( "default" );
  const [ prerequisitos, setPrerequisitos ] = useState([]);
  const [ programas, setProgramas ] = useState([]);
  const [ cursosEnPrograma, setCursosEnPrograma ] = useState([]);
  const [ errors, setErrors ] = useState({});
  const [ response, setResponse ] = useState({});
  const [ prefijo, setPrefijo ] = useState("");

  useEffect(() => {
      getProgramas().then( res => setProgramas( res.data.programas ) )
  }, [])
  
  useEffect( () => {
    setPrerequisito("default")
    if( curso.programaId && curso.programaId !== "default"){
        setPrerequisitos( [] );
        getCursosInPrograma( curso.programaId ).then( res => {
          if( res.data.cursos.length < 1){
            setPrerequisito("empty")
            setCursosEnPrograma( [] )
          }else{
            setCursosEnPrograma( res.data.cursos )
          }
        });
    }
  }, [ curso.programaId] )


  useEffect( ()=> {
    if( prerequisito != "empty" && prerequisito !="default"){
      const curso = cursosEnPrograma.find( c => c.id == prerequisito);
      setPrerequisitos( prev => ([...prev, curso]));
      const agregadosBloqueados = cursosEnPrograma.map( c => {
        if( c.id == curso.id){ 
          return ({ ...c, disabled: true }) 
        }else{
          return c;
        }
      })
      setCursosEnPrograma( agregadosBloqueados );
    }
  }, [ prerequisito ])


  const onChange = ( e ) => {
    const { value, name } = e.target;
    if(name === "programaId"){
      setPrefijo( ()=> programas.find( p => p.id == value).prefijo );
    }
    setCurso( prev => ({
      ...prev,
      [ name ] : value
    })) 
  } 

  const handleSubmit = ( ) =>{
    setResponse({ loading: true })
    setCursoCreado({});
    setReady( false );
    
    setErrors({});
    const idsPrerequisitos =  prerequisitos.map(c => c.id );
    setCurso( prev => ({...prev, prefijo, cursosPrerequisito: idsPrerequisitos}))
    const errores = checkErrors();
    if( !isObjEmpty( errores ) ){
      setErrors(errores);
      setResponse({ loading: false })
      return;
    }else{
      setReady( true );
    }
  }

  useEffect(() => {
    if( ready === true ){
      createCurso( { ...curso, nombre: curso.nombre.toUpperCase(), codigo: curso.codigo.toUpperCase() } )
      .then( res => {
        setResponse({ type:"success", message: res.data.message})
        setCursoCreado( res.data.curso );
        setCurso( cursoInitialState );
        setCursosEnPrograma([]);
        setPrerequisitos([]);
        setReady( false );
      }).catch( err => {
        console.log( err.response.data.message );
        setResponse({ type:"danger", message: err.response.data.message })
        setReady( false );
      })
    } 
  }, [ready])
  
  const handleDelete = ( id ) =>{
    const arrayAux = prerequisitos.filter( c => c.id !== id);
    setPrerequisitos( arrayAux );
    const desbloquearBloqueados = cursosEnPrograma.map( c => {
      if( c.id == id){ 
        return ({ ...c, disabled: false }) 
      }else{
        return c;
      }
    })
    setCursosEnPrograma( desbloquearBloqueados );
  }

  const checkErrors = ()=>{
    const errores = {};
    
    if( !validator.isLength( curso.nombre, { min:5, max:151 })){
      errores.nombre = "El nombre debe tener al menos 5 caracteres y máximo 150.";
    }else if(!validator.matches(curso.nombre, /^[a-zA-Z0-9\s]*$/)){
      errores.nombre = "El codigo del curso solo puede contener letras mayusculas, minusculas, numeros y espacios";
    }

    if( validator.isEmpty(curso.codigo)){
      errores.codigo = "El curso debe tener un código único asignado por el personal.";
    }else if(!validator.matches(curso.codigo, /^[a-zA-Z0-9-]+$/)){
      errores.codigo = "El codigo solo puede contener letras mayusculas, minusculas, numeros y guiones";
    }

    if( validator.isEmpty(curso.creditos)){
      errores.creditos = "El número de créditos es obligatorio";
    }else if(!validator.matches(curso.creditos, /^[0-9]+$/)){
      errores.creditos = "Los créditos deben de ser un número entero";
    }

    if( validator.isEmpty(curso.programaId) || curso.programaId === "default" ){
      errores.programaId = "Por favor selecciona la carrera a la que pertenecerá el curso."
    }

    //Revisar si hay prerequisitos repetidos
    if( prerequisitos.length > 0 && prerequisitos.some( noEsPrimero )){
      errores.prerequisito = "Existen cursos prerequisitos repetidos, no es permitido."
    }

    return errores;
  }


  const searchProgramaById = (id)=>{
    const programa = programas.find( p => p.id === id );
    return programa;
  }

  return (
    <>
    { cursoCreado.id && 
      <Card>
        <CardHeader style={{
          backgroundColor: '#efefef',
          borderColor: '#f7f7f7'}}>
            <CardTitle className="p-0" tag="h6">
              { response.message &&  <Alert className="mb-0" color={response.type}>{response.message}</Alert> }
            </CardTitle>
            <CardBody>
              <label> 
                <h6>Código: {cursoCreado.codigo}</h6>
                <h6>Nombre: {cursoCreado.nombre}</h6>
                <h6>Creditos: {cursoCreado.creditos}</h6>
                <h6>Carrera: { searchProgramaById(cursoCreado.programaId).nombre }</h6>
              </label>
            </CardBody>
        </CardHeader>
      </Card>
      }
    <Card>
      <CardHeader>
        <CardTitle tag="h5">Registrar nuevo curso</CardTitle>
      </CardHeader>
      <CardBody className="pb-1">
      { (response.type !=="success" && response.message ) && <Alert color={response.type}>{response.message}</Alert> }
        
        <Form >
          <Row>
            <Col  md="12">
              <FormGroup>
                <label>{'NOMBRE DEL CURSO  '}<label className="text-warning m-0"><strong>*</strong></label></label>                        
                <Input
                  className="text-dark"
                  placeholder="Nombre del curso"
                  type="text"
                  name="nombre"
                  value={curso.nombre || ''}
                  onChange={ onChange }
                  invalid={ !! errors.nombre }
                  required/>
                    <FormFeedback>
                                { errors.nombre }
                    </FormFeedback>
                </FormGroup>
              </Col>
            </Row>  
            <Row>
              <Col md="12">
                <FormGroup>
                  <label>CARRERA<label className="text-warning m-0"><strong>*</strong></label></label>
                  <Input
                    className="text-dark"
                    placeholder="Selecciona la carrera a la que pertenecera el curso."
                    name="programaId"
                    value={curso.programaId }
                    onChange={ onChange }
                    invalid={ !! errors.programaId }
                    type="select"
                  >
                    <option key="default"  value="default" disabled hidden>Selecciona la carrera.</option>
                      {
                          programas.map( p => <option key={p.id} value={p.id}>{p.codigo}  {' - '}  {p.nombre}</option>)
                      }
                  </Input>
                  <FormFeedback>
                    { errors.programaId }
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>   
            <Row>
              <Col  md="6">
                <FormGroup>
                  <label>{'CÓDIGO '}<label className="text-warning m-0"><strong>*</strong></label></label>
                  <InputGroup>
                    <InputGroupText>
                      {prefijo} - 
                    </InputGroupText>
                    <Input
                      className="text-dark"
                      placeholder="Código identificador"
                      type="text"
                      name="codigo"
                      value={curso.codigo || ''}
                      onChange={ onChange }
                      invalid={ !! errors.codigo }
                      required
                    />
                    <FormFeedback >
                      { errors.codigo }
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col  md="6">
                <FormGroup>
                  <label>{'CRÉDITOS '}<label className="text-warning m-0"><strong>*</strong></label></label>                        
                  <Input
                    className="text-dark"
                    placeholder="Número de créditos"
                    type="text"
                    name="creditos"
                    value={curso.creditos || ''}
                    onChange={ onChange }
                    invalid={ !! errors.creditos }
                    required/>
                      <FormFeedback>
                                  { errors.creditos }
                      </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <label>CURSOS PREREQUISITO</label>
                  <Input
                    className="text-dark"
                    placeholder="Selecciona los cursos prerequisito."
                    name="prerequisito"
                    value={prerequisito }
                    onChange={ e =>setPrerequisito( e.target.value ) }
                    type="select"
                    invalid={ !! errors.prerequisito }
                  >
                      <option key="default"  value="default" disabled hidden>Selecciona los cursos prerequisitos.</option>
                      <option key="empty" value="empty" disabled hidden>La carrera seleccionada aún no tiene cursos.</option>

                      {
                          cursosEnPrograma.map( (c,i) => <option key={i} value={c.id} disabled={ c.disabled ? true : false }>{c.codigo}. {' '}  {c.nombre}</option>)
                      }
                  </Input>
                  <FormFeedback>
                    { errors.prerequisito }
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            <CursosPrerequisito 
              prerequisitos={prerequisitos}
              handleDelete={handleDelete}/>

            <Row>
              <Col className="ml-3">
                { response.loading && <Spinner color="primary"/>}
              </Col>
              <Col className=" text-right ml-3 mt-3">
                <Button
                  size="md"
                  color="primary"
                  onClick={()=> handleSubmit()}
                >
                  Crear Curso
                </Button>
                
              </Col>
            </Row>
          </Form>
      </CardBody>
    </Card>
    </>
  )
}
