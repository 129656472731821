import React from 'react';
import {Button} from 'reactstrap';
const URL_API = process.env.REACT_APP_API_URL

const BackupDownload = () => {
  const handleDownload = async () => {
    try {
      const response = await fetch(`${URL_API}/backup/backup`);
      if (!response.ok) {
        throw new Error('Error al descargar el backup.');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'backup.sql';
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error:', error);
      alert('Hubo un error al descargar el backup.');
    }
  };

  return (
    <Button
      size="md"
      color="primary"
      onClick={handleDownload}
      >
    Descargar Backup
    </Button>
                
                
  );
};

export default BackupDownload;
