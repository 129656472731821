import React, { useState, useRef } from 'react';
import axios from 'axios';
import { SUBIR_ACTA } from 'utils/endpoints';
import ActasView from './ActasView'; 
import CustomToast from '../../CustomToast';

function ActasLogic() {
  const [archivos, setArchivos] = useState(null); // Estado para almacenar los archivos seleccionados
  const [datosActa, setDatosActa] = useState(null); // Estado para almacenar los datos del acta procesada
  const [datosCargados, setDatosCargados] = useState(false); // Estado para indicar si los datos están cargados
  const [estudiantesEditando, setEstudiantesEditando] = useState({}); // Estado para mantener el estado de edición de los estudiantes
  const inputRef = useRef(null); // Referencia al input de tipo 'file' para limpiarlo  
  //Estados de los botones
  const [cargarArchivosHabilitado, setCargarArchivosHabilitado] = useState(false);
  const [guardarCambiosHabilitado, setGuardarCambiosHabilitado] = useState(false);
  const [limpiarHabilitado, setLimpiarHabilitado] = useState(false);
  //Notificaciones
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');


  // Función para manejar el cambio en el input de tipo 'file'
  const SubirActas = (e) => {
    setArchivos(e.target.files);
    setCargarArchivosHabilitado(true); // Habilitar el botón "Cargar Archivos"
  };

  
   // Función para insertar el acta mediante una solicitud al backend
  const insertarActa = async () => {
  const f = new FormData();

  for (let index = 0; index < archivos.length; index++) {
    f.append('archivo', archivos[index]);
  }

  try {
    // Enviar solicitud al backend para procesar el archivo
    const response = await axios.post(SUBIR_ACTA, f);
    setDatosActa(response.data);
    setDatosCargados(true);

    setGuardarCambiosHabilitado(true); // Habilitar el botón "Guardar Cambios"
    setLimpiarHabilitado(true); // Habilitar el botón "Limpiar"
  } catch (error) {
    showErrorNotification('Error al subir el archivo');
    console.error('Error al subir el archivo:', error);
  }
  
};

// Función para mostrar la notificación de error
const showErrorNotification = (message) => {
  setToastMessage(message);
  setShowErrorToast(true);

  // Oculta la notificación después de un cierto tiempo
  setTimeout(() => {
    setShowErrorToast(false);
  }, 3000); // Puedes ajustar el tiempo en milisegundos
};

// Función para mostrar la notificación de éxito
const showSuccessNotification = (message) => {
  setToastMessage(message);
  setShowSuccessToast(true);

  // Oculta la notificación después de un cierto tiempo
  setTimeout(() => {
    setShowSuccessToast(false);
  }, 3000); // Puedes ajustar el tiempo en milisegundos
};

// Función para guardar los cambios en la base de datos
const guardarCambios = async (datosActa) => {
  //console.log('Datos a guardar:', datosActa);
  try {
    // Enviar solicitud al backend para insertar los datos en la base de datos
    //console.log('Entro al try de guardar cambios');
    const response = await axios.post(`${SUBIR_ACTA}/guardar`, { datosActa });
    showSuccessNotification('¡Datos guardados en la base de datos!');
    //console.log('Datos guardados en la base de datos:', response.data);
    
  } catch (error) {
    showErrorNotification('Error al guardar los datos.');
    console.error('Error al guardar los datos:', error);
  }
};


  // Función para activar la edición de un estudiante
  const handleEditar = (index) => {
    setEstudiantesEditando({ ...estudiantesEditando, [index]: true });
    document.querySelector('.actas-content').classList.add('editing');
  };

  // Función para confirmar los cambios realizados en un estudiante
  const handleConfirmarCambios = (index) => {
    setEstudiantesEditando({ ...estudiantesEditando, [index]: false });
    // Quita la clase 'editing' de actas-content al confirmar los cambios
    document.querySelector('.actas-content').classList.remove('editing');
    // Aquí puedes agregar la lógica para guardar los cambios en el backend si es necesario
  };

 
  // Función para manejar el cambio en los inputs de los estudiantes
  const handleInputChange = (index, campo, valor) => {
    const estudiantesActualizados = datosActa.estudiantes.map((estudiante, i) =>
      i === index ? { ...estudiante, [campo]: valor } : estudiante
    );
    setDatosActa({ ...datosActa, estudiantes: estudiantesActualizados });
  };

  // Función para limpiar la vista y volver al estado inicial
  const limpiarVista = () => {
    setDatosCargados(false);
    setDatosActa(null);
    setArchivos(null);
    setEstudiantesEditando({});

    setCargarArchivosHabilitado(false); // Deshabilitar el botón "Cargar Archivos"
    setGuardarCambiosHabilitado(false); // Deshabilitar el botón "Guardar Cambios"
    setLimpiarHabilitado(false); // Deshabilitar el botón "Limpiar"

    // Establece el valor del input de tipo 'file' como null para limpiarlo
    if (inputRef.current) {inputRef.current.value = null;}
  };
  
  // Renderiza el componente view pasando los estados y funciones relevantes como props
  return (
    <div className="content">
    <ActasView
      archivos={archivos}
      datosActa={datosActa}
      datosCargados={datosCargados}
      estudiantesEditando={estudiantesEditando}
      inputRef={inputRef}
      SubirActas={SubirActas}
      insertarActa={insertarActa}
      handleEditar={handleEditar}
      handleConfirmarCambios={handleConfirmarCambios}
      handleInputChange={handleInputChange}
      limpiarVista={limpiarVista}
      guardarCambios={guardarCambios}
      //Estados botones
      cargarArchivosHabilitado={cargarArchivosHabilitado}
      guardarCambiosHabilitado={guardarCambiosHabilitado}
      limpiarHabilitado={limpiarHabilitado}
    />
    {/* Renderizar el Toast de éxito cuando sea necesario */}
    {showSuccessToast && (
        <CustomToast
          type="success"
          message={toastMessage}
          onClose={() => setShowSuccessToast(false)}
        />
      )}

      {/* Renderizar el Toast de advertencia cuando sea necesario */}
      {showErrorToast && (
        <CustomToast
          type="error"
          message={toastMessage}
          onClose={() => setShowErrorToast(false)}
        />
      )}
    </div>
  );
}

export default ActasLogic;
