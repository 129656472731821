import React from 'react'
import { Col, Navbar, Row } from 'reactstrap';
import logo from "../../assets/img/logotipoCUNORI-2.png";


export const LoginNavbar = () => {
  return (
    <Navbar  style={{backgroundColor:"#2b2b2e", height:"80px", justifyContent: 'center'}}>
        <Row className="mx-5 px-5" style={{width: '80%'}}>
          <Col md="6">
          <div className="logo" style={{display: "flex", alignItems:"right" }}>
            <a href="/" className="simple-text logo-mini">
                <div className="logo-img">
                    <img src={logo} alt="react-logo" height="50px" width="50px"/>
                </div> 
            </a>
            <a href="/" className="simple-text logo-normal ml-2 text-center">
                POSTGRADOS
            </a>
          </div>
          </Col>
          <Col  md="6" className="text-right" style={{display: "flex", alignItems: 'center', justifyContent:'flex-end'}}>
            <a href="/postgrados" className="simple-text logo-normal ml-2 text-center">
                INICIAR SESIÓN
            </a>
            <a href="/preinscripcion" className="simple-text logo-normal ml-2 text-center">
                PREINSCRIPCIÓN
            </a>
          </Col>
        </Row>
        
    </Navbar>
    )
}
