import React from 'react'
import { Table } from 'reactstrap'
import moment from 'moment'

export const RespuestasTable = ( { respuestas = [] }) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Correo</th>
          <th>Carrera de Interes</th>
          <th>Telefono</th>
          <th>DPI</th>
          <th>Fecha</th>
        </tr>
      </thead>
        <tbody>
        { respuestas.map( r => (
          <tr key={r.id}>
            <td>{r.nombre}</td>
            <td>{r.email}</td>
            <td>{r.programa.nombre}</td>
            <td>{r.telefono}</td>
            <td>{r.dpi}</td>
            <td>{ moment( r.createdAt ).format("DD/MM/YYYY")}</td>
          </tr>
        ))}
        </tbody>
    </Table>
  )
}
