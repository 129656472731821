import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Alert, Badge, Button, Card, CardBody, CardHeader, CardTitle, Col, FormFeedback, FormGroup, Input, Row } from 'reactstrap'
import { getInscripcionStudentByPrograma } from 'redux/actions/inscripciones.actions';
import validator from 'validator';
import moment from 'moment';
import { inscribirEstudiante } from 'redux/actions/inscripciones.actions';
import { ModalConfirmarInscripcion } from './ModalConfirmarInscripcion';
export const InscripcionCard = ({ programa }) => {

  const student = useSelector( state => state.estudiante.student )
  const [ lastInscripcion, setLastInscripcion ] = useState({ inscrito: false });
  const [ ciclo, setCiclo ] = useState("default");
  const [ showModal, setShowModal ]  = useState( false );
  const [ errors, setErrors ] = useState({});
  const [ response, setResponse ] = useState({});


  const arrayCiclos = ()=>{
      let ciclos = [];
      for( let i=0; i<programa.ciclos; i++){
        ciclos.push(i+1)
      }
      return ciclos;
  }

  useEffect( ()=>{
      getInscripcionStudentByPrograma( student.id, programa.id )
      .then( res => {
        if( res.data.response !== null ){
          setLastInscripcion( res.data.response );
        }else{
          setLastInscripcion( prev => ({...prev, exist: false }))
        }
      })
      .catch( err => console.log(err))
  }, [])


  const handleInscripcion = ()=>{
    setErrors({})
    if( ciclo==="default" || !validator.isNumeric(ciclo.toString()) ){
      setErrors({ ciclo: "Por favor selecciona el año académico al que deseas inscribirte."})
      return;
    }

    if( lastInscripcion.inscrito === true ){
      setShowModal(true);
    }else{
      hacerInscripcion();
    }
  }

  const hacerInscripcion = ()=>{
    inscribirEstudiante( student.id, programa.id, ciclo )
    .then( res => {
      setResponse({ type: "success", message: res.data.message });
      setLastInscripcion( res.data.response );
      if( showModal == true ){ 
        setShowModal(false)
      }
    })
    .catch( err => {
      setResponse({ type: "danger", message: err.response.data.message });
      if( showModal == true ){ 
        setShowModal(false)
      }
    })
  }
  return (
    <>
    <Card>
      <CardHeader className="pb-2" style={{
                backgroundColor: '#efefef',
                borderColor: '#f7f7f7'
             }}>
        <Row style={{ alignItems: 'center'}} >
          <Col>
            <CardTitle className="mb-1" tag="h6">{ lastInscripcion.inscrito ? "Inscripción Actual: " : "Última Inscripción: "}</CardTitle>
            { lastInscripcion.ciclo 
              ? 
                <Row className=" mb-1"> 
                  <Col>
                    <strong>
                      <p className="text-muted mb-0">Año de carrera (ciclo académico): { lastInscripcion.ciclo ? lastInscripcion.ciclo : "Sin registro." }  </p>
                    </strong>
                    <strong>
                      <p className="text-muted">Fecha de inscripción: { lastInscripcion.fechaInscripcion ?  moment(lastInscripcion.fechaInscripcion).format("DD/MM/YYYY") : "Sin registro."}  </p>
                    </strong>
                  </Col>
                </Row>
              : 
                <strong>
                  <p className="ml-3 mb-0">No existe una inscripción previa.</p>
                </strong>
                }
          </Col>
          <Col className="text-right">
            <Badge className="p-2" color={ lastInscripcion.inscrito ? "success" : "danger"}>
              <h6 className="m-0">{ lastInscripcion.inscrito ? "Inscrito" : "No inscrito" }</h6>
            </Badge>
          </Col>
        </Row>
        <Row>
          <Col>
            {lastInscripcion.inscrito ? 
              <strong>
                <p className="ml-3 text-success">Inscrito en el curso actual.</p>
              </strong> 
            : 
              <strong>
                <p className="ml-3 text-danger">No tienes inscripciones vigentes. No estas inscrito en el año actual.</p>
              </strong> 
            }
          </Col>
        </Row>
      </CardHeader>
    </Card>
  
    <Card>
      <CardHeader>
        <CardTitle tag="h6">Inscribirse a {programa.nombre} - Año { new Date().getFullYear()}</CardTitle>
      </CardHeader>
      <CardBody>
        <Row className="ml-1">
          <Col md="7" sm="12" >
          { response.message && <Alert color={response.type}><strong>{response.message}</strong></Alert> }
          <FormGroup>
              <p className="mb-1">Año de carrera (ciclo académico)</p>
              <p className="text-muted">Ingresa el año de carrera en el que deseas aparecer inscrito, 
                si deseas volver a inscriberte al mismo año que te inscribiste la última vez, puedes hacerlo.
              </p>
              <p className="text-muted">Si llevas cursos de distintos años, selecciona el año con mayor cantidad de cursos.</p>
              <Input
              value={ciclo}
              onChange={(e)=>setCiclo( e.target.value )}
              type="select"
              invalid={ !!errors.ciclo }>
                <option key="default" value="default" >Selecciona el año al que te inscribes.</option>
                { arrayCiclos().map( c => <option key={c} value={c}>{c}</option>) }
              </Input>
              <FormFeedback>
                {errors.ciclo}
              </FormFeedback>
              <Button style={{ backgroundColor:"#31708f"}} 
                  className="ml-0 p-2" 
                  onClick={()=>handleInscripcion()}
                  color="primary">Inscribirse</Button>
            </FormGroup>
          </Col>    
        </Row>
      </CardBody>
    </Card>
    { showModal &&
      <ModalConfirmarInscripcion 
      setShowModal={setShowModal}
      showModal={showModal}
      inscribirCallback={ hacerInscripcion } />
    }
    </>
  )
}
