import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, CardBody, CardHeader, Col, FormFeedback, FormGroup, Input, Modal, Row, Spinner } from 'reactstrap';
import { createCursoAsignable } from 'redux/actions/asignaciones.actions';
import { getCursosInPrograma } from 'redux/actions/curso.actions';
import validator from 'validator';

export const ModalAddAsignable = ({ showModal, setShowModal, procesoId, carrera, idsAsignables, updateInfoCallback }) => {

  const [ cursos, setCursos ] = useState([]);
  const [ curso, setCurso ] = useState("default");
  const [ errors, setErrors ] = useState({});
  const [ response, setResponse ] = useState({});

  useEffect( ()=>{
    getCursosInPrograma( carrera.id )
    .then( res => {
        const cursosTraidos = res.data.cursos;
        const cursosDisponibles = cursosTraidos.filter( c => {
            const yaEsAsignable = idsAsignables.some( id => c.id === id );
            if( !yaEsAsignable ){
                return c;
            }
        });
        setCursos( cursosDisponibles );
    })
    .catch( err => console.log( err.response.data.message ));

  }, [])

  const handleAdd = ()=>{
      setResponse({ loading: true });
      setErrors({});
      if( curso === "default" || !validator.isInt( curso )) {
          setErrors( { curso: "Por favor selecciona un curso."} );
          setResponse({});
          return;
      }

      createCursoAsignable({ procesoId, cursoId: parseInt( curso ) })
      .then( res => {
        setResponse({ type: "success", message: "Curso agregado a este proceso de asignación correctamente."});
        updateInfoCallback();
        console.log( res.data );
      }).catch( err => {
          console.log(err)
        setResponse({ type: "danger", message: err.response.data.message });
      })
  }

  return (
    <Modal className="modal-dialog-centered "
      size="md"
      isOpen={showModal}
      toggle={() => setShowModal(false)}>

      <Card >
        <CardHeader>
          <h5>Agregar Curso Asignable - { carrera.nombre }</h5>
        </CardHeader>
        <CardBody className="mx-2">
          <Row style={{alignItems: 'center'}}>
            <Col md="12">
              <FormGroup className="mb-0">
                  <p className="text-muted">Se listan los cursos de la carrera que aun no han sido seleccionados como asignables.</p>
                  { response.message && <Alert color={response.type}>{ response.message }</Alert>}
                 
                  <Input
                    value={ curso }
                    type="select"
                    onChange={ e => setCurso(e.target.value)}
                    invalid={ !!errors.curso}
                  >
                    <option key="default" value="default" disabled hidden>Selecciona el curso que deseas agregar.</option>
                    { cursos.map( c => 
                        <option key={c.id} value={c.id}>{ c.codigo } - { c.nombre }</option>)}
                  </Input>
                  <FormFeedback>{ errors.curso }</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="mt-1 pl-0 text-right" md="12" >
              { response.loading && <Spinner/>}
              <Button className="ml-3" color="primary" onClick={()=>handleAdd()}>Agregar</Button>
            </Col>
          </Row>
        </CardBody>
      </Card>

    </Modal>
  )
}
