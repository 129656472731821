import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Form,FormFeedback, FormGroup, Input, Row } from 'reactstrap'
import { searchStudentByCarnetOrDPI } from 'redux/actions/student.actions';
import validator from 'validator';

export const BuscarEstudiante = () => {

  const [ keysearch, setKeySearch ] = useState("");
  const [ response, setResponse ] = useState({});
  const [ busqueda, setBusqueda ] = useState("");
  const [ errors, setErrors ] = useState({});
  
  const handleSearch = (e)=>{
    e.preventDefault();
    setResponse({});
    setErrors({});

    if( validator.isEmpty( keysearch )){
      console.log('entre al if');
      setErrors({ key : "Por favor ingresa un número de DPI o CARNET"});
      return;
    }else if(!validator.matches(keysearch, /^[0-9]+$/)){
      console.log('entre al segundo if');
      setErrors({ key : "El DPI o CARNET debe contener números enteros"});
      return; 
    }

    setBusqueda( keysearch );
    searchStudentByCarnetOrDPI  ( keysearch )
    .then( res => {
      if(res.status === 204 ){
        setResponse( { ok: false } )
      }else{
        setResponse( res.data )
      }
    })
    .catch( err => console.log(err))
}

  return (
    <>
        <Card>
            <CardHeader>
            <CardTitle tag="h5">Buscar Estudiante</CardTitle>
            </CardHeader>
            <CardBody>
            <Row className="mx-2">
                <Col  md="12">
                    <Form onSubmit={handleSearch}>
                    <FormGroup>
                        <label>Carnet o DPI</label>                        
                        <Input
                        className="text-dark"
                        placeholder="Identificador"
                        type="text"
                        name="keysearch"
                        value={keysearch || ''}
                        onChange={ (e) => setKeySearch(e.target.value) }
                        invalid={ !! errors.key }
                        required/>
                    <FormFeedback>
                        { errors.key }
                      </FormFeedback>
                        <Button size="sm" type="submit" color="primary">Buscar</Button>
                    </FormGroup>
                    </Form>
                </Col>
            </Row>
            <Row>
              <Col>
                { response.ok && 
                
                  <Card>
                    <CardHeader style={{ backgroundColor: '#efefef', borderColor: '#f7f7f7' }}>
                    <CardTitle className="p-0" tag="h6">Estudiante encontrado</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                        <Col md="5">
                            <label>
                                <h6>Nombre:</h6>
                                <h6>DPI:</h6>
                                <h6>Carnet:</h6>
                                <h6>Grado Académico:</h6>
                                <h6>Dirección:</h6>
                                <h6>Universidad:</h6>
                            </label>
                        </Col>
                        <Col>
                            <label>
                                <h6> {response.student.nombre}</h6>
                                <h6> {response.student.dpi}</h6>
                                <h6> {response.student.carnet}</h6>
                                <h6> {response.student.grado}</h6>
                                <h6> {response.student.direccion}</h6>
                                <h6> {response.student.universidad}</h6>
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-right" md="12">
                        <Link to={`estudiante/${response.student.id}`}>
                          <Button 
                                  size="sm"
                                  color="success">Gestionar</Button>
                        </Link>
                        </Col>
                    </Row>
                </CardBody>
              </Card>
              }
              { (response.ok === false) &&
                <Alert className="mx-4" color="warning">No se encontró ningún estudiante con carnet o DPI: {busqueda}.</Alert>
              }
              </Col>
            </Row>
            </CardBody>
            <CardFooter></CardFooter>
        </Card>
    </>
  )
}
