import React, { useEffect, useState, useCallback } from 'react'
import { useParams, useLocation } from 'react-router'
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Input, FormGroup } from 'reactstrap'
import { getSolicitud } from 'redux/actions/solicitud.actions';
import { BoletaSolicitud } from './BoletaSolicitud';
import moment from 'moment';
import { updateSolicitud } from 'redux/actions/solicitud.actions';
import { ModalConfirmarAtendida } from './ModalConfirmarAtendida';
import { ModalConfirmarEliminar } from './ModalConfirmarEliminar';
import axios from 'axios';
import { ENVIAR_CORREOS } from 'utils/endpoints';
import CustomToast from '../../nuevas_funciones/CustomToast';

export const SolicitudDetalle = () => {

  const location = useLocation(); // Para acceder a la ruta completa

  //const { id } = useParams();
  const parts = location.pathname.split('/');
  const id = parts[parts.length - 1];
  const [solicitud, setSolicitud] = useState({});
  const [, setResponse] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [programa, setPrograma] = useState({});
  const [estudiante, setEstudiante] = useState({});
  //Para controlar los nuevos status de la certificacion
  const [estados, setEstado] = useState([]);
  const [selectedEstado, setSelectedEstado] = useState('');
  const [selectedEstadoText, setSelectedEstadoText] = useState('');
  //Crear un objeto para almacenar el estado seleccionado por solicitud
  const [selectedStatesBySolicitud, setSelectedStatesBySolicitud] = useState({});

  //Notificaciones
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const isComentarioImpreso = ['IMPRESA', 'Impresa', 'impresa'].includes(solicitud.comentario);

  // Función para mostrar la notificación de éxito
  const showSuccessNotification = (message) => {
    setToastMessage(message);
    setShowSuccessToast(true);

    // Oculta la notificación después de un cierto tiempo
    setTimeout(() => {
      setShowSuccessToast(false);
    }, 3000); // Puedes ajustar el tiempo en milisegundos
  };

  // Función para mostrar la notificación de éxito
  const showErrorNotification = (message) => {
    setToastMessage(message);
    setShowErrorToast(true);
    // Oculta la notificación después de un cierto tiempo
    setTimeout(() => {
      setShowErrorToast(false);
    }, 3000); // Puedes ajustar el tiempo en milisegundos
  };

  useEffect(() => {
    //console.log(id);
    getSolicitud(id)
      .then(res => {
        const { programa, estudiante, ...resto } = res.data;
        setPrograma(programa);
        setEstudiante(estudiante);
        setSolicitud(resto);
      }).catch(err => { console.log(err.response.data.message) })

    cargarEstados(); // Llama a la función cargarEstados para cargar los estados cuando se monte el componente
    // Recuperar el valor almacenado en el almacenamiento local
    const storedSelectedEstadoText = localStorage.getItem(`selectedEstadoText-${id}`);
    if (storedSelectedEstadoText) {
      setSelectedEstadoText(storedSelectedEstadoText);
      setSelectedEstado(storedSelectedEstadoText); // Establecer el valor en el estado si es necesario
    }
  }, [id])

  const cambiarStatus = () => {
    updateSolicitud(id)
      .then(res => {
        setSolicitud(res.data);
        setShowModal(false);
      })
      .catch(err => setResponse({ color: "danger", message: err.response.data.message }));
  }

  const obtenerStatusCertificacion = async () => {
    return ['PENDIENTE DE APROBACION', 'PENDIENTE DE IMPRIMIR', 'CERTIFICACION IMPRESA', 'FIRMAS PENDIENTES', 'PASAR A RECOGER'];
  }

  const cargarEstados = useCallback(async () => {
    const estadosData = await obtenerStatusCertificacion();
    setEstado(estadosData);
  }, []);



  const handleEstadoChange = (event) => {
    setSelectedEstado(event.target.value);
    const selectedText = event.target.options[event.target.selectedIndex].text; // Obtener el texto seleccionado
    setSelectedEstadoText(selectedText); // Actualizar el estado del texto seleccionado

    // Guardar el valor seleccionado en el objeto por solicitud
    setSelectedStatesBySolicitud({ ...selectedStatesBySolicitud, [id]: selectedText });
    // Guardar el valor en el almacenamiento local
    localStorage.setItem(`selectedEstadoText-${id}`, selectedText);
    //localStorage.setItem('selectedEstadoText', selectedText);

    if(isComentarioImpreso){
      enviarCorreos(estudiante.email, solicitud.tipo, selectedText, estudiante.nombre);
    }
    
    //console.log('Status de la solicitud: ' + selectedText);
    //console.log('Correo del estudiante: ' + estudiante.email);
    //console.log('Tipo de solicitud: ' + solicitud.tipo);

  };

  const enviarCorreos = async (correoDestinatario, solicitudTipo, estadoSolicitud, nombreEstudiante) => {
    if (estadoSolicitud === "PASAR A RECOGER") {
      try {
        const response = await axios.post(`${ENVIAR_CORREOS}/enviar-correo`, {
          correoDestinatario,
          solicitudTipo,
          nombreEstudiante
        });
        console.log(response.data);
        // Mostrar la notificación de éxito
        showSuccessNotification('¡Correo de notificación enviado correctamente!');
      } catch (error) {
        console.error('Error al enviar el correo electrónico:', error);
        showErrorNotification('Error al enviar el correo electrónico.');
      }
    }
  };

  return (
    <div className="content">
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <h5>Detalle Solicitud</h5>
            </Col>
            <Col className="text-right mt-1">
              <Badge color={solicitud.status === "REGISTRADA" ? "danger" : "info"}>
                {solicitud.status === 'REGISTRADA' ? (
                   {/*Agregue esto && isComentarioImpreso para validar solo cuando son certificaciones impresas*/},
                  solicitud.tipo === 'CONSTANCIAS O CERTIFICACIONES' && isComentarioImpreso ? (
                    `SIN ATENDER - ${selectedEstadoText}`
                  ) : (
                    `SIN ATENDER`
                  )
                ) : (
                  `ATENDIDA`
                )}
              </Badge>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="mx-5" style={{ backgroundColor: "#f8f9fa" }}>
          <Row >
            <Col md="12">
              <h6 className="mb-0" style={{ color: "#344767" }}>SOLICITUD:</h6>
              <div className="ml-3 mt-2">
                <div>
                  <label className="text-muted mr-2  mb-0">Tipo:</label>
                  <label className="text-dark  mb-0"><strong>{solicitud.tipo}</strong></label>
                </div>
                <div>
                  <label className="text-muted mr-2  mb-0">Fecha:</label>
                  <label className="text-dark  mb-0"><strong>{moment(solicitud.createdAt).format("DD/MM/YYYY")}</strong></label>
                </div>
                <div>
                  <label className="text-muted mr-2  mb-0">Monto:</label>
                  <label className="text-dark  mb-0"><strong>{solicitud.monto || "Sin registro."}</strong></label>
                </div>
                <div>
                  <label className="text-muted mr-2  mb-0">Comentario:</label>
                  <label className="text-dark  mb-0"><strong>{solicitud.comentario || "Sin registro."}</strong></label>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md="6">
              <h6 className="mb-0" style={{ color: "#344767" }}>Datos del Estudiante:</h6>
              <div className="ml-3 mt-2">
                <div>
                  <label className="text-muted mr-2  mb-0">Estudiante:</label>
                  <label className="text-dark  mb-0"><strong>{estudiante.nombre}</strong></label>
                </div>
                <div>
                  <label className="text-muted mr-2  mb-0">DPI:</label>
                  <label className="text-dark  mb-0"><strong>{estudiante.dpi}</strong></label>
                </div>
                <div>
                  <label className="text-muted mr-2  mb-0">Carnet:</label>
                  <label className="text-dark  mb-0"><strong>{estudiante.carnet || "Sin registro"}</strong></label>
                </div>
                <div>
                  <label className="text-muted mr-2  mb-0">Carrera:</label>
                  <label className="text-dark  mb-0"><strong>{programa.nombre || "Sin registro"}</strong></label>
                </div>
              </div>
            </Col>
            <Col md="6">
              <h6 className="mb-0" style={{ color: "#344767" }}>Datos de Contacto:</h6>
              <div className="ml-3 mt-2">
                <div>
                  <label className="text-muted mr-2  mb-0">Correo:</label>
                  <label className="text-dark  mb-0"><strong>{estudiante.email || "Sin registro"}</strong></label>
                </div>
                <div>
                  <label className="text-muted mr-2  mb-0">Teléfono:</label>
                  <label className="text-dark  mb-0"><strong>{estudiante.telefono || "Sin registro"}</strong></label>
                </div>
                <div>
                  <label className="text-muted mr-2  mb-0">Whatsapp:</label>
                  <label className="text-dark  mb-0"><strong>{estudiante.whatsapp || "Sin registro"}</strong></label>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-4 mr-1" style={{ justifyContent: 'flex-end' }}>
            {solicitud.tipo === "CONSTANCIAS O CERTIFICACIONES" && solicitud.status !== "ATENDIDA" && isComentarioImpreso &&(
              <FormGroup className="mt-2 mb-2">
                <Input
                  className="text-dark"
                  placeholder="Seleccionar un status para la certificación"
                  type="select"
                  name="status"
                  value={selectedEstado}
                  onChange={handleEstadoChange}
                  required
                >
                  <option value="">Seleccione un estado</option>
                  {estados.map((estado) => (
                    <option key={estado} value={estado}>
                      {estado}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            )}

            {solicitud.status !== "ATENDIDA" &&
              <Button onClick={() => setShowModal(true)} color="success" size="sm">Marcar Como Atendida</Button>}

            <BoletaSolicitud solicitud={solicitud} estudiante={estudiante} carrera={programa.nombre} />
            <Button color="danger" size="sm" onClick={() => setShowModalDelete(true)}>Eliminar</Button>
          </Row>
        </CardBody>
        <CardFooter></CardFooter>
      </Card>
      {
        showModal &&
        <ModalConfirmarAtendida
          showModal={showModal}
          setShowModal={setShowModal}
          funcCallback={cambiarStatus} />
      }
      {
        showModalDelete &&
        <ModalConfirmarEliminar
          showModal={showModalDelete}
          setShowModal={setShowModalDelete}
          solicitudId={solicitud.id} />
      }
      {showSuccessToast && (
        <CustomToast
          type="success"
          message={toastMessage}
          onClose={() => setShowSuccessToast(false)}
        />
      )}
      {showErrorToast && (
        <CustomToast
          type="danger"
          message={toastMessage}
          onClose={() => setShowErrorToast(false)}
        />
      )}
    </div >
  )


}
