import React from "react";
import {
  Row,
  Col,
} from "reactstrap";

function Dashboard() {
  return (
    <>
      <div className="content">
        <Row style={{ justifyContent: 'center' }}>
          <Col className="text-center mx-5 mt-3 py-5" md="8" style={{ alignItems: 'center' }}>
            <h2 className="mb-1" >Bienvenido</h2>
            <p className="mb-0">Portal de administración de postgrados CUNORI.</p>
            <hr className="mt-2" />
            <div className="mt-5" />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
