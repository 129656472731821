import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, FormFeedback, FormGroup, Input, Modal, Row } from 'reactstrap'
import { changeInscripcionToAll } from 'redux/actions/inscripciones.actions';
import { habilitarInscripcionEnPrograma } from 'redux/actions/inscripciones.actions';
import { getProgramasByInscription } from 'redux/actions/programa.actions';
import { isObjEmpty } from 'utils/helpers';

export const ModalAddProgramaInscripcion = ({showModal, setShowModal, setProgramasCallback}) => {

  const [ programas, setProgramas ] = useState( [] );
  const [ programaId, setProgramaId ] = useState( "default");
  const [ errors, setErrors ] = useState( [] );
  const [ response, setResponse ] = useState({});
  const [ handleButtons, setHandleButtons ] = useState("default");

  useEffect( ()=>{
    getProgramas();
  }, [])


  const getProgramas = ( ) => {
    getProgramasByInscription( false )
    .then( (res) => {
      if( res.data.programas.length < 1){
        setProgramaId("empty");
      }
      setProgramas( res.data.programas)
    })

  }

  const handleAll = ( activeInscripcion ) => {

    if( activeInscripcion=== true && programas.length < 1) {
      setResponse( { type: "warning", message: "No hay carreras o todas están habilitadas"});
      return null;
    }

    changeInscripcionToAll( activeInscripcion )
    .then( res => {
      setResponse( { type: "success", message: res.data.message });
      setProgramaId("default");
      getProgramas();
      setProgramasCallback();
    }).catch( err => setResponse( { type: "danger", message: err.response.data.message }))
  }

  const handleSingle = ()=>{
    setErrors({});
    setResponse({});

    const errores = checkErrorsSingle();
    if( !isObjEmpty( errores ) ){
      setErrors(errores);
      setResponse({ loading: false })
      return;
    }

    habilitarInscripcionEnPrograma( programaId, true )
    .then( res => {
      setResponse({ type: "success", message: res.data.message });
      getProgramas();
      setProgramasCallback();
    }).catch( err => console.log(err))
  }

  const checkErrorsSingle = ()=>{
    const errores = {};
    if( programaId === "default" ){
      errores.programaId = "Selecciona una carrera.";
    }
    return errores;
  }

  return (
    <>
    <Modal className="modal-dialog-centered "
        size="lg"
        isOpen={showModal}
        toggle={() => setShowModal(false)}>

      <Card>
        <CardHeader>
          <Row className="mx-1 mt-2">
            <Col md="12">
              <CardTitle  tag="h6">Habilitar Inscripción en una Carrera</CardTitle>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="mx-2 my-0 py-0" >
          <Col md="12">
            { response.message && 
              <Alert color={ response.type}>{ response.message }</Alert>}
            <FormGroup className="mb-0">
              <label>SELECCIONA UNA CARRERA PARA HABILITAR INSCRIPCIÓN.<label className="text-warning m-0"><strong>*</strong></label></label>
              <Input
                className="text-dark "
                placeholder="Selecciona la carrera a la que pertenecera el curso."
                name="programaId"
                value={ programaId }
                onChange={ (e) => setProgramaId( e.target.value ) }
                invalid={ !! errors.programaId }
                type="select"
              >
                <option key="default" value="default" disabled hidden>Selecciona la carrera.</option>
                <option key="empty" value="empty" disabled hidden>No hay carreras o todas tiene la inscripción habilitada.</option>

                  {
                      programas.map( p => <option key={p.id} value={p.id}>{p.codigo}. {' '}  {p.nombre}</option>)
                  }
              </Input>
              <FormFeedback>
                { errors.programaId }
              </FormFeedback>
            </FormGroup>
            
            <Button 
              color="success" 
              size="sm"
              disabled={ programaId === "default" || programaId === "empty"  ? true : false }
              onClick={()=> handleSingle()}>
                Habilitar
            </Button> 

          </Col>
        </CardBody>

        <strong><hr className="text-danger"/></strong>
        <Row>
          <Col md="6">
  
        <CardHeader className=" mt-2 py-0">
          <Row className="mx-1 mt-2">
            <Col md="12">
              <CardTitle tag="h6">Habilitar Todas las Carreras </CardTitle>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="mx-2 my-0 py-0" >
          <Col md="12">
            <p className="mb-2">Habilitar la inscripción en todas las carreras del sistema.</p>
            <Button
                onClick={()=>setHandleButtons("button1")}
                size="sm"
                color="primary">
                Habilitar Todas
            </Button>
          </Col>
        </CardBody>
        
        {
          handleButtons === "button1" && 
            <CardFooter className="ml-3 text-left">
              <Button color="primary" onClick={()=>handleAll(true)}>
                CONFIRMAR
              </Button>
            </CardFooter>
        }
      </Col>
      <Col md="6">  
        <CardHeader className=" mt-2 py-0">
          <Row className="mx-1 mt-2">
            <Col md="12">
              <CardTitle tag="h6">Deshabilitar Todas </CardTitle>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="mx-2 my-0 py-0" >
          <Col md="12">
            <p className="mb-2">Deshabilitar inscripción en todas las carreras del sistemas.</p>
          <Button 
            onClick={()=>setHandleButtons("button2")}
            color="danger" 
            size="sm">
              Deshabilitar Todas
          </Button> 
          </Col>
        </CardBody>
        {
          handleButtons === "button2" && 
            <CardFooter className="ml-3 text-left">
              <Button color="danger" onClick={()=>handleAll(false)}>
                CONFIRMAR
              </Button>
            </CardFooter>
        }
        </Col>
        </Row>
      </Card>
    </Modal>
    </>
  )
}
