import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/css/Actas.css';

function IngresarNotasView({
  carreras,
  cursos,
  ciclos,
  secciones,
  selectedCarrera,
  selectedCurso,
  selectedCiclo,
  selectedSeccion,
  estudiantes,
  docente,
  limpiarHabilitado,
  generarNotas,
  handleCarreraChange,
  handleCursoChange,
  handleCicloChange,
  handleSeccionChange,
  handleCargarEstudiantesNotas,
  limpiarCampos,
  handleEditar,
  handleConfirmarCambios,
  handleInputChange,
  estudiantesEditando,
  guardarCambiosHabilitado,
  handleGuardarNotas,
  setDocente,
  selectedCursoText,
  notas,
  selectedNota,
  handleNotaChange
}) {

  // Función para determinar si el curso es un "SEMINARIO"
  const esSeminario = selectedCursoText.toLowerCase().includes('seminario');
  return (
    <div className="content">
      <Card>
        <CardHeader>
          <CardTitle tag="h5">Obtener información del curso</CardTitle>
        </CardHeader>
        <CardBody className="pb-1">
          <Form>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>{'CARRERA  '}<label className="text-warning m-0"><strong>*</strong></label></label>
                  <br></br>
                  <Input
                    className="text-dark w-100"
                    placeholder="Nombre de la carrera"
                    type="select"
                    name="carrera"
                    value={selectedCarrera}
                    onChange={handleCarreraChange}
                    required>
                    <option value="">Seleccione una carrera</option>
                    {carreras.map((carrera) => (
                      <option key={carrera.id} value={carrera.id}>
                        {carrera.nombre}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label>{'CURSO  '}<label className="text-warning m-0"><strong>*</strong></label></label>
                  <br></br>
                  <Input
                    className="text-dark w-100"
                    placeholder="Nombre deL curso"
                    type="select"
                    name="curso"
                    value={selectedCurso}
                    onChange={handleCursoChange}
                    required>
                    <option value="">Seleccione un curso</option>
                    {cursos.map((curso) => (
                      <option key={curso.id} value={curso.id}>
                        {curso.nombre}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>{'CICLO  '}<label className="text-warning m-0"><strong>*</strong></label></label>
                  <br></br>
                  <Input
                    className="text-dark w-100"
                    placeholder="Seleccionar un ciclo"
                    type="select"
                    name="ciclo"
                    value={selectedCiclo}
                    onChange={handleCicloChange}
                    required>
                    <option value="">Seleccione un ciclo</option>
                    {ciclos.map((ciclo) => (
                      <option key={ciclo} value={ciclo}>
                        {ciclo}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label>{'SECCIÓN  '}<label className="text-warning m-0"><strong>*</strong></label></label>
                  <br></br>
                  <Input
                    className="text-dark w-100"
                    placeholder="Seleccionar una seccion"
                    type="select"
                    name="seccion"
                    value={selectedSeccion}
                    onChange={handleSeccionChange}
                    required>
                    <option value="">Seleccione una sección</option>
                    {secciones.map((seccion) => (
                      <option key={seccion} value={seccion}>
                        {seccion}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>{'DOCENTE  '}<label className="text-warning m-0"><strong>*</strong></label></label>
                  <br></br>
                  <Input
                    className="text-dark w-100"
                    type="text"
                    name="docente"
                    value={docente}
                    onChange={(e) => setDocente(e.target.value.toUpperCase())}
                    required />
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup className="d-flex justify-content-center align-items-center">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleCargarEstudiantesNotas}
                    disabled={!selectedCarrera || !selectedCurso || !selectedCiclo || !selectedSeccion}>
                    Cargar datos
                  </button>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={limpiarCampos}
                    disabled={!limpiarHabilitado}>
                    Limpiar
                  </button>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleGuardarNotas}
                    disabled={!generarNotas}>
                    Guardar Notas
                  </button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>

      <Card>
        <CardBody className="pb-1">
          <div>
            {/* Aquí puedes mostrar la tabla con la información de estudiantes y notas */}
            {estudiantes.length > 0 && (
              <div className="actas-table-responsive">
                <div className="estudiantes-notas text-center">
                  <h2 >Estudiantes y Notas</h2>
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>DPI</th>
                        <th>Carné</th>
                        <th>Apellidos y Nombres</th>
                        <th>Nota</th>
                      </tr>
                    </thead>
                    <tbody>
                      {estudiantes.map((estudiante, index) => (
                        <tr key={estudiante.id}>

                          {/* Renderizado condicional de inputs o valores según el estado de edición */}
                          <td>
                            {estudiante.dpi}
                          </td>
                          <td>
                            {estudiante.carne}
                          </td>
                          <td>
                            {estudiante.nombre}
                          </td>
                          <td>
                            {esSeminario ? (
                              // Si es un SEMINARIO
                              estudiantesEditando[index] ? (
                                // Si está en modo edición, mostrar ComboBox
                                <Input
                                  type='select'
                                  value={estudiante.nota} // Usar la nota del estudiante como valor
                                  onChange={(e) => handleNotaChange(index, e.target.value)} // Pasar el índice y el valor seleccionado
                                >
                                  <option value="">Seleccione una nota</option>
                                  {notas.map((note) => (
                                    <option key={note} value={note}>
                                      {note}
                                    </option>
                                  ))}
                                </Input>
                              ) : (
                                // Si no está en modo edición, mostrar la nota como texto
                                estudiante.nota
                              )
                            ) : (
                              // Si no es un SEMINARIO
                              estudiantesEditando[index] ? (
                                // Si está en modo edición, mostrar input numérico
                                <Input
                                  type="number"
                                  placeholder="Ingresar nota"
                                  min={0}
                                  max={100}
                                  inputMode='numeric'
                                  value={estudiante.nota}
                                  onChange={(e) => handleInputChange(index, 'nota', e.target.value.toString())}
                                />
                              ) : (
                                // Si no está en modo edición, mostrar la nota como texto
                                estudiante.nota
                              )
                            )}
                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </CardBody>
      </Card>

    </div>
  );
}

export default IngresarNotasView;
