import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader, CardTitle, Col,  Row } from 'reactstrap'
import { getPrograma } from 'redux/actions/programa.actions';
import { ModalEditarPrograma } from './ModalEditarPrograma';
import { ProgramaCursos } from './ProgramaCursos';
import { ProgramaEstudiantes } from './ProgramaEstudiantes';

export const DetalleCarrera = () => {
  const location = useLocation(); // Para acceder a la ruta completa

  //const { id } = useParams();
  const parts = location.pathname.split('/');
  const id = parts[parts.length - 1];
  const [ programa, setPrograma ] = useState({});
  const [ showModalEditar, setShowModalEditar ] = useState( false );
  

  useEffect(() => {
      getPrograma( id )
      .then( res => setPrograma( res.data.programa ))
      .catch( err => console.log( err ));
  }, [id])
    

  return (
    <div className="content">
        <Row>
          <Col md="12">
            <Card className="" >
              <CardHeader style={{
                backgroundColor: '#efefef',
                borderColor: '#f7f7f7'
             }}>
                
                <Row>
                     <Col xs="6" sm="6" md="8">
                        <CardTitle className="p-0" tag="h5">{ programa.nombre }</CardTitle>                   
                     </Col>
                     <Col className="text-right"xs="6" sm="6" md="4">
                        <Button size="sm" color="success" onClick={()=>setShowModalEditar( true )}>
                            Editar 
                        </Button>
                     </Col>
                 </Row>
              </CardHeader>
              <CardBody>
                <label> 
                    <h6>Código: {programa.codigo}</h6>
                    <h6>Prefijo: {programa.prefijo}</h6>
                    <h6>Descripción: {programa.descripcion}</h6>
                    <h6>Años (Ciclos Académicos): {programa.ciclos}</h6>
                    <h6>Unidad: {programa.unidad}</h6>
                    <h6>Extensión: {programa.extension}</h6>
                </label>
              </CardBody>
            </Card>
          </Col>
        </Row>
        
       <ProgramaCursos programa={programa} />
       <ProgramaEstudiantes programa={programa}/>

       { showModalEditar && 
        <ModalEditarPrograma
          programaId= {programa.id} 
          programaPrev={programa}
          showModal={showModalEditar}
          setShowModal={ setShowModalEditar }/>
       }
    </div>
  )
}
