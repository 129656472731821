import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { GENERAR_CERTIFICACION } from 'utils/endpoints';
import GenerarCertificacionView from './GenerarCertificacionView';
import CustomToast from '../../CustomToast';
import { GenerarCertificacion } from './generarCertificacion';
let carnets = [];
let dpis = [];
let creditos = [];
let codCursos = [];

function GenerarCertificacionLogic() {
    const [carreras, setCarreras] = useState([]);
    const [selectedCarrera, setSelectedCarrera] = useState('');
    const [estudiantes, setEstudiantes] = useState([]);
    const [selectedEstudiante, setSelectedEstudiante] = useState('');
    const [carnet, setCarnet] = useState('');
    const [DPI, setDPI] = useState('');
    const [cursos, setCursos] = useState([]);
    const [coordinador, setCoordinador] = useState('');
    const [director, setDirector] = useState('');
    //Extraer los textos de los input
    const [selectedCarreraText, setSelectedCarreraText] = useState('');
    const [selectedEstudianteText, setSelectedEstudianteText] = useState('');
    const [carnetText, setCarnetText] = useState('');
    const [DPIText, setDPIText] = useState('');
    //Notificaciones
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [showWarningToast, setShowWarningToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    //Estados de los botones
    const [cargaHabilitada, setCargaHabilitada] = useState(false);
    const [limpiarHabilitado, setLimpiarHabilitado] = useState(false);
    const [generarCertificacionHabilitado, setGenerarCertificacionHabilitado] = useState(false);
    const [guardarCambiosHabilitado, setGuardarCambiosHabilitado] = useState(false);

    const [fechaText, setFechaText] = useState(null);
    const [diaLetras, setDiaLetras] = useState('');
    const [mesLetras, setMesLetras] = useState('');
    const [anioLetras, setAnioLetras] = useState('');

    const [notas, setNotas] = useState([]);
    const [selectedNota, setSelectedNota] = useState('');
    const [selectedNotaText, setSelectedNotaText] = useState('');
    //const esSeminario = selectedCursoText.toLowerCase().includes('seminario');
    const [cursosEditando, setCursosEditando] = useState({}); // Estado para mantener el estado de edición de los cursos

    //let estudiantesOrdenados = [];

    // Función para mostrar la notificación de error
    const showErrorNotification = (message) => {
        setToastMessage(message);
        setShowErrorToast(true);

        // Oculta la notificación después de un cierto tiempo
        setTimeout(() => {
            setShowErrorToast(false);
        }, 3000); // Puedes ajustar el tiempo en milisegundos
    };

    // Función para mostrar la notificación de éxito
    const showSuccessNotification = (message) => {
        setToastMessage(message);
        setShowSuccessToast(true);

        // Oculta la notificación después de un cierto tiempo
        setTimeout(() => {
            setShowSuccessToast(false);
        }, 3000); // Puedes ajustar el tiempo en milisegundos
    };

    // Función para mostrar la notificación de advertencia
    const showWarningNotification = (message) => {
        setToastMessage(message);
        setShowWarningToast(true);
        // Oculta la notificación después de un cierto tiempo
        setTimeout(() => {
            setShowWarningToast(false);
        }, 3000); // Puedes ajustar el tiempo en milisegundos
    };

    const obtenerNotas = async () => {
        return ['APROBADO', 'REPROBADO'];
    }

    const obtenerCarreras = async () => {
        try {
            const response = await axios.get(`${GENERAR_CERTIFICACION}/carreras`);
            return response.data;
        } catch (error) {
            showErrorNotification('Error al obtener carreras.');
            console.error('Error al obtener carreras:', error);
            throw error;
        }
    }

    const cargarCarreras = useCallback(async () => {
        try {
            const carrerasData = await obtenerCarreras();
            setCarreras(carrerasData);
        } catch (error) {
            showErrorNotification('Error al cargar carreras.');
            console.error('Error al cargar carreras:', error);
        }
    }, []);

    const obtenerEstudiantes = async (carreraId) => {
        try {
            const response = await axios.get(`${GENERAR_CERTIFICACION}/estudiantes-carrera`, {
                params: { carreraId },
            });
            return response.data;
        } catch (error) {
            showErrorNotification('Error al obtener estudiantes.');
            console.error('Error al obtener estudiantes:', error);
            throw error;
        }
    }

    const obtenerCursosNotas = async (estudianteNombre) => {
        //console.log("Entro a obtenerCursosNotas: " + estudianteNombre);
        try {
            const response = await axios.get(`${GENERAR_CERTIFICACION}/cursos-notas-estudiante`, {
                params: { estudianteNombre },
            });
            return response.data;
        } catch (error) {
            showErrorNotification('Error al obtener cursos y notas.');
            console.error('Error al obtener cursos y notas:', error);
            throw error;
        }
    }

    const cargarNotas = useCallback(async () => {
        const notasData = await obtenerNotas();
        setNotas(notasData);
    }, []);

    const cargarEstudiantes = async (carreraId) => {
        try {
            const studentsData = await obtenerEstudiantes(carreraId);
            // Ordenar los estudiantes alfabéticamente por nombre
            const estudiantesOrdenados = studentsData.sort((a, b) =>
                a.nombre.localeCompare(b.nombre));

            // Extraer los carnets y DPIs de los estudiantes
            carnets = estudiantesOrdenados.map((student) => student.carnet || '');
            dpis = estudiantesOrdenados.map((student) => student.dpi || '');

            setEstudiantes(estudiantesOrdenados);
        } catch (error) {
            showErrorNotification('Error al cargar estudiantes.');
            console.error('Error al cargar estudiantes:', error);
        }
    };

    useEffect(() => {
        cargarCarreras();
        cargarNotas();
        //cargarEstudiantes();
    }, [cargarCarreras, cargarNotas]);

    const handleCarreraChange = (event) => {
        const carreraId = event.target.value;
        const selectedText = event.target.options[event.target.selectedIndex].text; // Obtener el texto seleccionado
        setSelectedCarrera(carreraId);
        setSelectedEstudiante('');
        setSelectedCarreraText(selectedText); // Actualizar el estado del texto seleccionado
        cargarEstudiantes(carreraId);
    };

    const handleStudentsChange = (event) => {
        const indexStudent = event.target.selectedIndex;
        const studentId = event.target.value;
        const selectedText = event.target.options[event.target.selectedIndex].text; // Obtener el texto seleccionado
        setSelectedEstudiante(studentId);
        setSelectedEstudianteText(selectedText); // Actualizar el estado del texto seleccionado

        //console.log(indexStudent);
        //console.log(selectedText);

        setCarnet(carnets[indexStudent - 1]);
        setCarnetText(carnets[indexStudent - 1]);
        //console.log(carnets);
        //console.log(carnets[indexStudent - 1]);

        setDPI(dpis[indexStudent - 1]);
        setDPIText(dpis[indexStudent - 1]);
        //console.log(dpis);
        //console.log(dpis[indexStudent - 1]);
    };

    const handleEditar = (index) => {
        // Actualiza el estado cursosEditando para habilitar la edición del curso en el índice
        setCursosEditando({ ...cursosEditando, [index]: true });
    };

    const handleConfirmarCambios = (index) => {
        // Actualiza el estado cursosEditando para deshabilitar la edición del curso en el índice
        setCursosEditando({ ...cursosEditando, [index]: false });
    };

    //Función para actualizar las notas de los cursos en la certificación
    const handleModificarNotaNumerica = (index, campo, valor) => {
        const cursosActualizados = cursos.map((curso, i) =>
        i === index ? { ...curso, [campo]: valor, notaEnLetras: numeroEnLetras(Number(valor)) } : curso
        );
        setCursos(cursosActualizados);
    };

    const handleModificarNotaSeminario = (index, value) => {
        const cursosActualizados = [...cursos]; // Clonar el array de cursos
        cursosActualizados[index].notaEnLetras = value; // Actualizar la nota del cursos en el índice específico
        setCursos(cursosActualizados); // Actualizar el estado de los cursos
        setSelectedNota(value); // Asegúrate de actualizar el valor de selectedNota
    };

    const handleCargarCursosNotas = async () => {
        const nombreEstudianteNormalizado = selectedEstudianteText.trim();
        if (nombreEstudianteNormalizado) {
            try {
                const cursosNotasData = await obtenerCursosNotas(nombreEstudianteNormalizado);

                // Filtrar los cursos válidos (notas >= 61 o "APROBADO" en seminarios)
                const cursosValidos = cursosNotasData.filter((curso) => {
                    if (curso.nombreCurso.includes("SEMINARIO")) {
                        return curso.notaCurso === "APROBADO";
                    } else {
                        return Number(curso.notaCurso) >= 61;
                    }
                });

                // Ordenar los cursos por fecha de aprobación
                cursosValidos.sort((a, b) => {
                    // Divide las fechas en sus partes: día, mes y año
                    const fechaPartesA = a.fechaModificacion.split('/');
                    const fechaPartesB = b.fechaModificacion.split('/');

                    // Construye objetos Date con las fechas separadas
                    const fechaA = new Date(fechaPartesA[2], fechaPartesA[1] - 1, fechaPartesA[0]);
                    const fechaB = new Date(fechaPartesB[2], fechaPartesB[1] - 1, fechaPartesB[0]);

                    // Compara las fechas y ordena de menor a mayor
                    return fechaA - fechaB;
                });


                // Validar y procesar los datos antes de establecerlos en el estado
                const cursosProcesados = cursosValidos.map((curso) => {
                    const codigoCurso = curso.codigoCurso;
                    const nombreCurso = curso.nombreCurso;
                    const notaNumerica = curso.nombreCurso.includes("SEMINARIO") ? "-" : curso.notaCurso;
                    const notaEnLetras = curso.nombreCurso.includes("SEMINARIO") ? curso.notaCurso : numeroEnLetras(Number(curso.notaCurso));
                    const fechaModificacion = curso.fechaModificacion;
                    const creditoCurso = curso.creditoCurso; // Agrega esta línea para obtener los créditos

                    // Guarda los créditos en el arreglo 'creditos'
                    creditos.push(creditoCurso);
                    codCursos.push(codigoCurso);
                    return {
                        codigoCurso,
                        nombreCurso,
                        notaNumerica,
                        notaEnLetras,
                        fechaModificacion,
                        creditoCurso,
                    };
                });
                //console.log("Creditos:", creditos);
                //console.log("Codigo curso:", codCursos);

                // Crear un objeto cursosEditando con todos los cursos habilitados para edición
                const cursosEditandoInicial = cursosProcesados.reduce((obj, dato, index) => {
                    obj[index] = false;
                    return obj;
                }, {});

                setCursos(cursosProcesados);
                setCursosEditando(cursosEditandoInicial); // Habilitar edición para todos los cursos
                //Habilitar los botones
                setLimpiarHabilitado(true);
                setGenerarCertificacionHabilitado(true);
            } catch (error) {
                showErrorNotification('Error al cargar cursos y notas.');
                console.error('Error al cargar cursos y notas:', error);
            }
        }
    };

    const handleGuardarNotas = async () => {
        try {
            const data = {
                estudianteDPI: DPIText,
                cursos: cursos.map((curso) => ({
                  codigoCurso: curso.codigoCurso,
                  notaNumerica: curso.notaNumerica,
                  notaEnLetras: curso.notaEnLetras, // Asegúrate de incluir la nota en letras actualizada
                })),
              };
              //console.log('Arreglo enviado para guardar:', JSON.stringify(data, null, 2));

          const response = await axios.post(`${GENERAR_CERTIFICACION}/actualizar-notas`, {data});
          setCursosEditando({}); // Limpiar los estados de edición
          setGuardarCambiosHabilitado(false); // Deshabilitar el botón de guardar cambios
    
          // Mostrar la notificación de éxito
          showSuccessNotification('¡Las notas se guardaron correctamente!');
          //console.log('Datos guardados en la base de datos:', response.data);
          //Refrescar los datos para que se actualicen las notas
          handleCargarCursosNotas();
        } catch (error) {
          showErrorNotification('Error al guardar notas.');
          console.error('Error al guardar notas:', error);
        }
      };

    // Función para convertir números a letras
    function numeroEnLetras(numero) {
        const unidades = ['', 'UNO', 'DOS', 'TRES', 'CUATRO', 'CINCO', 'SEIS', 'SIETE', 'OCHO', 'NUEVE'];
        const especiales = ['', 'ONCE', 'DOCE', 'TRECE', 'CATORCE', 'QUINCE', 'DIECISEIS', 'DIECISIETE', 'DIECIOCHO', 'DIECINUEVE'];
        const decenas = ['', 'DIEZ', 'VEINTE', 'TREINTA', 'CUARENTA', 'CINCUENTA', 'SESENTA', 'SETENTA', 'OCHENTA', 'NOVENTA'];

        //console.log("Nota recibida para convertir a letras: " + numero);
        let letra = '';

        if (numero === 100) {
            letra= "CIEN";
        } else if (numero < 10) {
            letra = unidades[numero];
        } else if (numero >= 11 && numero <= 19) {
            letra = especiales[numero - 10];
        } else if (numero % 10 === 0) {
            letra = decenas[numero / 10];
        } else if (numero > 20 && numero < 30) {
            letra = 'VEINTI' + unidades[numero % 10];
        } else {
            const decena = Math.floor(numero / 10);
            const unidad = numero % 10;
            letra = decenas[decena];
            if (unidad > 0) {
                letra += ' Y ' + unidades[unidad];
            }
        }
        //console.log("Nota convertida a letras: " + letra);
        return letra;
        
    }

    const limpiarCampos = () => {
        setSelectedCarrera('');
        setSelectedEstudiante('');
        setCarnet('');
        setDPI('');
        setCursos([]);
        // Deshabilitar los botones
        setCargaHabilitada(false);
        setLimpiarHabilitado(false);
        setGenerarCertificacionHabilitado(false);
	    creditos.length = 0;
    };

    function diaEnLetras(numero) {
        const dias = ['', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve', 'diez',
            'once', 'doce', 'trece', 'catorce', 'quince', 'dieciséis', 'diecisiete', 'dieciocho', 'diecinueve',
            'veinte', 'veintiuno', 'veintidós', 'veintitrés', 'veinticuatro', 'veinticinco', 'veintiséis', 'veintisiete',
            'veintiocho', 'veintinueve', 'treinta', 'treinta y uno'];

        if (numero >= 1 && numero <= 31) {
            return dias[numero];
        } else {
            return 'día inválido';
        }
    }

    function mesEnLetras(numero) {
        const meses = ['', 'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto',
            'septiembre', 'octubre', 'noviembre', 'diciembre'];

        if (numero >= 1 && numero <= 12) {
            return meses[numero];
        } else {
            return 'mes inválido';
        }
    }

    function anioEnLetras(numero) {
        if (numero >= 2000 && numero < 2100) {
            const anios = ['', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve'];
            const decadas = ['', 'diez', 'veinte', 'treinta', 'cuarenta', 'cincuenta', 'sesenta', 'setenta', 'ochenta', 'noventa'];
            const aniosEspeciales = ['', 'veintitrés', 'veinticuatro', 'veinticinco', 'veintiséis', 'veintisiete', 'veintiocho', 'veintinueve'];

            const decena = Math.floor((numero - 2000) / 10);
            const unidad = (numero - 2000) % 10;

            if (numero >= 2023 && numero <= 2029) {
                return `dos mil ${aniosEspeciales[numero - 2023 + 1]}`;
            }

            let anioEnLetras = 'dos mil';

            if (decena > 0) {
                anioEnLetras += ` ${decadas[decena]}`;
                if (unidad > 0) {
                    anioEnLetras += ` y ${anios[unidad]}`;
                }
            } else if (unidad > 0) {
                anioEnLetras += ` ${anios[unidad]}`;
            }

            return anioEnLetras;
        } else {
            return 'año inválido';
        }
    }



    function fechaEnLetras(fecha) {
        const fechaParts = fecha.split('/'); // Divide la fecha en sus partes: día, mes y año
        if (fechaParts.length === 3) {
            const dia = parseInt(fechaParts[0], 10); // Convierte el día a número
            const mes = parseInt(fechaParts[1], 10); // Convierte el mes a número
            const anio = parseInt(fechaParts[2], 10); // Convierte el año a número

            // Convierte cada parte de la fecha a letras utilizando las funciones correspondientes
            const diaEnLetrasResultado = diaEnLetras(dia);
            const mesEnLetrasResultado = mesEnLetras(mes);
            const anioEnLetrasResultado = anioEnLetras(anio);

            // Asigna los resultados a los estados correspondientes
            setDiaLetras(diaEnLetrasResultado);
            setMesLetras(mesEnLetrasResultado);
            setAnioLetras(anioEnLetrasResultado);

            //console.log("Día: " + diaEnLetrasResultado);
            //console.log("Mes: " + mesEnLetrasResultado);
            //console.log("Año: " + anioEnLetrasResultado);

            return { diaEnLetras: diaEnLetrasResultado, mesEnLetras: mesEnLetrasResultado, anioEnLetras: anioEnLetrasResultado };
        } else {
            // Manejo de error en caso de que la fecha no tenga el formato esperado
            console.error('Formato de fecha incorrecto: ' + fecha);
            return null; // O devuelve algún valor por defecto o maneja el error de acuerdo a tu lógica
        }
    }

    const handleGenerarCertificacion = async () => {
        if (!coordinador && !director && !fechaText) {
            // Mostrar la notificación de error
            showWarningNotification('Todos los campos están vacíos.');
            console.error('Todos los campos están vacíos.');
            return;
        } else if (!coordinador && !director) {
            // Mostrar la notificación de error
            showWarningNotification('Los campos coordinador académico y director no pueden estar vacíos.');
            console.error('Los campos coordinador académico y director no pueden estar vacíos.');
            return;
        } else if (!coordinador && !fechaText) {
            // Mostrar la notificación de error
            showWarningNotification('Los campos coordinador académico y fecha no pueden estar vacíos.');
            console.error('Los campos coordinador académico y fecha no pueden estar vacíos.');
            return;
        } else if (!director && !fechaText) {
            // Mostrar la notificación de error
            showWarningNotification('Los campos director y fecha no pueden estar vacíos.');
            console.error('Los campos director y fecha no pueden estar vacíos.');
            return;
        } else if (!coordinador) {
            // Mostrar la notificación de error
            showWarningNotification('El campo coordinador académico no puede estar vacío.');
            console.error('El campo coordinador académico no puede estar vacío.');
            return;
        } else if (!director) {
            // Mostrar la notificación de error
            showWarningNotification('El campo director no puede estar vacío.');
            console.error('El campo director no puede estar vacío.');
            return;
        } else if (!fechaText) {
            // Mostrar la notificación de error
            showWarningNotification('El campo fecha no puede estar vacío.');
            console.error('El campo fecha no puede estar vacío.');
            return;
        }


        let sumaTotalCreditos = 0;
        if (cursos.length > 0 && fechaText) {
            if (creditos.length === 1) {
                //console.log("Solo hay un curso, no se calcularán los créditos.");
                sumaTotalCreditos = creditos[0];
            } else {
                // Utiliza el método reduce para sumar todos los créditos
                sumaTotalCreditos = creditos.reduce((acumulador, valorActual) => {
                    return acumulador + valorActual;
                }, 0); // El segundo argumento (0) es el valor inicial del acumulador
                //console.log("Suma total de créditos:", sumaTotalCreditos);
            }

            GenerarCertificacion(selectedEstudianteText, DPI, carnet, selectedCarreraText, cursos, diaLetras, mesLetras, anioLetras, sumaTotalCreditos, coordinador, director);
            // Vacía el arreglo de créditos para usos posteriores
            //creditos = [];
            creditos.length = 0;
        }
    };


    return (
        <div className="content">
            <GenerarCertificacionView
                carreras={carreras}
                selectedCarrera={selectedCarrera}
                handleCarreraChange={handleCarreraChange}

                estudiantes={estudiantes}
                selectedEstudiante={selectedEstudiante}
                handleStudentsChange={handleStudentsChange}

                carnet={carnet}
                DPI={DPI}
                coordinador={coordinador}
                setCoordinador={setCoordinador}
                director={director}
                setDirector={setDirector}

                cursos={cursos}
                handleCargarCursosNotas={handleCargarCursosNotas}
                numeroEnLetras={numeroEnLetras}

                cargaHabilitada={cargaHabilitada}
                limpiarHabilitado={limpiarHabilitado}
                generarCertificacionHabilitado={generarCertificacionHabilitado}
                limpiarCampos={limpiarCampos}
                handleGenerarCertificacion={handleGenerarCertificacion}

                fechaText={fechaText}
                setFechaText={setFechaText}
                fechaEnLetras={fechaEnLetras}
                diaLetras={diaLetras}
                mesLetras={mesLetras}
                anioLetras={anioLetras}
                setDiaLetras={setDiaLetras}
                setMesLetras={setMesLetras}
                setAnioLetras={setAnioLetras}

                handleModificarNotaNumerica={handleModificarNotaNumerica}
                handleModificarNotaSeminario={handleModificarNotaSeminario}
                notas={notas}
                cursosEditando={cursosEditando}
                handleEditar={handleEditar}
                handleConfirmarCambios={handleConfirmarCambios}
                handleGuardarNotas={handleGuardarNotas}

            />
            {/* Renderizar el Toast de éxito cuando sea necesario */}
            {showSuccessToast && (
                <CustomToast
                    type="success"
                    message={toastMessage}
                    onClose={() => setShowSuccessToast(false)}
                />
            )}

            {/* Renderizar el Toast de advertencia cuando sea necesario */}
            {showWarningToast && (
                <CustomToast
                    type="warning"
                    message={toastMessage}
                    onClose={() => setShowWarningToast(false)}
                />
            )}

            {/* Renderizar el Toast de advertencia cuando sea necesario */}
            {showErrorToast && (
                <CustomToast
                    type="danger"
                    message={toastMessage}
                    onClose={() => setShowErrorToast(false)}
                />
            )}
        </div>
    );
};

export default GenerarCertificacionLogic;
