import React from 'react';
import { Button, Col, FormFeedback, FormGroup, Input, Row, Spinner } from 'reactstrap';
import { gradosAcademicos } from 'utils/gradosAcademicos';

export const FormDataEstudiante = ({ dispositions ={}, student, onChange, onClick, errors, loading }) => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col md="6">
          <FormGroup>
            <label className="text-muted">NOMBRE COMPLETO <label className="text-danger"><strong className="pb-0">*</strong></label></label>
            <Input className="text-dark" name="nombre" value={student.nombre || ''} onChange={onChange} invalid={!!errors.nombre}/>
            <FormFeedback>{ errors.nombre }</FormFeedback>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label className="text-muted">DPI/PASAPORTE (ÚNICO) <label className="text-danger"><strong className="pb-0">*</strong></label></label>
            <Input className="text-dark" name="dpi" value={student.dpi || ''} onChange={onChange} invalid={!!errors.dpi} disabled={ dispositions.dpi?.disabled}/>
            <FormFeedback>{ errors.dpi }</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="6">
          <FormGroup>
            <label className="text-muted"> <label className="text-muted">CARNET  (ÚNICO)</label></label>
            <Input className="text-dark" name="carnet" value={student.carnet || ''} onChange={onChange} disabled={ dispositions.carnet?.disabled}/>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label className="text-muted">CORREO</label>
            <Input className="text-dark" name="email" placeholder="Correo electrónico."
              value={student.email || ''} onChange={onChange} invalid={!!errors.email}/>
            <FormFeedback>{ errors.email }</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <FormGroup>
            <label className="text-muted">TELÉFONO</label>
            <Input className="text-dark" name="telefono" type='text' placeholder="Número teléfonico personal."
              value={student.telefono || ''} onChange={onChange} invalid={!!errors.telefono}/>
            <FormFeedback>{ errors.telefono }</FormFeedback>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <label className="text-muted">WHATSAPP</label>
            <Input className="text-dark" name="whatsapp" type='text' placeholder="Número teléfonico asociado a Whatsapp."
              value={student.whatsapp || ''} onChange={onChange} invalid={!!errors.whatsapp}/>
            <FormFeedback>{ errors.whatsapp }</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <FormGroup>
            <label className="text-muted">DIRECCIÓN</label>
            <Input className="text-dark" name="direccion" value={student.direccion || ''} onChange={onChange} invalid={!!errors.direccion}/>
            <FormFeedback>{ errors.direccion }</FormFeedback>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <label className="text-muted">GRADO ACADÉMICO</label>
            <Input className="text-dark" name="grado" value={student.grado || 'default'} onChange={onChange}  type="select">
              <option key="default" value="default" hidden disabled>Selecciona tu grado académico.</option>
              {
                  gradosAcademicos.map( g => <option key={g.value} value={g.nombre}>{g.nombre}</option>)
              }
            </Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <label className="text-muted">UNIVERSIDAD DE LICENCIATURA</label>
            <Input className="text-dark" name="universidad" value={student.universidad || ''} onChange={onChange}/>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mr-1" style={{ justifyContent: 'flex-end' }}>
        { loading && <Spinner className="mr-2"/> }
        <Button 
              onClick={onClick}
              color="primary" 
              size="sm" 
              style={{backgroundColor:"#31708f"}}>
            Guardar
        </Button>
      </Row>
    </React.Fragment>
  )
}
