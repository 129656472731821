const URL_API = process.env.REACT_APP_API_URL || '/api';

export const LOGIN = URL_API + "/auth/login";

//Usuarios
export const CREATE_ADMIN = URL_API + "/admin/";
export const GET_ADMINS = URL_API + "/admin";

//Estudiantes
export const CREATE_STUDENT = URL_API + "/student";
export const GET_STUDENT = URL_API + "/student/get/";
export const SEARCH_STUDENT_BY_CARNET_DPI = URL_API + "/student/search";
export const GET_STUDENT_BY_USERNAME = URL_API + "/student/username/"
export const UPDATE_STUDENT = URL_API + "/student/update";
export const UPDATE_STUDENT_DPI = URL_API + "/student/dpi/update";
export const UPLOAD_STUDENTS_BY_CSV = URL_API + "/student/upload/csv";

//Programas
export const CREATE_PROGRAMA = URL_API + "/programa";
export const GET_PROGRAMA = URL_API + "/programa";
export const GET_PROGRAMAS = URL_API + "/programa/all";
export const UPDATE_PROGRAMA = URL_API + "/programa/update/";

//Programas e Inscripciones
export const GET_PROGRAMAS_BY_INSCRIPCION = URL_API + "/programa/inscripcion";
export const HABILITAR_INSCRIPCION_EN_PROGRAMA = URL_API + "/programa/inscripcion/";
export const CHANGE_INSCRIPCION_TO_ALL = URL_API + "/programa/inscripcion";

//Inscripciones
export const GET_LAST_INSCRIPCION_ESTUDIANTE_BY_PROGRAMA = URL_API + "/inscripcion/last";
export const INSCRIBIR_ESTUDIANTE = URL_API + "/inscripcion/save";
export const GET_INSCRITOS_EN_PROGRAMA = URL_API + "/inscripcion/programa/inscritos";
export const GET_EXCEL_INSCRITOS_EN_PROGRAMA = URL_API + "/inscripcion/excel/inscritos";

//Programas y Asignaciones
export const GET_PROGRAMAS_BY_ASIGNACION = URL_API + "/programa/asignacion";

//Asignaciones
export const CREATE_PROCESO_ASIGNACION = URL_API + "/asignacion";
export const SEARCH_PROCESO_ASIGNACION = URL_API + "/asignacion/search";
export const GET_LAST_PROCESOS_ASIGNACION = URL_API + "/asignacion/proceso/last";
export const GET_PROCESOS_BY_YEAR_PROGRAMAID = URL_API + "/asignacion/proceso/year";
export const ASIGNAR_CURSOS = URL_API + "/asignacion/cursos/asignar";
export const GET_CURSOS_ASIGNABLES_BY_PROCESO = URL_API + "/asignacion/cursos/asignables";
export const GET_PROCESO_CURSOS_DISPONIBLES_Y_ASIGNADOS = URL_API + "/asignacion/cursos/disponibles";
export const CREATE_CURSO_ASIGNABLE = URL_API + "/asignacion/curso/asignable";
export const CHANGE_DATE_PROCESO = URL_API + "/asignacion/proceso/endAt";
export const GET_PROCESOS_BY_PROGRAMA = URL_API + "/asignacion/procesos";
export const GET_EXCEL_ASIGNADOS_BY_PROGRAMA = URL_API + "/asignacion/excel/estudiantes/asignados";
export const GET_ASIGNADOS_BY_PROGRAMA = URL_API + "/asignacion/estudiantes/asignados";
export const DESASIGNAR_ESTUDIANTE_EN_CURSO = URL_API + "/asignacion/estudiante/desasignar";

//Programas y Estudiantes
export const ASOCIAR_ESTUDIANTE_PROGRAMA = URL_API + "/programa/add/student";

//Curso
export const CREATE_CURSO = URL_API + "/curso";
export const GET_CURSO = URL_API + "/curso/get/";
export const UPDATE_CURSO = URL_API + "/curso/";
export const SEARCH_CURSO = URL_API + "/curso/buscar";

//Curso y Programas
export const GET_CURSOS_IN_PROGRAMA = URL_API + "/curso/programa/"; 

//Preinscripcion 
export const GET_PREINSCRIPCION_CONFIG = URL_API + "/preinscripcion/ajustes/form";
export const CHANGE_PREINSCRIPCION_ACTIVE = URL_API + "/preinscripcion/ajustes/form";//Cambia si se muestra o no el formulario
export const SAVE_RESPUESTA_PREINSCRIPCION = URL_API + "/preinscripcion/respuesta";// guarda respusta enviada desde el formulario
export const GET_RESPUESTAS_BY_PROGRAMA = URL_API + "/preinscripcion/respuestas";
export const GET_EXCEL_PREINSCRIPCION = URL_API + "/preinscripcion/excel/respuestas";
export const DELETE_RESPUESTAS_BY_PROGRAMA_DATES = URL_API + "/preinscripcion/delete/programa/";
export const DELETE_ALL_RESPUESTAS = URL_API + "/preinscripcion/delete/all";
export const GET_LAST_RESPUESTAS = URL_API + "/preinscripcion/last/respuestas";

//Programa y Preinscripcion
export const CHANGE_ACTIVE_PREINSCRIPCION_IN_PROGRAMAS = URL_API + "/programa/preinscripcion";

//Solicitudes
export const CREATE_SOLICITUD = URL_API + "/solicitud/create";
export const GET_SOLICITUDES_BY_STATUS = URL_API + "/solicitud/estado/";
export const GET_SOLICITUD = URL_API + "/solicitud/get/";
export const UPDATE_SOLICITUD = URL_API + "/solicitud/update/";
export const GET_SOLICITUDES_BY_STUDENT = URL_API + "/solicitud/estudiante/";
export const DELETE_SOLICITUD = URL_API + "/solicitud/delete/";
export const DELETE_ATENDIDAS = URL_API + "/solicitud/atendidas/delete";
export const DELETE_ATENDIDAS_BY_STUDENT = URL_API + "/solicitud/student/delete/";

//Endpoints de los nuevos modulos (2023)
export const SUBIR_ACTA = URL_API + "/subir-acta";
export const GENERAR_ACTA = URL_API + "/generar-acta";
export const INGRESAR_NOTA = URL_API + "/ingresar-nota";
export const GENERAR_CERTIFICACION = URL_API + "/generar-certificacion";
export const ENVIAR_CORREOS = URL_API + "/correos";
export const INFO_CURSOS = URL_API + "/info-cursos";