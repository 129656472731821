import axios from "axios"
import { ASOCIAR_ESTUDIANTE_PROGRAMA } from "utils/endpoints"
import { GET_PROGRAMAS_BY_INSCRIPCION } from "utils/endpoints"
import { UPDATE_PROGRAMA } from "utils/endpoints"
import { GET_PROGRAMA } from "utils/endpoints"
import { GET_PROGRAMAS } from "utils/endpoints"
import { CREATE_PROGRAMA } from "utils/endpoints"

export const createPrograma = ( body ) =>{
    return new Promise( (resolve, reject) => {
        axios.post( CREATE_PROGRAMA, body)
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
}

export const updatePrograma = ( id, body ) => {
    delete body.id;
    return new Promise( (resolve, reject) => {
        axios.patch( UPDATE_PROGRAMA + id, body )
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
}

export const getPrograma = ( idPrograma ) => {
    return new Promise( (resolve, reject ) => {
        axios.get( GET_PROGRAMA, { 
            params: { 
                id: idPrograma 
            } 
        })
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
}

export const getProgramas = () => {
    return new Promise( (resolve, reject ) => {
        axios.get( GET_PROGRAMAS )
        .then( res => resolve(res))
        .catch( err => reject(err) )
    })
} 

export const getProgramasByInscription = (activeInscripcion) => {
    return new Promise( (resolve, reject ) => {
        axios.get( GET_PROGRAMAS_BY_INSCRIPCION, { 
            params: { 
                activeInscripcion
            } 
        })
        .then( res => resolve(res))
        .catch( err => reject(err) )
    })
} 


export const asociarEstudianteAPrograma = ( body ) => {
    return new Promise( (resolve, reject ) => {
        axios.post( ASOCIAR_ESTUDIANTE_PROGRAMA, body )
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
}