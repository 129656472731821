import axios from 'axios';
import { setTokenRequest } from 'utils/setTokenRequest';
import { LOGIN } from '../../utils/endpoints';
import types from '../types/types';
import { setCurrentStudent } from './student.actions';

export const startLogin = ( userData ) => dispatch => {
    return new Promise( (resolve, reject) => {
        axios.post( LOGIN, userData, {
            headers: { 'Content-type': 'application/json'}
        }).then( response => {
            const {username, role, token } = response.data;
            localStorage.setItem('token', token);
            setTokenRequest(token);
            const loggedIn = true;
            dispatch( setCurrentUser({ username, role, loggedIn }))
            resolve( true );
        }).catch( error => {
            reject(error);
        })
    })
}


export const setCurrentUser = ({username, role, loggedIn }) => {
    return ({
        type: types.authSetUser,
        payload: {
            checking: false,
            loggedIn,
            user: { username, role}
        }
    })
}


export const logoutUser = () => dispatch =>{
    localStorage.removeItem('token');
    setTokenRequest(false);
    dispatch( setCurrentUser({ username: "", role: " ", loggedIn: false}));
}

export const logoutStudent = () => dispatch => {
    dispatch(logoutUser());
    dispatch( setCurrentStudent({
        fetched: false,
        student: {},
        programas_inscrito: [] }) );
}