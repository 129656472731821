import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, FormFeedback, Input, Row } from 'reactstrap';
import { getProgramas } from 'redux/actions/programa.actions';
import { NuevoProcesoForm } from './NuevoProcesoForm';
import { ResumenProceso } from './ResumenProceso';

export const NuevoProceso = () => {

  const [ programaId, setProgramaId ] = useState("default");
  const [ programas, setProgramas ] = useState([]);
  const [ programaSelected, setProgramaSelected ] = useState({});
  const [ response, setResponse ] = useState({});
  const [ errors, setErrors ] = useState({});
  const [ showForm, setShowForm ] = useState(false);
  const [ disabledButton, setDisabledButton ] = useState(false);
  const [ resultado, setResultado ] = useState({ ready: false });
  const [ fallo, setFallo ] = useState({ fail: false });

  useEffect( ()=>{
      traerProgramas();
  }, [])


  const traerProgramas = ()=>{
    getProgramas()
    .then( res=> setProgramas( res.data.programas ))
    .catch( err => console.log(err))
  }

  const handleButton = () => {
    setErrors({});
    setResponse({});
    if( programaId === "default"){
      setErrors( {programaId : "Selecciona una carrera para continuar."} );
      return ;
    }else{
        const selectedProgram = programas.find( p => p.id.toString() === programaId );
        setProgramaSelected(selectedProgram);
        setShowForm(true);
        setDisabledButton(true);
    }
  }

  const mostrarResultado = (resultado) => {
      setResultado({ ...resultado, ready: true });
      setShowForm(false)
  }


  return (
    <div className="content">
      <h6>Carrera</h6>
      <Row className="mb-3">
        <Col xs="12" sm="12" md="6">
            
          { response.message && <Alert color={response.type}>lol {response.message}</Alert>}
          <Row style={{ alignItems: 'center'}}>    
            <Col xs="8" md="10">  
              <Input
                disabled={disabledButton}
                value={ programaId }
                onChange={ (e) => setProgramaId( e.target.value )}
                type="select"
                invalid={  (!!errors.programaId && programaId==="default") }>
                  <option key="default" value="default" disabled hidden>Selecciona la carrera.</option>
                  { 
                    programas.map( p => 
                      <option key={p.id} value={p.id}>{p.codigo} - {p.nombre}</option>
                    )
                  }
              </Input>
              <FormFeedback>
                { errors.programaId }
              </FormFeedback>
            </Col>
              <Col xs="4" md="2" className="pl-0">
                <Button
                  disabled={disabledButton}
                  style={{ backgroundColor:"#31708f"}} 
                  className="ml-0 p-2" 
                  color="primary"
                  onClick={()=>handleButton()}>
                      Continuar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        
        {
            fallo.fail && <Alert color={fallo.type}>{fallo.message}</Alert>
        }
        
        { showForm &&
            <NuevoProcesoForm 
                setFallo={setFallo}
                mostrarResultado={mostrarResultado}
                programaNombre={programaSelected.nombre} 
                programaId={programaId} />
        }
        {
            resultado.ready &&
            <ResumenProceso
                title={"Nuevo Proceso Creado"}
                proceso={resultado.procesoAsignacion}
                nombrePrograma={resultado.programaNombre}
                cantidadCursos={resultado.cursosAsignables.length}/>
        }
        
    </div>
  )
}
