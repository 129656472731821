import ExcelJS from 'exceljs';
import { imgBase64 } from './logoBase64';

export const generarArchivoExcel = async (estudiantes, selectedCarreraText, selectedCursoText, selectedCicloText, selectedSeccionText, docenteText, fechaText) => {
  if (estudiantes.length > 0) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Estudiantes Notas');
    let filaActual = 16;
    let numero = 0;
    let cantidad0a70 = 0;
    let cantidad71a100 = 0;
    let cantidadAprobado = 0;
    let cantidadReprobado = 0;
    let cantidadSeminario = 0;
    let cantidadNSP = 0;
    //Define el estilo de borde
    const borderStyle = {
      style: 'thin', // Puedes cambiar el estilo del borde (thin, medium, thick, dashed, etc.)
      color: { argb: '000000' } // Código de color en formato ARGB (hexadecimal)
    };

    const imageBase64 = imgBase64;
    const base64Data = imageBase64.split(',')[1];
    const imageType = imageBase64.split(',')[0].split(':')[1].split(';')[0].split('/')[1];

    // Inserta la imagen en la celda A1
    const imageX = workbook.addImage({
      base64: base64Data,
      extension: imageType,
    });
    worksheet.addImage(imageX, {
      tl: { col: 0.25, row: 0 },
      ext: { width: 82, height: 83 }
    });


    // Agregar datos iniciales con estilo de celda personalizado
    const styleHeader = {
      alignment: { horizontal: 'center', vertical: 'middle' },
      font: { size: 12, bold: true, name: 'Arial' }
    };

    const styleInfoCurso = {
      alignment: { horizontal: 'left', vertical: 'middle' },
      font: { size: 12, bold: true, name: 'Arial' }
    };

    const styleTexto1 = {
      alignment: { horizontal: 'center', vertical: 'middle' },
      font: { size: 12, bold: true, name: 'Arial' }
    };

    const styleTexto2 = {
      alignment: { horizontal: 'left', vertical: 'middle' },
      font: { size: 12, bold: false, name: 'Arial' }
    };

    const styleTexto3 = {
      alignment: { horizontal: 'left', vertical: 'middle' },
      font: { size: 10, bold: false, name: 'Arial' }
    };

    const styleTexto4 = {
      alignment: { horizontal: 'center', vertical: 'middle' },
      font: { size: 10, bold: false, name: 'Arial' }
    };

    const styleTotal = {
      alignment: { horizontal: 'center', vertical: 'middle' },
      font: { size: 12, bold: false, name: 'Arial' },
      border: { top: borderStyle }
    };

    const styleDocente = {
      alignment: { horizontal: 'center', vertical: 'middle' },
      font: { size: 11, bold: false, name: 'Arial' },
      border: { top: borderStyle }
    };

    const styleNombreStudent = {
      alignment: { wrapText: true, vertical: 'middle', horizontal: 'left' },
      font: { size: 10, bold: false, name: 'Arial' },
      border: {
        top: borderStyle,
        bottom: borderStyle,
        left: borderStyle,
        right: borderStyle
      }
    };

    const styleVoBo = {
      alignment: { horizontal: 'center', vertical: 'middle' },
      font: { size: 10, bold: false, name: 'Arial' }
    };

    const styleTextoDocente = {
      alignment: { horizontal: 'center', vertical: 'middle' },
      font: { size: 12, bold: false, name: 'Arial' }
    };



    const cellRange1 = worksheet.getCell('A1'); // Obtener la celda en la esquina superior izquierda del rango
    cellRange1.value = 'UNIVERSIDAD DE SAN CARLOS DE GUATEMALA'; // Contenido del título
    cellRange1.style = styleHeader;
    worksheet.mergeCells('A1:F1'); // Combinar las celdas del rango

    const cellRange2 = worksheet.getCell('A2');
    cellRange2.value = 'CENTRO UNIVERSITARIO ORIENTE';
    cellRange2.style = styleHeader;
    worksheet.mergeCells('A2:F2');

    const cellRange3 = worksheet.getCell('A3');
    cellRange3.value = 'DEPARTAMENTO DE ESTUDIOS DE POSTGRADO';
    cellRange3.style = styleHeader;
    worksheet.mergeCells('A3:F3');

    const cellRange4 = worksheet.getCell('A4');
    cellRange4.value = 'PROGRAMA: ' + selectedCarreraText;
    cellRange4.style = styleHeader;
    worksheet.mergeCells('A4:F4');

    const cellRange5 = worksheet.getCell('A6');
    cellRange5.value = 'ACTA DE FIN DE CURSO';
    cellRange5.style = styleHeader;
    worksheet.mergeCells('A6:F6');

    const cur = worksheet.getCell('A8');
    cur.value = 'CURSO:';
    cur.style = styleTexto2;

    const cic = worksheet.getCell('A9');
    cic.value = 'CICLO:';
    cic.style = styleTexto2;

    const sec = worksheet.getCell('A10');
    sec.value = 'SECCIÓN:';
    sec.style = styleTexto2;

    const doc = worksheet.getCell('A11');
    doc.value = 'DOCENTE:';
    doc.style = styleTexto2;

    // Ajustar el ancho de la primera columna
    worksheet.getColumn('A').width = 12.5;
    worksheet.getColumn('B').width = 20;
    worksheet.getColumn('C').width = 14;
    worksheet.getColumn('D').width = 35;
    worksheet.getColumn('E').width = 14;
    worksheet.getColumn('F').width = 12;
    worksheet.getColumn('G').width = 16;

    const curso = worksheet.getCell('B8');
    curso.value = selectedCursoText;
    curso.style = styleInfoCurso;
    worksheet.mergeCells('B8:F8');

    const ciclo = worksheet.getCell('B9');
    ciclo.value = selectedCicloText;
    ciclo.style = styleInfoCurso;
    worksheet.mergeCells('B9:F9');

    const seccion = worksheet.getCell('B10');
    seccion.value = selectedSeccionText;
    seccion.style = styleInfoCurso;
    worksheet.mergeCells('B10:F10');

    const docente = worksheet.getCell('B11');
    docente.value = docenteText;
    docente.style = styleInfoCurso;
    worksheet.mergeCells('B11:F11');

    const a = worksheet.getCell('A16');
    a.value = 'No.';
    a.style = styleTexto1;

    const b = worksheet.getCell('B16');
    b.value = 'DPI';
    b.style = styleTexto1;

    const c = worksheet.getCell('C16');
    c.value = 'CARNÉ';
    c.style = styleTexto1;

    const d = worksheet.getCell('D16');
    d.value = 'APELLIDOS Y NOMBRES';
    d.style = styleTexto1;

    const e = worksheet.getCell('E16');
    e.value = 'NOTA';
    e.style = styleTexto1;

    let maxNombreLength = 0;
    estudiantes.forEach((estudiante) => {
      const nombreLength = estudiante.nombre.length;
      if (nombreLength > maxNombreLength) {
        maxNombreLength = nombreLength;
      }
    });

    const l1 = worksheet.getCell('A13');
    l1.value = 'EL PROFESOR EXAMINADOR CERTIFICA POR LA PRESENTE, HABER EXAMINADO LOS  CURSANTES';
    l1.style = styleTexto3;
    worksheet.mergeCells('A13:E13');

    const l2 = worksheet.getCell('A14');
    l2.value = 'REGULARES  CUYOS CARNES, NOMBRES Y CALIFICACIONES SE EXPRESAN A CONTINUACIÓN:';
    l2.style = styleTexto3;
    worksheet.mergeCells('A14:E14');

    // Agregar datos de estudiantes
    estudiantes.forEach((estudiante, index) => {
      const rowNumber = index + 17; //Ajustar por el encabezado en la fila 16
      numero++;
      // Convertir DPI y carné a números (asegúrate de que los valores sean numéricos)
      //const dpi = parseInt(estudiante.dpi);
      //const carne = parseInt(estudiante.carne);
      worksheet.getCell(`A${rowNumber}`).value = numero;
      worksheet.getCell(`A${rowNumber}`).style = styleTexto4;
      worksheet.getCell(`B${rowNumber}`).value = estudiante.dpi;
      worksheet.getCell(`B${rowNumber}`).style = styleTexto4;
      worksheet.getCell(`C${rowNumber}`).value = estudiante.carne;
      worksheet.getCell(`C${rowNumber}`).style = styleTexto4;
      worksheet.getCell(`D${rowNumber}`).value = estudiante.nombre;
      worksheet.getCell(`D${rowNumber}`).style = styleNombreStudent;
      worksheet.getCell(`E${rowNumber}`).value = estudiante.nota;
      worksheet.getCell(`E${rowNumber}`).style = styleTexto4;
      /*
      // Obtener la nota del estudiante
      const nota = parseInt(estudiante.nota);

      // Verificar el rango de notas
      if (nota >= 0 && nota <= 70) {
        cantidad0a70++;
      } else if (nota >= 71 && nota <= 100) {
        cantidad71a100++;
      }

      // Verificar los estados
      if (estudiante.nota === "APROBADO") {
        cantidadAprobado++;
      } else if (estudiante.nota === "REPROBADO") {
        cantidadReprobado++;
      } else if (estudiante.nota === "SEMINARIO") {
        cantidadSeminario++;
      } else if (estudiante.nota === "NSP") {
        cantidadNSP++;
      }*/

      // Obtener la nota del estudiante
      const nota = parseInt(estudiante.nota);

      // Verificar los estados
      if (estudiante.nota === "APROBADO") {
        cantidadAprobado++;
      } else if (estudiante.nota === "REPROBADO") {
        cantidadReprobado++;
      } else if (estudiante.nota === "NSP") {
        cantidadNSP++;
      } else {
        // Verificar el rango de notas para aquellos que no tienen un estado específico
        if (nota >= 0 && nota <= 70) {
          // Si la nota está en el rango 0-70, considerarlo "REPROBADO"
          cantidadReprobado++;
        } else if (nota >= 71 && nota <= 100) {
          // Si la nota está en el rango 71-100, considerarlo "APROBADO"
          cantidadAprobado++;
        }
      }

      filaActual++;
      //console.log("Numero de fila actual: "+filaActual);
    });

    // Luego de procesar a todos los estudiantes, puedes mostrar o utilizar las cantidades como desees.
    //console.log("Cantidad de estudiantes en el rango 0-70:", cantidad0a70);
    //console.log("Cantidad de estudiantes en el rango 71-100:", cantidad71a100);
    //console.log("Cantidad de estudiantes APROBADOS:", cantidadAprobado);
    //console.log("Cantidad de estudiantes REPROBADOS:", cantidadReprobado);
    //console.log("Cantidad de estudiantes SEMINARIO:", cantidadSeminario);
    //console.log("Cantidad de estudiantes NSP:", cantidadNSP);

    // Obtén el rango de celdas al que deseas aplicar los bordes
    const startRow = 16; // La primera fila de los estudiantes
    const endRow = filaActual; // La última fila de los estudiantes
    const startColumn = 'A';
    const endColumn = 'E';
    // Aplica el estilo de borde a las celdas en el rango
    for (let row = startRow; row <= endRow; row++) {
      for (let col = startColumn; col <= endColumn; col++) {
        const cell = worksheet.getCell(`${col}${row}`);
        cell.border = {
          top: borderStyle,
          bottom: borderStyle,
          left: borderStyle,
          right: borderStyle
        };
      }
    }

    worksheet.addRow([]);
    filaActual++;
    filaActual++;
    const l3 = worksheet.getCell(`A${filaActual}`);
    l3.value = 'SUSCRIBO LA PRESENTE ACTA EN ORDEN DE CARNES, NOMBRES EN QUE FIGURAN LAS RESPECTIVAS';
    l3.style = styleTexto3;
    //worksheet.mergeCells(`A${filaActual}:E${filaActual}`); 
    filaActual++;
    const l4 = worksheet.getCell(`A${filaActual}`);
    l4.value = 'CALIFICACIONES, EN LA CIUDAD DE CHIQUIMULA EL ' + fechaText;
    l4.style = styleTexto3;
    //worksheet.mergeCells(`A${filaActual}:E${filaActual}`); 
    //console.log("Numero de fila actual: "+filaActual);

    worksheet.addRow([]);
    filaActual++;
    filaActual++;
    const res = worksheet.getCell(`A${filaActual}`);
    res.value = 'RESUMEN:';
    res.style = styleTexto2;

    const apr1 = worksheet.getCell(`C${filaActual}`);
    apr1.value = 'APROBADOS';
    apr1.style = styleTexto2;

    const apr2 = worksheet.getCell(`D${filaActual}`);
    apr2.value = cantidad0a70 !== 0 ? cantidad0a70 : cantidadAprobado;
    apr2.style = styleTextoDocente;

    filaActual++;
    const rep1 = worksheet.getCell(`C${filaActual}`);
    rep1.value = 'REPROBADOS';
    rep1.style = styleTexto2;

    const rep2 = worksheet.getCell(`D${filaActual}`);
    rep2.value = cantidad71a100 !== 0 ? cantidad71a100 : cantidadReprobado;
    rep2.style = styleTextoDocente;

    filaActual++;
    const nsp1 = worksheet.getCell(`C${filaActual}`);
    nsp1.value = 'NSP';
    nsp1.style = styleTexto2;

    const nsp2 = worksheet.getCell(`D${filaActual}`);
    nsp2.value = cantidadNSP;
    nsp2.style = styleTextoDocente;


    filaActual++;
    const total1 = worksheet.getCell(`C${filaActual}`);
    total1.value = 'TOTAL';
    total1.style = styleTotal;
    const total2 = worksheet.getCell(`D${filaActual}`);
    total2.value = apr2.value + rep2.value + nsp2.value;
    total2.style = styleTotal;
    //console.log("Numero de fila actual: "+filaActual);

    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    filaActual++;
    filaActual++;
    filaActual++;
    filaActual++;

    const line = worksheet.getCell(`E${filaActual}`);
    line.value = '------------------------------------------------------------------------';
    line.style = styleTexto3;
    worksheet.mergeCells(`E${filaActual}:G${filaActual}`);
    //console.log("Numero de fila actual: "+filaActual);

    filaActual++;
    const vobo = worksheet.getCell(`E${filaActual}`);
    vobo.value = 'Vo.Bo. Mario Roberto Díaz Moscoso';
    vobo.style = styleVoBo;
    worksheet.mergeCells(`E${filaActual}:G${filaActual}`);
    //console.log("Numero de fila actual: "+filaActual);

    filaActual++;
    const profesor = worksheet.getCell(`A${filaActual}`);
    profesor.value = docenteText;
    profesor.style = styleDocente;
    worksheet.mergeCells(`A${filaActual}:C${filaActual}`);
    //console.log("Numero de fila actual: "+filaActual);

    const director = worksheet.getCell(`E${filaActual}`);
    director.value = 'Director';
    director.style = styleVoBo;
    worksheet.mergeCells(`E${filaActual}:G${filaActual}`);
    //console.log("Numero de fila actual: "+filaActual);

    filaActual++;
    const docen = worksheet.getCell(`A${filaActual}`);
    docen.value = 'Docente';
    docen.style = styleTextoDocente;
    worksheet.mergeCells(`A${filaActual}:C${filaActual}`);
    //console.log("Numero de fila actual: "+filaActual);

    const depto = worksheet.getCell(`E${filaActual}`);
    depto.value = 'Departamento de Estudios de Posgrado';
    depto.style = styleVoBo;
    worksheet.mergeCells(`E${filaActual}:G${filaActual}`);
    //console.log("Numero de fila actual: "+filaActual);

    filaActual++;
    const cunori = worksheet.getCell(`E${filaActual}`);
    cunori.value = 'Centro Univeristario de Oriente.';
    cunori.style = styleVoBo;
    worksheet.mergeCells(`E${filaActual}:G${filaActual}`);
    //console.log("Numero de fila actual: "+filaActual);

    // Establece el área de impresión en función de tu rango de celdas
    //worksheet.pageSetup.printArea = `A1:G${filaActual}`;

    // Establece la configuración de la página para que se ajuste a una sola página
    worksheet.pageSetup.fitToPage = true;
    worksheet.pageSetup.fitToWidth = 1; // 1 es el número de páginas de ancho
    worksheet.pageSetup.fitToHeight = 0; // 0 significa que se ajustará a la altura automáticamente

    // Generar archivo Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);

    // Crear un enlace para descargar el archivo
    const link = document.createElement('a');
    link.href = url;
    link.download = selectedCursoText + ' ' + selectedSeccionText + '.xlsx';
    link.click();
    window.URL.revokeObjectURL(url);
  }
};
