import { AjustesForm } from 'components/preinscripciones/AjustesForm';
import { Respuestas } from 'components/preinscripciones/Respuestas';
import React, { useState } from 'react';
import { Button, Card, CardHeader, Col, Row } from 'reactstrap';

export const Preinscripciones = () => {
  
  const [ activeBtnRespuestas, setActiveBtnRespuestas ] = useState(true);  
  const [ activeBtnAjustes, setActiveBtnAjustes ] = useState(false);

  const styleButton = {
    borderRadius: 0,
    backgroundColor: "#f8f9fa",
    color: "#344767",
    "&:hover": {
        background: "#efefef"
    },
  }

  const onClick = ( e )=>{
    const { name } = e.target;
    if( name === "respuestas" ){
        setActiveBtnRespuestas( prev => !prev);
        setActiveBtnAjustes( false );
    }else if( name === "ajustes"){
        setActiveBtnAjustes( prev => !prev);
        setActiveBtnRespuestas( false );
    } 
  }

  return (
    <div className="content">
      <Row>
        <Col>
          <Card>
            <CardHeader >
              <Row style={{ justifyContent: 'center'}}>
                  
                <Button color="primary" className="mx-0" 
                    name="respuestas" onClick={onClick}
                    style={styleButton } active={ activeBtnRespuestas }>Respuestas</Button>
                <Button color="primary" className="mx-0"
                    name="ajustes" onClick={onClick} 
                    style={styleButton } active={ activeBtnAjustes }>Configurar</Button>
              </Row>
            </CardHeader>
          </Card>
        </Col>
      </Row>
      { activeBtnRespuestas && <Respuestas/>}
      { activeBtnAjustes &&  <AjustesForm/> }
      
    </div>
  )
}
