import React, { useState } from 'react'
import {Button, Card, CardHeader, CardBody, Col, Modal, Row, CardTitle, Alert, Spinner } from 'reactstrap';
import { deleteSolicitud } from 'redux/actions/solicitud.actions';

export const ModalConfirmarEliminar= ({showModal, setShowModal, solicitudId }) => {

  const [ response, setResponse ] = useState({});

  const handleDelete = ()=>{
    setResponse({ loading: true });
    deleteSolicitud(solicitudId)
    .then( res => setResponse({ type: "success", message: res.data.message }))
    .catch( err => setResponse( {type: "danger", message: err.response.data.message }));
  }

  return (
    <Modal className="modal-dialog-centered "
      size="md"
      isOpen={showModal}
      toggle={() => setShowModal(false)}>

      <Card className="mx-2">
        <CardHeader>
          <CardTitle>
            <h6 className="text-danger"> ¿Estás seguro de eliminar esta solicitud?</h6>
          </CardTitle>
        </CardHeader>
        <CardBody className="mx-4">
          { response.message && <Alert color={response.type}>{ response.message }</Alert>}
            <p>Se eliminará este registro de la base de datos.</p>
            <Row>
              <Col md="12" className="text-right">
                { response.loading && <Spinner/>}
              </Col>
              <Col md="12" className="text-right">
                  <Button color="danger" size="sm"
                  onClick={()=>handleDelete()}>Confirmar</Button>
              </Col>
            </Row>
        </CardBody>
      </Card>
    </Modal>
  )
}
