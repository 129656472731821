import axios from "axios"
import types from "redux/types/types"
import { UPDATE_STUDENT } from "utils/endpoints"
import { UPLOAD_STUDENTS_BY_CSV } from "utils/endpoints"
import { UPDATE_STUDENT_DPI } from "utils/endpoints"
import { GET_STUDENT } from "utils/endpoints"
import { GET_STUDENT_BY_USERNAME } from "utils/endpoints"
import { SEARCH_STUDENT_BY_CARNET_DPI } from "utils/endpoints"
import { CREATE_STUDENT } from "utils/endpoints"

export const createStudent = ( data ) => {
    return new Promise( (resolve, reject) => {
        axios.post( CREATE_STUDENT, data )
        .then( res =>resolve( res ))
        .catch( err => reject( err ))
    })
}

export const getStudent = ( id ) => {
    return new Promise( (resolve, reject) => {
        axios.get( GET_STUDENT + id )
        .then( res => resolve(res))
        .catch( err => reject( err ))
    }  )
}

export const updateDPIStudent = ( body ) => {
    return new Promise( (resolve, reject) => {
        axios.patch( UPDATE_STUDENT_DPI, body )
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
};

export const updateStudent = ( body ) => {
    return new Promise( (resolve, reject) => {
        axios.patch( UPDATE_STUDENT, body )
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
};

export const getStudentByUsername = ( username ) => {
    return new Promise( (resolve, reject ) => {
        axios.get( GET_STUDENT_BY_USERNAME + username )
        .then( res => resolve(res))
        .catch( err => reject(err))
    })
}

export const initStudent = ( username ) => dispatch =>{
    return new Promise( (resolve, reject ) => {
        getStudentByUsername( username )
        .then( res => {
            const { programas_inscrito } = res.data.student;
            const { programas_inscrito: omitted, ...student } = res.data.student;
            dispatch( setCurrentStudent( {fetched:true, student, programas_inscrito} ));
            resolve( true );
        })
        .catch( err => reject(err))
    })
}

export const setCurrentStudent = ( data ) =>{
    const { fetched, student, programas_inscrito } = data;
    return ({
        type: types.setStudent,
        payload: {
            fetched,
            student,
            programas_inscrito,
        }
    })
}

export const searchStudentByCarnetOrDPI = ( keysearch ) => {
    return new Promise( (resolve, reject) => {
        axios.get( SEARCH_STUDENT_BY_CARNET_DPI, {
            params:{ keysearch }
        }).then( res => resolve(res))
        .catch( err => reject(err))
    })
}

export const uploadStudentsByCSV = ( formFile ) => {
    return new Promise( (resolve, reject ) =>{
        axios.post( UPLOAD_STUDENTS_BY_CSV, formFile, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then( res => resolve(res))
        .catch( err => reject(err))
    })
}