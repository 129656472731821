import React from "react";
import { Row, Col } from "reactstrap";
import BackupDownload from "../components/Backup/DownloadBackup";
import BackupUpload from "../components/Backup/UploadBackup";

function PageBackup() {
  return (
    <div className="content">
      <Row style={{ justifyContent: "center" }}>
        <Col className="text-center mx-5 mt-3 py-5" md="8" style={{ alignItems: "center" }}>
          <div>
            <h1>Gestión de Copias de Seguridad</h1>
            <BackupDownload />
            <hr></hr>
            <BackupUpload />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default PageBackup;
