import React from 'react';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/css/Actas.css';

function ActasView({
  archivos,
  datosActa,
  datosCargados,
  estudiantesEditando,
  inputRef, SubirActas,
  insertarActa,
  handleEditar,
  handleConfirmarCambios,
  handleInputChange,
  limpiarVista,
  guardarCambios,
  cargarArchivosHabilitado,
  guardarCambiosHabilitado,
  limpiarHabilitado }) {
  return (
    <div className="content">
      <Card>
        <CardHeader>
          <CardTitle tag="h5">Sección para cargar las actas</CardTitle>
        </CardHeader>
        <CardBody className="actas-upload-section">
          {/* Ref para limpiar el input de tipo 'file' */}
          <input type="file" name="archivo" ref={inputRef} multiple onChange={SubirActas} accept=".xlsx"/>
          <button className="btn btn-primary" onClick={insertarActa} disabled={!cargarArchivosHabilitado}>
            Cargar Archivo
          </button>
          <button className="btn btn-primary" onClick={() => guardarCambios(datosActa)} disabled={!guardarCambiosHabilitado}>
            Guardar Cambios
          </button>
          <button className="btn btn-primary" onClick={limpiarVista} disabled={!limpiarHabilitado}>
            Limpiar
          </button>
        </CardBody>
      </Card>

      <div className="actas-content">
        {datosCargados && datosActa && (
          <>
            <div className="info-section">
              <h3>Información del Curso:</h3>
              <p>Curso: {datosActa.curso}</p>
              <p>Ciclo: {datosActa.ciclo}</p>
              <p>Sección: {datosActa.seccion}</p>
              <p>Docente: {datosActa.docente}</p>
            </div>

            <div className="actas-table-responsive">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>No.</th>
                    <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>DPI</th>
                    <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>Carné</th>
                    <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>Apellidos y Nombres</th>
                    <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>Nota</th>
                    <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {datosActa.estudiantes.map((estudiante, index) => (
                    <tr key={estudiante.numero}>
                      <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                        {estudiante.numero}
                      </td>
                      {/* Renderizado condicional de inputs o valores según el estado de edición */}
                      <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                        {
                          estudiante.dpi
                        }
                      </td>
                      <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                        {
                          estudiante.carne
                        }
                      </td>
                      <td>
                        {estudiantesEditando[index] ? (
                          <input
                            type="text"
                            value={estudiante.nombre}
                            onChange={(e) => handleInputChange(index, 'nombre', e.target.value.toUpperCase())}
                            style={{ width: '100%' }}
                          />
                        ) : (
                          estudiante.nombre
                        )}
                      </td>
                      <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                        {estudiantesEditando[index] ? (
                          <input
                            type="text"
                            value={estudiante.nota}
                            onChange={(e) => handleInputChange(index, 'nota', e.target.value.toUpperCase())}
                          />
                        ) : (
                          estudiante.nota
                        )}
                      </td>
                      <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                        {/* Renderizado condicional de botones de edición y confirmación */}
                        {estudiantesEditando[index] ? (
                          <>
                            <button className="btn btn-success btn-sm" onClick={() => handleConfirmarCambios(index)}>
                              <i className="fas fa-check"></i>
                            </button>
                          </>
                        ) : (
                          <button className="btn btn-primary btn-sm" onClick={() => handleEditar(index)}>
                            <i className="fas fa-edit"></i>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ActasView;
