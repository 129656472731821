import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Input, Row, Table } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { es } from 'date-fns/locale';
import moment from 'moment-timezone';

function GenerarCertificacionView({ carreras, selectedCarrera, handleCarreraChange, estudiantes, selectedEstudiante,
    handleStudentsChange, carnet, setCarnet, DPI, setDPI, handleCargarCursosNotas, cursos, numeroEnLetras, limpiarCampos,
    cargaHabilitada, limpiarHabilitado, generarCertificacionHabilitado, handleGenerarCertificacion, fechaText, setFechaText,
    fechaEnLetras, director, coordinador, setDirector, setCoordinador, handleModificarNotaNumerica, handleModificarNotaSeminario,
    notas, cursosEditando, handleEditar, handleConfirmarCambios, handleGuardarNotas }) {
    return (
        <div className="content">
            <Card>
                <CardHeader>
                    <CardTitle tag="h5">Obtener información del Estudiante</CardTitle>
                </CardHeader>
                <CardBody className="pb-1">
                    <Form>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <label>{'CARRERA  '}<label className="text-warning m-0"><strong>*</strong></label></label>
                                    <br></br>
                                    <Input
                                        className="text-dark w-100"
                                        placeholder="Nombre de la carrera"
                                        type="select"
                                        name="carrera"
                                        value={selectedCarrera}
                                        onChange={handleCarreraChange}
                                        required>
                                        <option value="">Seleccione una carrera</option>
                                        {carreras.map((carrera) => (
                                            <option key={carrera.id} value={carrera.id}>
                                                {carrera.nombre}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label>{'NOMBRE  '}<label className="text-warning m-0"><strong>*</strong></label></label>

                                    <Input
                                        className="text-dark w-100"
                                        placeholder="Nombre del estudiante"
                                        type="select"
                                        name="nombre"
                                        value={selectedEstudiante}
                                        onChange={handleStudentsChange}
                                        required
                                    >
                                        <option value="">Seleccione un estudiante</option>
                                        {estudiantes.map((student) => (
                                            <option key={student.id} value={student.id}>
                                                {student.nombre}
                                            </option>
                                        ))}

                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <label>{'CARNET  '}<label className="text-warning m-0"><strong>*</strong></label></label>
                                    <br></br>
                                    <Input
                                        className="text-dark w-100"
                                        placeholder="Carnet del estudiante"
                                        type="text"
                                        name="carnet"
                                        value={carnet}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label>{'DPI  '}<label className="text-warning m-0"><strong>*</strong></label></label>
                                    <br></br>
                                    <Input
                                        className="text-dark w-100"
                                        placeholder="DPI del estudiante"
                                        type="text"
                                        name="dpi"
                                        value={DPI}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <label>{'COORDINADOR ACADÉMICO  '}<label className="text-warning m-0"><strong>*</strong></label></label>
                                    <br></br>
                                    <Input
                                        className="text-dark w-100"
                                        placeholder="Coordinar Académico"
                                        type="text"
                                        name="coordinador"
                                        value={coordinador}
                                        onChange={(e) => setCoordinador(e.target.value.toUpperCase())}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label>{'DIRECTOR  '}<label className="text-warning m-0"><strong>*</strong></label></label>
                                    <br></br>
                                    <Input
                                        className="text-dark w-100"
                                        placeholder="Director"
                                        type="text"
                                        name="director"
                                        value={director}
                                        onChange={(e) => setDirector(e.target.value.toUpperCase())}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <label>FECHA</label>
                                    <br></br>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="(dd/mm/yyyy)"
                                        title="El formato de la fecha debe ser el dd/mm/yyyy"
                                        dateFormat="dd/MM/yyyy" // Formato de fecha personalizado
                                        selected={fechaText ? moment.tz(fechaText.split('/').reverse().join('-'), 'America/Guatemala').toDate() : null}
                                        onChange={(date) => {
                                            const formattedDate = date ? moment(date).tz('America/Guatemala').format('DD/MM/YYYY') : '';
                                            setFechaText(formattedDate);
                                            fechaEnLetras(formattedDate);
                                        }}
                                        locale={es}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="d-flex justify-content-center align-items-center">
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={handleCargarCursosNotas}
                                        disabled={!selectedCarrera || !selectedEstudiante}
                                    >
                                        Cargar datos
                                    </button>
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={limpiarCampos}
                                        disabled={!limpiarHabilitado}
                                    >
                                        Limpiar
                                    </button>
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={handleGenerarCertificacion}
                                        disabled={!generarCertificacionHabilitado}
                                    >
                                        Generar Certificación
                                    </button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
            <Card>
                <CardBody className="pb-1">
                    <div>
                        {/* Aquí puedes mostrar la tabla con la información de estudiantes y notas */}
                        {cursos.length > 0 && (
                            <div className="estudiantes-notas text-center">
                                <h2 >Cursos y Notas</h2>
                                <FormGroup className="d-flex justify-content-center align-items-center">
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={handleGuardarNotas}
                                        //disabled={!generarNotas}
                                        >
                                        Actualizar Notas
                                    </button>
                                </FormGroup>
                                <Table className="table table-bordered table-striped">
                                    <thead>
                                        <tr className="table-dark">
                                            <th>No.</th>
                                            <th>Código del Curso</th>
                                            <th>Nombre del Curso</th>
                                            <th>Calificación en Números</th>
                                            <th>Calificación en Letras</th>
                                            <th>Fecha de Aprobado</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cursos.map((curso, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{curso.codigoCurso}</td>
                                                <td>{curso.nombreCurso}</td>
                                                <td>
                                                    {!curso.nombreCurso.includes('SEMINARIO') && cursosEditando[index] ? (
                                                        <Input
                                                            type="number"
                                                            placeholder="Editar nota"
                                                            min={0}
                                                            max={100}
                                                            inputMode='numeric'
                                                            value={curso.notaNumerica}
                                                            onChange={(e) => handleModificarNotaNumerica(index, 'notaNumerica', e.target.value.toString())}
                                                        />
                                                    ) : (
                                                        curso.notaNumerica
                                                    )}
                                                </td>
                                                <td>
                                                    {curso.nombreCurso.includes('SEMINARIO') && cursosEditando[index] ? (
                                                        <Input
                                                            type='select'
                                                            value={curso.notaEnLetras}
                                                            onChange={(e) => handleModificarNotaSeminario(index, e.target.value)}
                                                        >
                                                            <option value="">Seleccione una nota</option>
                                                            {notas.map((note) => (
                                                                <option key={note} value={note}>
                                                                    {note}
                                                                </option>
                                                            ))}
                                                        </Input>
                                                    ) : (
                                                        curso.notaEnLetras
                                                    )}
                                                </td>
                                                <td>{curso.fechaModificacion}</td>

                                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                    {/* Renderizado condicional de botones de edición y confirmación */}
                                                    {cursosEditando[index] ? (
                                                        <>
                                                            <button className="btn btn-success btn-sm"
                                                                onClick={() => handleConfirmarCambios(index)}
                                                            >
                                                                <i className="fas fa-check"></i>
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <button className="btn btn-primary btn-sm"
                                                            onClick={() => handleEditar(index)}
                                                        >
                                                            <i className="fas fa-edit"></i>
                                                        </button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </Table>
                            </div>
                        )}
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}

export default GenerarCertificacionView;
