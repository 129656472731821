import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'

export const ProgramaCursos = ({programa}) => {


  return (
    <>
        <Row>
          <Col md="12">
            <Card className="" >
              <CardHeader style={{
                backgroundColor: '#efefef',
                borderColor: '#f7f7f7'
             }}>
                <CardTitle className="p-0" tag="h5">Cursos</CardTitle>
              </CardHeader>
              <CardBody className="ml-2">
                <label>
                <Link to={`/admin/programas/cursos/${programa.id}`}>
                  <h6 className="text-muted">Ver Todos</h6>
                </Link>
                </label>                   
              </CardBody>
            </Card>
          </Col>
        </Row>
    </>
  )
}
