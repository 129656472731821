import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router';
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import { getSolicitud } from 'redux/actions/solicitud.actions';
import moment from 'moment';
import { ModalConfirmarEliminar } from './ModalConfirmarEliminar';

export const SolicitudDetalleEstudiante = () => {

  const location = useLocation(); // Para acceder a la ruta completa

  //const { id } = useParams();
  const parts = location.pathname.split('/');
  const id = parts[parts.length - 1];
  const [solicitud, setSolicitud] = useState({});
  const [programa, setPrograma] = useState({});
  const [estudiante, setEstudiante] = useState({});
  // const [ respones, setResponse ] = useState({});
  const [showModalDelete, setShowModalDelete] = useState(false);
  // Agrega un estado para almacenar el texto del estado seleccionado
  const [selectedEstadoText, setSelectedEstadoText] = useState('');
  const isComentarioImpreso = ['IMPRESA', 'Impresa', 'impresa'].includes(solicitud.comentario);

  useEffect(() => {
    getSolicitud(id)
      .then(res => {
        const { programa, estudiante, ...resto } = res.data;
        //console.log('Respuesta del servidor:', res.data); 
        setPrograma(programa);
        setEstudiante(estudiante);
        setSolicitud(resto);
        // Recuperar el valor almacenado en el almacenamiento local
        const storedSelectedEstadoText = localStorage.getItem(`selectedEstadoText-${id}`);
        if (storedSelectedEstadoText) {
          setSelectedEstadoText(storedSelectedEstadoText|| '');
        }
        //console.log(`Status en respuesta: ', ${selectedEstadoText}`);
      }).catch(err => { console.log(err.response.data.message) })
  }, [id]);

  return (
    <div className="content">
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <h5>Detalle Solicitud</h5>
            </Col>
            <Col className="text-right mt-1">
              {/* Usa el estado selectedEstadoText en el Badge */}
              <Badge color={solicitud.status === 'REGISTRADA' ? 'danger' : 'info'}>
                {solicitud.status === 'REGISTRADA' ? (
                  solicitud.tipo === 'CONSTANCIAS O CERTIFICACIONES' ? (
                    `SIN ATENDER - ${selectedEstadoText}` // Estado personalizado para CONSTANCIAS O CERTIFICACIONES
                  ) : (
                    'SIN ATENDER'
                  )
                ) : (
                  'ATENDIDA'
                )}
              </Badge>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="mx-5" style={{ backgroundColor: "#f8f9fa" }}>
          <Row>
            <Col md="12">
              <h6 className="mb-0" style={{ color: "#344767" }}>SOLICITUD:</h6>
              <div className="ml-3 mt-2">
                <div>
                  <label className="text-muted mr-2  mb-0">Tipo:</label>
                  <label className="text-dark  mb-0"><strong>{solicitud.tipo}</strong></label>
                </div>
                <div>
                  <label className="text-muted mr-2  mb-0">Fecha:</label>
                  <label className="text-dark  mb-0"><strong>{moment(solicitud.createdAt).format("DD/MM/YYYY")}</strong></label>
                </div>
                <div>
                  <label className="text-muted mr-2  mb-0">Monto:</label>
                  <label className="text-dark  mb-0"><strong>{solicitud.monto || "Sin registro."}</strong></label>
                </div>
                <div>
                  <label className="text-muted mr-2  mb-0">Carrera:</label>
                  <label className="text-dark  mb-0"><strong>{programa.nombre || "Sin registro"}</strong></label>
                </div>
                <div>
                  <label className="text-muted mr-2  mb-0">Comentario:</label>
                  <label className="text-dark  mb-0"><strong>{solicitud.comentario || "Sin registro."}</strong></label>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md="6">
              <h6 className="mb-0" style={{ color: "#344767" }}>Tus Datos:</h6>
              <div className="ml-3 mt-2">
                <div>
                  <label className="text-muted mr-2  mb-0">Estudiante:</label>
                  <label className="text-dark  mb-0"><strong>{estudiante.nombre}</strong></label>
                </div>
                <div>
                  <label className="text-muted mr-2  mb-0">DPI:</label>
                  <label className="text-dark  mb-0"><strong>{estudiante.dpi}</strong></label>
                </div>
                <div>
                  <label className="text-muted mr-2  mb-0">Carnet:</label>
                  <label className="text-dark  mb-0"><strong>{estudiante.carnet || "Sin registro"}</strong></label>
                </div>

              </div>
            </Col>
            <Col md="6">
              <h6 className="mb-0" style={{ color: "#344767" }}>Datos de Contacto Proporcionados:</h6>
              <div className="ml-3 mt-2">
                <div>
                  <label className="text-muted mr-2  mb-0">Correo:</label>
                  <label className="text-dark  mb-0"><strong>{estudiante.email || "Sin registro"}</strong></label>
                </div>
                <div>
                  <label className="text-muted mr-2  mb-0">Teléfono:</label>
                  <label className="text-dark  mb-0"><strong>{estudiante.telefono || "Sin registro"}</strong></label>
                </div>
                <div>
                  <label className="text-muted mr-2  mb-0">Whatsapp:</label>
                  <label className="text-dark  mb-0"><strong>{estudiante.whatsapp || "Sin registro"}</strong></label>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-2 mr-1" style={{ justifyContent: 'flex-end' }}>
            <Button color="danger" size="sm" onClick={() => setShowModalDelete(true)}>Eliminar</Button>
          </Row>
        </CardBody>
        <CardFooter></CardFooter>
      </Card>
      {showModalDelete &&
        <ModalConfirmarEliminar
          showModal={showModalDelete}
          setShowModal={setShowModalDelete}
          solicitudId={solicitud.id} />}
    </div>
  )
}
