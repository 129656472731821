import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Modal, Row, Spinner } from 'reactstrap'
import { updatePrograma } from 'redux/actions/programa.actions';
import { isObjEmpty } from 'utils/helpers';
import validator from 'validator';

export const ModalEditarPrograma = ({programaId, programaPrev, showModal, setShowModal }) => {


  const [ programa, setProgama ] = useState(programaPrev);
  const [ nombrePrograma, setNombrePrograma] = useState("Carrera");
  const [ errors, setErrors ] = useState({});
  const [ response, setResponse ] = useState({});


  useEffect( ()=>{
      delete programaPrev.activeInscripcion;
      delete programaPrev.fechaInscripcion;
      delete programaPrev.prefijo;
      setProgama({ ...programaPrev, ciclos: programaPrev.ciclos.toString()} );
      setNombrePrograma( programaPrev.nombre )
  }, [programaPrev])

  const onChange = ( e) =>{
      const { name, value } = e.target;
      setProgama( prev => ({ ...prev, [name]: value }))
  }

  const submitHandle = ( e ) => {
    e.preventDefault();
    setResponse({ loading: true })
    setErrors({});

    const errores = checkErrors();
    
    if( !isObjEmpty( errores ) ){
      setErrors(errores);
      console.log("entro")
      setResponse({ loading: false })
      return;
    }

    updatePrograma(programaId, programa)
    .then( res => setResponse({ type:"success", message: res.data.message}))
    .catch(err => setResponse({ type:"danger", message: err.response.data.message }))
  } 


  const checkErrors = ()=>{
    const errores = {};

    if( !validator.isLength( programa.nombre, { min: 5 }) ){
      errores.nombre = "El nombre es obligatorio. Y debe tener al menos 5 caracteres.";
    }else if(!validator.matches(programa.nombre, /^[a-zA-Z0-9\s]*$/) ){
      errores.nombre = "El nombre de la carrera solo puede contener letras mayusculas, minusculas, numeros y espacios";
    }

    if( validator.isEmpty( programa.codigo ) ){
      errores.codigo = "El código de la carrera es obligatorio."
    }else if(!validator.matches(programa.codigo, /^[0-9-]+$/)){
      errores.codigo = "El código de la carrera solo puede contener números y guiones."
    }

    if( validator.isEmpty( programa.ciclos ) || !validator.isInt( programa.ciclos ) ){
      errores.ciclos = "La cantidad de años de la carrera deben ser números enteros."
    }
    
    if( validator.isEmpty( programa.unidad ) ){
      errores.unidad = "El codigo de la unidad es obligatorio."
    }else if(!validator.matches(programa.unidad, /^[0-9]+$/)){
      errores.unidad = "El codigo de la unidad solo puede contener números."
    }
    
    if( validator.isEmpty( programa.extension ) ){
      errores.extension = "El codigo de extensión de la carrera es obligatorio."
    }else if(!validator.matches(programa.extension, /^[0-9]+$/)){
      errores.extension = "El codigo de la extension solo puede contener números."
    }

    
    return errores;
  }

  return (
    <Modal className="modal-dialog-centered "
              size="lg"
              isOpen={showModal}
              toggle={() => setShowModal(false)}>

            <Card className="card-user">
                <CardHeader>
                    <CardTitle  tag="h6">Editar { nombrePrograma || "Carrera" }</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row className="mx-3">
                    <Col  md="12">
                        { response.message && 
                        <Alert color={response.type}>{response.message}</Alert> }
                      <Form onSubmit={submitHandle} >
                        <Row>
                            <Col  md="6">
                                <FormGroup>
                                    <label>NOMBRE DE CARRERA<label className="text-warning m-0"><strong>*</strong></label></label>                        
                                    <Input
                                    className="text-dark"
                                    placeholder="Nombre de la carrera"
                                    type="text"
                                    name="nombre"
                                    value={programa.nombre || ''}
                                    onChange={ onChange }
                                    invalid={ !! errors.nombre }
                                    required/>
                                        <FormFeedback>
                                                    { errors.nombre }
                                        </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col  md="6">
                            <FormGroup>
                                <label>DESCRIPCIPÓN</label>
                                <Input
                                className="text-dark"
                                placeholder="Descripcion del programa académico..."
                                type="text"
                                name="descripcion"
                                value={programa.descripcion || ''}
                                onChange={ onChange }
                                invalid={ !! errors.descripcion }
                                
                                />
                                <FormFeedback >
                                { errors.descripcion }
                                </FormFeedback>
                            </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col  md="6">
                            <FormGroup>
                                <label>CÓDIGO<label className="text-warning m-0"><strong>*</strong></label></label>                        
                                <Input
                                className="text-dark"
                                placeholder="Código"
                                type="text"
                                name="codigo"
                                value={programa.codigo || ''}
                                onChange={ onChange }
                                invalid={ !! errors.codigo }
                                required/>
                                    <FormFeedback>
                                                { errors.codigo }
                                    </FormFeedback>
                            </FormGroup>
                            </Col>
                            <Col  md="6">
                              <FormGroup>
                                <label>AÑOS (CICLOS académicos)<label className="text-warning m-0"><strong>*</strong></label></label>                        
                                <Input
                                  className="text-dark"
                                  placeholder="Cantidad de años de la carrera."
                                  type="number"
                                  name="ciclos"
                                  value={programa.ciclos || ''}
                                  onChange={ onChange }
                                  invalid={ !! errors.ciclos }
                                  />
                                    <FormFeedback>
                                                { errors.ciclos }
                                    </FormFeedback>
                              </FormGroup>
                            </Col>
                            </Row>
                            <Row>
                            <Col  md="6">
                            <FormGroup>
                                <label>UNIDAD<label className="text-warning m-0"><strong>*</strong></label></label>                        
                                <Input
                                className="text-dark"
                                placeholder="Unidad"
                                type="text"
                                name="unidad"
                                value={programa.unidad || ''}
                                onChange={ onChange }
                                invalid={ !! errors.unidad }
                                required/>
                                    <FormFeedback>
                                                { errors.unidad }
                                    </FormFeedback>
                            </FormGroup>
                            </Col>
                            <Col  md="6">
                            <FormGroup>
                                <label>EXTENSIÓN<label className="text-warning m-0"><strong>*</strong></label></label>                        
                                <Input
                                className="text-dark"
                                placeholder="Extensión"
                                type="text"
                                name="extension"
                                value={programa.extension || ''}
                                onChange={ onChange }
                                invalid={ !! errors.extension }
                                required/>
                                    <FormFeedback>
                                                { errors.extension }
                                    </FormFeedback>
                            </FormGroup>
                            </Col>
                            
                        </Row>
                        <Row>
                            
                            <Col className=" text-right ml-3 mt-3">
                                <Button
                                    size="md"
                                    className="btn-round"
                                    color="primary"
                                    type="submit"
                                >
                                Guardar Cambios
                                </Button>
                            </Col>
                            
                        </Row>
                       { response.loading && 
                        <Spinner/> } 
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter></CardFooter>
            </Card>
    </Modal>
  )
}
