import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom'
import { getLastProcesosAsignacion } from 'redux/actions/asignaciones.actions';
import { isObjEmpty } from 'utils/helpers';
import { Card, CardHeader, CardBody, Button, Row, Col, Input, FormGroup, FormFeedback, ListGroup, Spinner, Alert } from 'reactstrap';

import validator from 'validator';
import { getProcesoByYearAndProgramaId } from 'redux/actions/asignaciones.actions';
import { ProcesoListItem } from './ProcesoListItem';
import { MostrarProcesosByPrograma } from './MostrarProcesosByPrograma';

export const DetalleCarreraAsignacion = () => {

  const location = useLocation(); // Para acceder a la ruta completa

  //const { id } = useParams();
  const parts = location.pathname.split('/');
  const id = parts[parts.length - 1];
  const [ lastProcesos, setLastProcesos ] = useState([]);
  const [ procesosBuscados, setProcesosBuscados ] = useState([]);
  const [ programa, setPrograma ] = useState({});
  const [ response, setResponse ] = useState({});
  const [ showAllProcesos, setShowAllProcesos ] = useState( false );
  const [ year, setYear ] = useState("");
  const [ errors, setErrors ] = useState({});

  useEffect( ()=>{
    getLastProcesosAsignacion( 3, id )
    .then( res => {
      setPrograma(res.data.programa);
      setLastProcesos(res.data.procesos);
    })
    .catch( err => console.log(err))
  }, []);

  const handleSearchByYear = ()=>{
    setResponse({ loading: true });
    setErrors({});
    const errores = checkErrors();
    if( !isObjEmpty(errores) ){
      setErrors(errores);
      setResponse({});
      return;
    }

    getProcesoByYearAndProgramaId( year.trim(), programa.id )
    .then( res => {
      setProcesosBuscados( res.data.procesos );
      if( res.data.procesos.length < 1 ){
        setResponse({ color: "info", message: "No se han encontraron procesos con año " + year + "." });
      }else{
        setResponse({});
      }
    })
    .catch( err => {
      setResponse({});
      console.log(err)
    }) 
  }

  const checkErrors = ()=>{
    const errores = {}
    if( validator.isEmpty( year.trim() ) || !validator.isInt(year.trim(), { min: 1950, max: 2100 })){
      errores.anio = "Debes ingresar un año para buscar y debe ser entre 1950 - 2100."
    }
    return errores;
  }

  
  return (
    <div className="content">
      <h5>{programa.nombre} - procesos de asignación</h5>
      <Row>
        <Col md="7">
          <Card  style={{ height: '100%'}}>
            <CardHeader>
              <h6>Buscar Por Año</h6>
            </CardHeader>
            <CardBody >
              { response.message && <Alert color={response.color}><strong>{ response.message }</strong></Alert>}
              <Row style={{ alignItems: 'center'}}>
                <Col >
                  <FormGroup className="mb-0">
                    <Input
                      value={year}
                      onChange={ (e)=>setYear(e.target.value)} 
                      placeholder='Año'
                      invalid={ !!errors.anio }
                    />
                    <FormFeedback>{ errors.anio }</FormFeedback>
                  </FormGroup>                
                </Col>
                <Col className="pl-0">
                { response.loading && <Spinner className="mr-2"/> }
                  <Button color="primary"
                        onClick={()=>handleSearchByYear()}
                        style={{ backgroundColor:"#31708f"}}>
                    Buscar
                  </Button>
                </Col>
                

              </Row>
              <ListGroup className="mt-3 mx-5" flush>
                { procesosBuscados.map( (proceso) => 
                  <ProcesoListItem key={proceso.id} proceso={proceso}/>
                )}
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
        <Col md="5">
          <Card style={{ height: '100%'}}>
            <CardHeader>
              <Row style={{alignItems: 'center'}}>
                <Col className="ml-1">
                  <h6>Últimos Procesos</h6>
                </Col>
                <Col className="text-right">
                  <Button color="primary" size="sm" outline 
                    onClick={()=>setShowAllProcesos(true)} style={{color: '#2076e8'}}>
                      Ver Todos
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <ListGroup className="mt-3" flush>
                { lastProcesos.map( (proceso) => 
                  <ProcesoListItem key={proceso.id} proceso={proceso}/>
                )}
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>

      { showAllProcesos && 
        <Row className="mt-5">
          <Col md="12">
            <Card >
              <CardBody className="mx-3">
                <MostrarProcesosByPrograma programaId={programa.id}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      }
    </div>
  )
}
