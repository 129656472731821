import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { GENERAR_ACTA } from 'utils/endpoints';
import GenerarActasView from './GenerarActasView';
import { generarArchivoExcel } from './GenerarExcel';
import CustomToast from '../../CustomToast';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function GenerarActasLogic() {
  const [carreras, setCarreras] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [ciclos, setCiclos] = useState([]);
  const [secciones, setSecciones] = useState([]);
  const [selectedCarrera, setSelectedCarrera] = useState('');
  const [selectedCurso, setSelectedCurso] = useState('');
  const [selectedCiclo, setSelectedCiclo] = useState('');
  const [selectedSeccion, setSelectedSeccion] = useState('');
  const [estudiantes, setEstudiantes] = useState([]);
  const [docente, setDocente] = useState('');
  //Extraer los textos de los input
  const [selectedCarreraText, setSelectedCarreraText] = useState('');
  const [selectedCursoText, setSelectedCursoText] = useState('');
  const [selectedCicloText, setSelectedCicloText] = useState('');
  const [selectedSeccionText, setSelectedSeccionText] = useState('');
  const [docenteText, setDocenteText] = useState('');
  const [fechaText, setFechaText] = useState(null);
  //Estados de los botones
  const [cargaHabilitada, setCargaHabilitada] = useState(false);
  const [limpiarHabilitado, setLimpiarHabilitado] = useState(false);
  const [generarExcelHabilitado, setGenerarExcelHabilitado] = useState(false);
  //Notificaciones
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const handleDateChange = (date) => {
    setFechaText(date);
  };

  // Función para mostrar la notificación de error
  const showErrorNotification = (message) => {
    setToastMessage(message);
    setShowErrorToast(true);

    // Oculta la notificación después de un cierto tiempo
    setTimeout(() => {
      setShowErrorToast(false);
    }, 3000); // Puedes ajustar el tiempo en milisegundos
  };

  // Función para mostrar la notificación de éxito
  const showSuccessNotification = (message) => {
    setToastMessage(message);
    setShowSuccessToast(true);

    // Oculta la notificación después de un cierto tiempo
    setTimeout(() => {
      setShowSuccessToast(false);
    }, 3000); // Puedes ajustar el tiempo en milisegundos
  };

  const obtenerCiclos = async () => {
    return ['ANUAL','PRIMER SEMESTRE', 'SEGUNDO SEMESTRE','PRIMER TRIMESTRE', 'SEGUNDO TRIMESTRE','TERCER TRIMESTRE', 
    'PRIMER BIMESTRE', 'SEGUNDO BIMESTRE', 'TERCER BIMESTRE', 'CUARTO BIMESTRE'];
  }

  const obtenerSecciones = async () => { 
    return ['SECCIÓN A', 'SECCIÓN B', 'SECCIÓN C', 'SECCIÓN D', 'SECCIÓN E'];
  }

  const obtenerCarreras = async () => {
    try {
      const response = await axios.get(`${GENERAR_ACTA}/carreras`);
      return response.data;
    } catch (error) {
      showErrorNotification('Error al obtener carreras.');
      console.error('Error al obtener carreras:', error);
      throw error;
    }
  }

  const obtenerCursos = async (carreraId) => {
    try {
      const response = await axios.get(`${GENERAR_ACTA}/cursos`, {
        params: { carreraId },
      });
      return response.data;
    } catch (error) {
      showErrorNotification('Error al obtener cursos.');
      console.error('Error al obtener cursos:', error);
      throw error;
    }
  }

  const obtenerEstudiantesNotas = async (cursoId, ciclo, seccion) => {
    try {
      const response = await axios.get(`${GENERAR_ACTA}/estudiantes-notas`, {
        params: { cursoId, ciclo, seccion },
      });
      return response.data;
    } catch (error) {
      //showErrorNotification('Error al obtener estudiantes y notas.');
      console.error('Error al obtener estudiantes y notas:', error);
      throw error;
    }
  }

  //*********************************************************************************************************** */
  const cargarCarreras = useCallback(async () => {
    try {
      const carrerasData = await obtenerCarreras();
      setCarreras(carrerasData);
    } catch (error) {
      showErrorNotification('Error al cargar carreras.');
      console.error('Error al cargar carreras:', error);
    }
  }, []);


  const cargarCursos = async (carreraId) => {
    try {
      const cursosData = await obtenerCursos(carreraId);
      setCursos(cursosData);
    } catch (error) {
      showErrorNotification('Error al cargar cursos.');
      console.error('Error al cargar cursos:', error);
    }
  };

  const cargarCiclos = useCallback(async () => {
    const ciclosData = await obtenerCiclos();
    setCiclos(ciclosData);
  }, []);

  const cargarSecciones = useCallback(async () => {
    const seccionesData = await obtenerSecciones();
    setSecciones(seccionesData);
  }, []);

  useEffect(() => {
    cargarCarreras();
    cargarCiclos();
    cargarSecciones();
  }, [cargarCarreras, cargarCiclos, cargarSecciones]);


  const handleCarreraChange = (event) => {
    const carreraId = event.target.value;
    const selectedText = event.target.options[event.target.selectedIndex].text; // Obtener el texto seleccionado
    setSelectedCarrera(carreraId);
    setSelectedCurso('');
    setSelectedCarreraText(selectedText); // Actualizar el estado del texto seleccionado
    cargarCursos(carreraId);
  };

  const handleCursoChange = (event) => {
    const cursoId = event.target.value;
    const selectedText = event.target.options[event.target.selectedIndex].text; // Obtener el texto seleccionado
    setSelectedCurso(cursoId);
    setSelectedCursoText(selectedText); // Actualizar el estado del texto seleccionado
    handleCargarEstudiantesNotas(cursoId);
  };

  const handleCicloChange = (event) => {
    setSelectedCiclo(event.target.value);
    const selectedText = event.target.options[event.target.selectedIndex].text; // Obtener el texto seleccionado
    setSelectedCicloText(selectedText); // Actualizar el estado del texto seleccionado

  };

  const handleSeccionChange = (event) => {
    setSelectedSeccion(event.target.value);
    const selectedText = event.target.options[event.target.selectedIndex].text; // Obtener el texto seleccionado
    setSelectedSeccionText(selectedText); // Actualizar el estado del texto seleccionado
  };

  const handleCargarEstudiantesNotas = async () => {
    if (selectedCurso) {
      try {
        const estudiantesNotasData = await obtenerEstudiantesNotas(selectedCurso, selectedCiclo, selectedSeccion);
        
        // Ordenar los estudiantes alfabéticamente por nombre
        const estudiantesOrdenados = estudiantesNotasData.estudiantesNotas.sort((a, b) =>
          a.nombre.localeCompare(b.nombre));

        //setEstudiantes(estudiantesNotasData.estudiantesNotas);
        setEstudiantes(estudiantesOrdenados);
        setDocente(estudiantesNotasData.cursoNota.docente);
        setDocenteText(estudiantesNotasData.cursoNota.docente); // Actualizar el estado del docente

        //Habilitar los botones
        setLimpiarHabilitado(true);
        setGenerarExcelHabilitado(true);

      } catch (error) {
        showErrorNotification('No se encontraron estudiantes para el ciclo y la sección proporcionados.');
        console.error('Error al cargar estudiantes y notas:', error);
      }
    }
  };

  const limpiarCampos = () => {
    setSelectedCarrera('');
    setSelectedCurso('');
    setSelectedCiclo('');
    setSelectedSeccion('');
    setEstudiantes([]);
    setDocente('');
    setFechaText('');

    // Deshabilitar los botones
    setCargaHabilitada(false);
    setLimpiarHabilitado(false);
    setGenerarExcelHabilitado(false);
  };

  const handleGenerarArchivoExcel = async () => {
    if (estudiantes.length > 0 && fechaText) {
      await generarArchivoExcel(estudiantes, selectedCarreraText, selectedCursoText, selectedCicloText, selectedSeccionText, docenteText, fechaText);
    } else {
      // Muestra una notificación de error si no se cumple alguna condición
      showErrorNotification('Debes seleccionar una fecha y tener estudiantes cargados.');
    }
  };

  return (
    <div className="content">
      <GenerarActasView
        carreras={carreras}
        cursos={cursos}
        ciclos={ciclos}
        secciones={secciones}
        selectedCarrera={selectedCarrera}
        selectedCurso={selectedCurso}
        selectedCiclo={selectedCiclo}
        selectedSeccion={selectedSeccion}
        estudiantes={estudiantes}
        docente={docente}
        selectedCarreraText={selectedCarreraText}
        selectedCursoText={selectedCursoText}
        selectedCicloText={selectedCicloText}
        selectedSeccionText={selectedSeccionText}
        docenteText={docenteText}
        fechaText={fechaText}
        setFechaText={setFechaText}
        cargaHabilitada={cargaHabilitada}
        limpiarHabilitado={limpiarHabilitado}
        generarExcelHabilitado={generarExcelHabilitado}
        cargarCarreras={cargarCarreras}
        cargarCursos={cargarCursos}
        cargarCiclos={cargarCiclos}
        cargarSecciones={cargarSecciones}
        handleCarreraChange={handleCarreraChange}
        handleCursoChange={handleCursoChange}
        handleCicloChange={handleCicloChange}
        handleSeccionChange={handleSeccionChange}
        handleCargarEstudiantesNotas={handleCargarEstudiantesNotas}
        limpiarCampos={limpiarCampos}
        handleGenerarArchivoExcel={handleGenerarArchivoExcel}
      />
      {/* Renderizar el Toast de éxito cuando sea necesario */}
      {showSuccessToast && (
        <CustomToast
          type="success"
          message={toastMessage}
          onClose={() => setShowSuccessToast(false)}
        />
      )}

      {/* Renderizar el Toast de advertencia cuando sea necesario */}
      {showErrorToast && (
        <CustomToast
          type="danger"
          message={toastMessage}
          onClose={() => setShowErrorToast(false)}
        />
      )}
    </div>
  );

};

export default GenerarActasLogic;
