export const gradosAcademicos = [ 
    {
        value: 0,
        nombre: "Pensum cerrado"
    },
    {
        value: 1,
        nombre: "Licenciatura"
    },
    {
        value: 2,
        nombre: "Maestría"
    },
    {
        value: 3,
        nombre: "Doctorado"
    },
    {
        value: 4,
        nombre: "Otro"
    }
];