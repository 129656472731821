import React from 'react'
import { Button, Card, CardBody, CardHeader, Col, Modal, Row } from 'reactstrap'

export const ModalInfo = ({ showModal, setShowModal, message }) => {

  return (
    <Modal className="modal-dialog-centered "
      size="sm"
      isOpen={showModal}
      toggle={() => setShowModal(false)}>
      <Card>
        <CardHeader>
            <h6>Info</h6>
        </CardHeader>
        <CardBody>
            <p>{ message }</p>
            <Row>
              <Col className="text-right">
                <Button color="primary" size="sm" 
                  onClick={()=>setShowModal(false)} style={{ backgroundColor:"#31708f"}}> 
                  Aceptar  
                </Button>
              </Col>
            </Row>
        </CardBody>
      </Card>

    </Modal>
  )
}
