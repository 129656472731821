import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { changePreinscripcionActive } from 'redux/actions/preinscripcion.actions';
import { getConfigPreinscripcion } from 'redux/actions/preinscripcion.actions';
import { CarrerasSeleccionables } from './CarrerasSeleccionables';
import { ModalDetele } from './ModalDetele';

export const AjustesForm = () => {

  const [ config, setConfig ] = useState({});
  const [ formActive, setFormaActive ] = useState({});
  const [ showCarreras, setShowCarreras ] = useState( false );
  const [ showModal, setShowModal ] = useState( false );

  useEffect( ()=>{
    getConfigPreinscripcion()
    .then( res => {
      hadleFormActive(res.data.preinscripcionActive);
      setConfig(res.data)
    })
    .catch( err => console.log(err))
  }, []);

  const onClickBtns = (e)=>{
    const { name } = e.target;
    if( name === "activar" ){
      if( config.preinscripcionActive === true ){
        return null
      }else{
        changeActivePreinscripcion( true );
      }
    }else if( name === "desactivar"){
      if( config.preinscripcionActive === false ){
        return null;
      }else{
        changeActivePreinscripcion(false);
      }
    }
  } 

  const changeActivePreinscripcion = ( preinscripcionActive )=>{
    changePreinscripcionActive( preinscripcionActive )
    .then( res => {
      hadleFormActive(res.data.preinscripcionActive);
      setConfig(res.data)
    })
    .catch( err => console.log(err))
  }

  const hadleFormActive = ( preinscripcionActive )=>{
    if( preinscripcionActive === true ){
      setFormaActive({ btn1: 'Activo', btn2: 'Desactivar', activo: true, inactivo: false });
    }else{
      setFormaActive({ btn1: 'Activar', btn2: 'Desactivado', activo: false, inactivo: true });
    }
  }

  const styleButton = {
    borderRadius: 0,
    backgroundColor: "#f8f9fa",
    color: "#344767",
    "&:hover": {
        background: "#efefef"
    },
  }
    
  return (
    <>
      <Row style={{ justifyContent: 'center' }}>
        <Col md= "8" >
          <Card className="px-4">
            <CardHeader className="pl-0" tag="h5"> Configuración General</CardHeader>
            <hr className="mt-2"/>
            <CardBody>
                <Row>
                  <Col>
                    <p className="mb-0">Formulario Habilitado</p>
                    <p className="text-muted">Habilita o deshabilita el acceso al formulario de Preinscripción.</p>                  
                  </Col>
                  <Col className="text-right">
                    <ButtonGroup color="primary">
                      <Button onClick={onClickBtns} name="activar" active={ formActive.activo } color="info" style={styleButton} size="sm">{ formActive.btn1 }</Button>
                      <Button onClick={onClickBtns} name="desactivar" active={ formActive.inactivo } color="info" style={styleButton} size="sm">{ formActive.btn2 }</Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <hr className="mt-2"/>
                <Row>
                  <Col>
                    <p className="mb-0">Eliminar Respuestas</p>
                    <p className="text-muted">Elimina respuestas de preinscripción en la base de datos.</p>                  
                  </Col>
                  <Col className="text-right">
                      <Button onClick={()=>setShowModal(prev => !prev)} color="primary" style={styleButton} size="sm">Continuar</Button>
                  </Col>
                </Row>
                
            </CardBody>
            { showModal && 
                <ModalDetele setShowModal={setShowModal}/> }
                
                <hr className="mt-2"/> 
          </Card>
          

          <Card className="px-4">
            <CardHeader className="pl-0" tag="h5">Carreras Seleccionables</CardHeader>
            <hr className="mt-2"/>
            <CardBody>
              <Row>
                <Col>
                  <p className="mb-0">Seleccionar carreras</p>
                  <p className="text-muted">Selecciona las carreras que se mostrarán en el formulario de preinscripción.</p>                  
                </Col>
                <Col className="text-right">
                    <Button color="primary" style={styleButton} 
                      size="sm" onClick={()=> setShowCarreras(prev => !prev)}>Continuar</Button>
                </Col>
              </Row> 
              { showCarreras && <CarrerasSeleccionables/>}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* <ModalDetele
        showModal={showModal}
        setShowModal={setShowModal}/> */}
    </>
  )
}
