import React, { useEffect, useState } from 'react'
import { Badge, Button, Table } from 'reactstrap'
import { getProcesosByPrograma } from 'redux/actions/asignaciones.actions';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { TooltipItem } from 'components/utils/TooltipItem';

export const MostrarProcesosByPrograma = ({ programaId }) => {

  const [ procesos, setProcesos ] = useState([]);

  useEffect( ()=>{
      getProcesosByPrograma( programaId )
      .then( res => {
          setProcesos(res.data.procesos);
      }).catch( err => console.log(err))
  }, [])

  return (
    <Table striped responsive>
      <thead>
        <tr>
          <th>#</th>
          <th>Año</th>
          <th>Semestre</th>
          <th>Periodo</th>
          <th>Creado</th>
          <th>Estado</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
          { procesos.map( (p, i) => 
            <tr key={p.id}>
              <td>{ i+1 }</td>
              <td>{p.anio}</td>
              <td>{p.semestre}</td>
              <td>{p.periodo}</td>
              <td>{ moment(p.createdAt).format("DD/MM/YYYY")}</td>
              <td>
                <Badge className="p-1 mb-2" color={p.finalizado ? "danger" : "success"}>
                  <h6 className="mb-0">{p.finalizado ? "Finalizado" : "Abierto"}</h6>
                </Badge>
              </td>
              <td>
                <Link to={`/admin/asignaciones/proceso/${p.id}`}>
                    <Button id={`edit-${p.id}`} color="primary" size="sm" style={{backgroundColor:'#f7f7f7'}}>
                      <div className="stats" >
                        <i className="far fa-eye" style={{color:"#344767"}}></i>                                            
                      </div>
                    </Button>
                    <TooltipItem target={`edit-${p.id}`} title={"Ver"}/>
                </Link>
              </td>
            </tr>
          )}
      </tbody>
    </Table>
  )
}
