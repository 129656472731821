import React, {useState} from 'react'
import { Alert, Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Row, Spinner } from 'reactstrap'
import { searchProcesoAsignacion } from 'redux/actions/asignaciones.actions';
import { isObjEmpty } from 'utils/helpers';
import validator from 'validator';
import { periodos } from 'utils/periodos';
import { MostrarCursos } from './MostrarCursos';

export const SearchProceso = ({ programaId,programaNombre }) => {

  const procesoInitial = { semestre: "", anio: new Date().getFullYear().toString(), periodo: "default", programaId };
  const [ procesoSearch, setProcesoSearch ] = useState(procesoInitial);
  const [ procesoEncontrado, setProcesoEncontrado ] = useState({});
  const [ disableForm, setDisabledForm] = useState(false);
  const [ showCursos, setShowCursos] = useState(false);
  const [ errors, setErrors ] = useState({});
  const [ response, setResponse ] = useState({});

  const onChange = ( e ) => {
    const { name, value } = e.target;
    setProcesoSearch( prev => ({...prev, [name]: value}))
  }

  const prepareSearchBody = ()=>{
    return {
        anio: procesoSearch.anio.trim(),
        semestre: procesoSearch.semestre.trim(),
        periodo: procesoSearch.periodo,
        programaId
    };
  }
  const handleSearch = ()=>{
      setResponse({loading:true});
      setErrors({})
      const errores = checkErrors();
      if( !isObjEmpty(errores) ){
          setResponse({});
          setErrors(errores);
          return;
      }
      
      searchProcesoAsignacion( prepareSearchBody() )
      .then( res=> {
        setResponse({});
        if( res.data.exist ){
          setProcesoEncontrado(res.data.proceso);
          setDisabledForm(true);
          setShowCursos( true );
        }else{
            setResponse({type:"warning", message: "No se encontró ningún proceso de asignación con los campos enviados en esta carrera." })
        }
      })
      .catch( err => console.log(err))

  }

  const checkErrors = ()=>{
    const errores = {};

    if( validator.isEmpty( procesoSearch.semestre ) || !validator.isInt(procesoSearch.semestre, {min: 1, max: 4}) ){
        errores.semestre = "Semestre o trimestre debe ser un entero 1-4."
    }
    if( validator.isEmpty( procesoSearch.anio.trim() ) || !validator.isInt(procesoSearch.anio.trim(), { min: 1950, max: 2100 })){
        errores.anio = "El año debe ser entre 1950 - 2100."
    }
    if( validator.isEmpty(procesoSearch.periodo) || procesoSearch.periodo === "default"){
        errores.periodo = "Selecciona un periodo.";
    }
    return errores;
  }

  return (
    <>
    <Card>
      <CardHeader
        tag="h6" 
        className="py-3 mb-3" style={{ backgroundColor: '#efefef',borderColor: '#f7f7f7'}}>
            Proceso Asignación - {programaNombre}
      </CardHeader>
      <CardBody>
        <Form>
          <Row style={{justifyContent:'center'}}>
            <Col md="8">
            { response.message && <Alert color={response.type}><strong>{ response.message }</strong></Alert>}
            <Row className="mb-1">
                <Col md="4">      
                  <FormGroup>
                    <label><strong>AÑO</strong></label>
                    <Input
                      disabled={disableForm}
                      className="text-dark"
                      name="anio"
                      value={procesoSearch.anio}
                      onChange={onChange}
                      type="text"
                      placeholder="Año"
                      invalid={!!errors.anio}
                      required/>
                    <FormFeedback>{errors.anio}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label><strong>SEMESTRE O TRIMESTRE</strong></label>
                    <Input      
                      disabled={disableForm}    
                      className="text-dark"
                      name="semestre"
                      value={procesoSearch.semestre}
                      onChange={onChange}
                      type="text"
                      placeholder="1 | 2 | 3"
                      invalid={!!errors.semestre}
                      required/>
                    <FormFeedback>{errors.semestre}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label><strong>PERIODO</strong></label>
                    <Input
                     disabled={disableForm}
                     className="text-dark"
                      name="periodo"
                      value={procesoSearch.periodo}
                      onChange={onChange}
                      type="select"
                      placeholder="Periodo" 
                      invalid={!!errors.periodo}
                      required>
                        <option value={"default"} hidden disabled>Selecciona el periodo.</option>
                        { periodos.map( p => 
                          <option key={p.key} value={p.value}> {p.value} </option>)}
                      </Input>
                    <FormFeedback>{errors.periodo}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row >
                  <Col className="text-right" style={{alignItems: 'center'}}>
                {response.loading && 
                    <Spinner className="mr-3"/>}
                  <Button
                    disabled={disableForm}
                    color="primary"
                    style={{ backgroundColor:"#31708f"}} 
                    onClick={ ()=>handleSearch()}
                    size="sm">
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
    { (showCursos && procesoEncontrado) 
      && <MostrarCursos procesoProp={ procesoEncontrado }/> }
    </>

  )
}
