import React, { useEffect, useState } from 'react'
import { FormNuevaSolicitud } from 'components/solicitudes/FormNuevaSolicitud'
import { NuevaSolicitud } from 'components/solicitudes/NuevaSolicitud'
import { useDispatch } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { useSelector } from 'react-redux'
import { initStudent } from 'redux/actions/student.actions'
import { SolicitudListEstudiante } from 'components/solicitudes/SolicitudList'
import { DeleteSolicitudesAtendidas } from 'components/solicitudes/DeleteSolicitudesAtendidas'

export const Solicitudes = () => {
    
  const user = useSelector( state => state.auth.user );
  const fetched = useSelector( state => state.estudiante.fetched );
  const student = useSelector( state => state.estudiante.student );
  const programas_inscrito = useSelector( state => state.estudiante.programas_inscrito );
  const [ programas, setProgramas ] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!fetched){
        dispatch( initStudent( user.username ))
    }
  }, []);

  useEffect( () => {
    if(programas_inscrito){
      setProgramas(programas_inscrito);
    }
  }, [programas_inscrito]);

  return (
    <div className="content">
      <h5>Modulo de Solicitudes</h5>
      <Row>
       <Col md="12">
        <p className="mb-1">La respuesta a tus solicitudes llegará por medio de correo electronico o por contacto de Whatsapp indicados en tu Datos Personales. 
        Por favor proporciona estos datos de contacto en tus Datos Personales si aún no lo has hecho. </p>
        <p className="text-muted">Correo: { student.email ? student.email : "Sin registro."  } | Whatsapp: { student.whatsapp ? student.whatsapp : "Sin registro."  }</p>
       </Col>
      </Row>
      <Row className="mt-3">
        <Col md="6">
          <NuevaSolicitud correo={student.email} whatsapp={student.whatsapp} studentId={student.id}>
            <FormNuevaSolicitud programas={programas} student={student}/>
          </NuevaSolicitud>
        </Col>
        <Col>
          <DeleteSolicitudesAtendidas studentId={student.id}/>
          { student.id && 
            <SolicitudListEstudiante studentId={student.id}/>
          }
        </Col>
      </Row>
        
    </div>
  )
}
