import React, { useState } from 'react'
import { Alert, Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap'
import { searchCurso } from 'redux/actions/curso.actions';
import { CursoListSearch } from './CursoListSearch';
import validator from 'validator';

export const BuscarCurso = () => {

  const [nombre, setNombre] = useState("");
  const [codigo, setCodigo] = useState("");
  const [errors, setErrors ] = useState({});
  const [coincidencias, setCoincidencias ] = useState([]);
  const [ response, setResponse ] = useState({});

  const handleSearchByNombre = () => {
    setErrors({});

    
    if( !validator.isLength( nombre, { min:5, max:151 })){
      setErrors({nombre : "El nombre debe tener al menos 5 caracteres y máximo 150."});
      return;
    }else if(!validator.matches(nombre, /^[a-zA-Z0-9\s]*$/)){
      setErrors({nombre : "El codigo del curso solo puede contener letras mayusculas, minusculas, numeros y espacios"});
      return;
    }



    submit({ clave: "nombre", valor: nombre})
  }

  const handleSearchByCodigo = () => {
    setErrors({});


    if( validator.isEmpty( codigo )){
      setErrors({ codigo : "Por favor ingresa un código para búsqueda."})
      return;
    }

    if( validator.isEmpty(codigo)){
      setErrors({ codigo : "El curso debe tener un código único asignado por el personal."});
      return;
    }else if(!validator.matches(codigo, /^[a-zA-Z0-9-]+$/)){
      setErrors({ codigo : "El codigo solo puede contener letras mayusculas, minusculas, numeros y guiones"});
      return;
    }


    submit({ clave: "codigo", valor: codigo})
  }

  const submit = ( searchBody )=>{
    searchCurso( searchBody )
    .then( res => {
      if( res.data.coincidencias.length < 1 ){
        setCoincidencias( [] )
        setResponse({ type: 'warning', message: `No se encontraron coincidencias con: ${nombre}.`, clave: searchBody.clave })
      }else{
        setCoincidencias( res.data.coincidencias )
      }
    })
    .catch( err => {
        setCoincidencias([]);
        console.log( err );
    })
  }

  return (
    <>
    <Row>
    <Col>
      <Card>
        <CardHeader>
            <CardTitle tag="h5">Buscar por Nombre </CardTitle>
        </CardHeader>
        <CardBody className="pt-3 pb-4">
            <Row className="mx-2">
            <Col  md="12">
              { response.clave === "nombre" && <Alert color={response.type}>{ response.message }</Alert> }
                <Form >
                <FormGroup>
                    <label>Curso</label>                        
                    <Input
                    className="text-dark mb-2"
                    placeholder="Nombre del curso"
                    type="text"
                    name="nombre"
                    value={nombre || ''}
                    onChange={ (e) => setNombre(e.target.value) }
                    invalid={ !! errors.nombre }
                    required/>
                    <FormFeedback>
                        { errors.nombre }
                      </FormFeedback>
                    <Button size="sm" color="primary" onClick={()=>handleSearchByNombre()}>Buscar</Button>
                </FormGroup>
                </Form>
            </Col>
            </Row>
        </CardBody>
      </Card>
    </Col>
    <Col>
      <Card>
        <CardHeader>
            <CardTitle tag="h5">Buscar por Código </CardTitle>
        </CardHeader>
        <CardBody className="pt-3 pb-4"  >
            <Row className="mx-2">
            <Col  md="12">
                { response.clave === "codigo" && <Alert color={response.type}>{ response.message }</Alert> }
                <Form >
                <FormGroup>
                    <label>Curso</label>                        
                    <Input
                    className="text-dark mb-3"
                    placeholder="Código del curso"
                    type="text"
                    name="codigo"
                    value={codigo || ''}
                    onChange={ (e) => setCodigo(e.target.value) }
                    invalid={ !! errors.codigo }
                    required/>
                      <FormFeedback>
                        { errors.codigo }
                      </FormFeedback>
                    <Button size="sm" color="primary" onClick={()=>handleSearchByCodigo()}>Buscar</Button>
                </FormGroup>
                </Form>
            </Col>
            </Row>
        </CardBody>
      </Card>
    </Col>
    </Row>
     { coincidencias.length > 0 && <CursoListSearch coincidencias={coincidencias}/>} 
    </>
  )
}
