/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

/*
import React from "react";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import store from "./store";
import { Provider } from "react-redux";
import { AppRouter } from "routes/AppRouter";

ReactDOM.render(
  <Provider store={ store }>
    <AppRouter/>
  </Provider>,
  document.getElementById("root")
);
*/

import React from "react";
import { createRoot } from "react-dom/client"; // Importa createRoot
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import store from "./store";
import { Provider } from "react-redux";
import { AppRouter } from "routes/AppRouter";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { RECAPTCHA_KEY_SITE } from './utils/enviroments';

// Encuentra el elemento root
const container = document.getElementById("root");
const root = createRoot(container); // Crea el root

// Renderiza la aplicación usando createRoot
root.render(
  <Provider store={store}>
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY_SITE}>
      <AppRouter />
    </GoogleReCaptchaProvider>
  </Provider>
);

