import { CarreraListAsignacion } from 'components/asignaciones/CarreraListAsignacion'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { getProgramasByAsignacion } from 'redux/actions/asignaciones.actions';

export const Asignaciones = () => {

  const navigate = useNavigate();
  const [ programas, setProgramas ] = useState([]);

  useEffect( ()=>{ 
    getProgramasActivos();
  }, [])

  const getProgramasActivos = ()=>{
    getProgramasByAsignacion( true )
    .then( res => setProgramas( res.data.programas ))
    .catch( err => console.log(err))
  }

  const goToNuevoProceso = ()=>{
    navigate("asignaciones/nuevo");
  }

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card className="card-user">
            <CardHeader>
              <Row className="ml-0 mr-2" style={{ alignItems: 'center'}}>
                <Col md="6">
                  <h5>Carreras con Procesos de Asignación Activos</h5>
                </Col>
                <Col  md="6" className="text-right">
                  <Button
                    style={{ backgroundColor:"#31708f"}}
                    color="primary"
                    onClick={()=>goToNuevoProceso() }>
                    Nuevo Proceso
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="mr-4">
              { programas.length > 0 ? 
              <CarreraListAsignacion programasConAsignaciones={programas}/>
              : <Alert color="info">
                <strong>No hay carreras con procesos de asignación activos.</strong>
                </Alert>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
