import React from 'react'
import { Button, Card, CardBody, CardHeader, Col, Modal, Row } from 'reactstrap';

export const ModalConfirmarInscripcion = ({ showModal, setShowModal, inscribirCallback }) => {

  const handleClick = ()=>{
    inscribirCallback();
  };

  return (
    <Modal className="modal-dialog-centered "
      size="md"
      isOpen={showModal}
      toggle={() => setShowModal(false)}>
      <Card>
        <CardHeader>
            <h6> <p className="text-danger"> ATENCIÓN </p> - Confirma la inscripción</h6>
        </CardHeader>
        <CardBody>
            <p>Tienes una inscripción vigente, se eliminará tu inscripción vigente y se sustituirá por la nueva inscripción.</p>
            <Row>
              <Col className="text-right">
                <Button color="primary" size="sm" 
                    onClick={()=>handleClick()} style={{ backgroundColor:"#31708f"}}>Confirmar</Button>
                <Button color="warning" size="sm" onClick={()=>setShowModal(false)}> Cancelar  </Button>
              </Col>
            </Row>
        </CardBody>
      </Card>

    </Modal>
  )
}
