import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/css/Actas.css';

function VerCursosNotasView({ cursos }) {


  return (
    <div className="content">
      {cursos.map((curso, index) => (
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Información del curso</CardTitle>
          </CardHeader>
          <CardBody className="pb-1">
            <Form>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <label>{'NOMBRE DEL CURSO  '}<label className="text-warning m-0"><strong>*</strong></label></label>
                    <br></br>
                    <Input
                      className="text-dark w-100"
                      placeholder="Nombre del curso"
                      type="text"
                      name="curso"
                      value={curso.nombre}
                      readOnly>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label>{'SECCIÓN  '}<label className="text-warning m-0"><strong>*</strong></label></label>
                    <br></br>
                    <Input
                      className="text-dark w-100"
                      placeholder="Seccion"
                      type="text"
                      name="seccion"
                      value={curso.seccion}
                      readOnly>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <label>{'CATEDRÁTICO '}<label className="text-warning m-0"><strong>*</strong></label></label>
                    <br></br>
                    <Input
                      className="text-dark w-100"
                      placeholder="Catedratico"
                      type="text"
                      name="catedratico"
                      value={curso.docente}
                      readOnly>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label>{'NOTA  '}<label className="text-warning m-0"><strong>*</strong></label></label>
                    <br></br>
                    <Input
                      className="text-dark w-100"
                      placeholder="Nota"
                      type="text"
                      name="nota"
                      value={curso.nota}
                      readOnly>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      ))}

    </div>
  );
}

export default VerCursosNotasView;
