/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import {  useNavigate, useLocation, matchRoutes } from 'react-router-dom';
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { PrivateRoute } from "routes/PrivateRoute";
import routes from "routes/adminRoutes";
import { useSelector } from "react-redux";

var ps;

function Dashboard(props) {
  const [backgroundColor,] = React.useState("black");
  const [activeColor,] = React.useState("info");
  const mainPanel = React.useRef();
  const location = useLocation();

  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user);
  const loggedIn = useSelector(state => state.auth.loggedIn);

  React.useEffect(() => {
    if (loggedIn) {
      if (user.role !== 'role_admin') {
        navigate("/home");
      }
    } else {
      navigate("/login");
    }
  }, [loggedIn, user, navigate]);

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
    };
  }, []);

  React.useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        routes={routes}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div className="main-panel" ref={mainPanel}>
        <DemoNavbar {...props} />


          
        {
        routes.map((prop, key) => {
         let fullPath = prop.layout + prop.path;
          
          //const match = matchPath(fullPath, location.pathname);
          const match = matchRoutes([{ path: fullPath }], location.pathname);

          if (prop.layout === "/admin" && match) {

            
          
            return (
              <PrivateRoute
                exact
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          }else { return null }
          
          })
          }


        <Footer fluid />
      </div>
    </div>
  );
}

export default Dashboard;

