import { ModalConfirmDelete } from 'components/solicitudes/ModalConfirmDelete';
import { TooltipItem } from 'components/utils/TooltipItem';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { Alert, Button, Card, CardBody, CardHeader, Col, Row, Spinner, Table } from 'reactstrap';
import { desasignarEstudianteDeCurso } from 'redux/actions/asignaciones.actions';
import { downloadExcelAsignados } from 'redux/actions/asignaciones.actions';
import { getEstudianteAsignados } from 'redux/actions/asignaciones.actions';
import { ModalInfo } from 'components/asignaciones/estudiante/ModalInfo';

export const DetalleCursoProceso = () => {

  const location = useLocation();
  

  const parts = location.pathname.split('/');
  const procesoId = parts[parts.length - 3]; 
  const cursoId = parts[parts.length - 1];  
  
  //const { procesoId, cursoId } = useParams();
  const [ estudiantes, setEstudiante ] = useState([]);
  const [ estudianteId, setEstudianteId ] = useState('');
  const [ estudianteDesasignar, setEstudianteDesasignar ] = useState({});
  const [ proceso, setProceso ] = useState({});
  const [ curso, setCurso ] = useState('');
  const [ programa, setPrograma ] = useState('');
  const [ response, setResponse ] = useState({});
  const [ showModal, setShowModal ] = useState(false);
  const [ showModalInfo, setShowModalInfo] = useState(false);

  useEffect( ()=>{
    getEstudianteAsignados( procesoId, cursoId )
    .then( res => {
       console.log(res.data);
        setProceso( res.data.proceso );
        setEstudiante( res.data.estudiantes);
        setPrograma( res.data.programa );
        setCurso( res.data.curso );
    })
    .catch( err => console.log(err))
  }, []);

  const handleDownload = () => {
    setResponse({ loading: true });
    downloadExcelAsignados( procesoId, cursoId )
    .then( res => {
      setResponse({});
    }).catch(err =>{
      setResponse({ color: "danger", message: err.response.data.message })
    });
  }

  const handleDesasignar = (estudianteId) => {
    setShowModal(true);
    setEstudianteId(estudianteId);
    setEstudianteDesasignar(estudiantes.find( estudiante => estudiante.id === estudianteId ));
  }

  const desasignarEstudiante = () => {
    setResponse({ loading: true });
    desasignarEstudianteDeCurso( procesoId, estudianteId, cursoId )
    .then( res => {
      setEstudiante( estudiantes.filter( estudiante => estudiante.id !== estudianteId ));
      desasignacionCorrecta(res.data.message);
    }).catch(err =>{
      setResponse({ color: "danger", message: err.response.data.message })
    });
  }

  const desasignacionCorrecta = (message) => {
    setShowModal(false);
    setEstudianteId('');
    setResponse({ });
    setShowModalInfo(true);
  }

  return (
    <div className="content">
      <h5>Estudiantes Asignados</h5>
      <Card>
        <CardHeader>
          <Row>
            <Col md="12">
              <p className='mb-0'>Programa: <strong>{programa}</strong></p>
              <p className='mb-0'>Curso: <strong>{curso}</strong></p>
            </Col>
          </Row>
          <Row className="mx-2 mb-2" style={{ justifyContent: 'flex-end'}}>
            { response.loading && <Spinner className="mr-3"/>}
            <Button color='primary' size="sm" onClick={()=>handleDownload()}
             style={{ backgroundColor:"#31708f"}}>Descargar</Button>
          </Row>
        </CardHeader>
        <CardBody className="pt-0">
          <Table striped responsive>
            <thead>
              <tr>
                <th>Semestre</th>
                <th>Año</th>
                <th>Periodo</th>
                <th>Nombre</th>
                <th>DPI</th>
                <th>Carnet</th>
                <th>Fecha</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
                { estudiantes.map( e => 
                    <tr key={e.id}>
                      <td>{proceso.semestre}</td>
                      <td>{proceso.anio}</td>
                      <td>{proceso.periodo}</td>
                      <td>{e.nombre}</td>
                      <td>{e.dpi}</td>
                      <td>{e.carnet}</td>
                      <td>{ moment(e.CursosAsignados.fechaAsignacion).format("DD/MM/YYYY")}</td>
                      <td>
                        <Button id={`desasignar-${e.id}`} color="danger" size="sm"
                          onClick={()=>handleDesasignar(e.id)}>
                          <div className="stats" >
                            <i className="fas fa-trash"></i>                                           
                          </div>
                        </Button>
                        <TooltipItem target={`desasignar-${e.id}`} title={"Desasignar"}/>
                      </td>
                    </tr>
                )}
            </tbody>
          </Table>
        </CardBody>
      </Card>

      { showModal && 
        <ModalConfirmDelete
          showModal={showModal}
          setShowModal={setShowModal}>
          { response.message && <Alert color={response.color} ><strong>{response.message}</strong></Alert>}
          <p>¿Está seguro de desasignar al estudiante <strong>{estudianteDesasignar.nombre}</strong> que 
           se identifica con DPI <strong>{estudianteDesasignar.dpi}</strong>? </p>           
           <p>Esta acción es irreversible.</p>
          <Button onClick={()=>desasignarEstudiante()} size="sm" color="danger">Desasignar</Button>
          { response.loading && <Spinner className="ml-3"/>}
        </ModalConfirmDelete>
      }
      { showModalInfo &&
          <ModalInfo 
            showModal={showModalInfo}
            setShowModal={setShowModalInfo}
            message="El estudiante se ha desasignado correctamente"/>
      }

    </div>
  )
}
