import React, { useState } from 'react'
import { Button, Card, CardBody, CardHeader, CardTitle, Modal, Row, Spinner } from 'reactstrap'

export const ConfirmarProcesoModal = ({ showModal, setShowModal, proceso, crearProceso }) => {

  const { programaNombre, cursosSeleccionados } = proceso;
  const [ loading, setLoading ] = useState(false);


  const handleButton = ()=>{
    setLoading(true);
    crearProceso();
  }

  return (
    <Modal className="modal-dialog-centered "
      size="lg"
      isOpen={showModal}
      toggle={() => setShowModal(false)}>

      <Card className="card-user mx-2">
        <CardHeader>
          <CardTitle  tag="h6">¿Estás seguro de crear este nuevo proceso de asignación?</CardTitle>
        </CardHeader>
        <CardBody  className=" mx-4">
            <p className="text-danger">Se creará un nuevo proceso de asignación para la carrera {programaNombre}.
            Una vez creado el proceso, no se podrá eliminar.  </p>
            <h6>Información de proceso:</h6>
            <label className="mx-4 pt-2 text-dark"> 
                <h6>AÑO: {proceso.anio}</h6>
                <h6>Semestre o trimestre: {proceso.semestre}</h6>
                <h6>Periodo: {proceso.periodo}</h6>
                <h6>Carrera : {programaNombre}</h6>
                <h6>Cursos: | { cursosSeleccionados.map( c=> ""+ c.codigo+ " | " )} </h6>
            </label>
            <Row className="mx-3" style={{ alignItems:'center', justifyContent:"flex-end"}} >
                { loading && <Spinner color="primary" className="mr-3"/>}
                <Button
                  className="mr-1"
                  onClick={()=>handleButton()}
                  color="primary"
                  style={{ backgroundColor:"#31708f"}}>
                    CREAR</Button>
                <Button color="warning" onClick={()=>setShowModal(false)}>
                  CANCELAR</Button>
            </Row>
        </CardBody>

      </Card>
    </Modal>
  )
}
