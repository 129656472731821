import React, { useState } from 'react';
import { Modal, Card, CardHeader, CardBody, Row, Col, Input, FormGroup, FormFeedback, Button, Alert, Spinner } from 'reactstrap';
import validator from 'validator';
import moment from 'moment';
import { isObjEmpty } from 'utils/helpers';
import { changeDateEndAtProceso } from 'redux/actions/asignaciones.actions';

export const ModalChangeDateEndAt = ( { setProceso, procesoId, showModal, setShowModal}) => {

  const [ errors, setErrors ] = useState({});
  const [ response, setResponse ] = useState({});
  const [ endAt, setEndAt ] = useState("");

  const handleClick = ()=>{
    setResponse({ loading: true });
    const errores = checkErrors();
    if( !isObjEmpty(errores) ){
      setErrors(errores);
      setResponse({});
      return;
    }

    changeDateEndAtProceso( procesoId, endAt )
    .then( res => {
        setResponse({ type: "success", message: "Fecha de finalización cambiada correctamente."})
        setProceso( prev => ({ carrera: prev.carrera, ...res.data.proceso }) );
    }).catch( err => {
        setResponse( {type: "danger", message: err.response.data.message })
    })
  }

  const checkErrors = ()=>{
    setErrors({});
    const errores = {};
    if( !validator.isDate( endAt )) {
      errores.endAt = "Selecciona una fecha de finalización.";
    }else{
      const dateIngresada = moment(endAt).format("MM/DD/YYYY");
      const now = moment().format("MM/DD/YYYY");
      if( now > dateIngresada ){
            errores.endAt = "No puedes seleccionar una fecha anterior a la actual.";
      }
    }
    return errores;
  }

  return (
    <Modal className="modal-dialog-centered "
      size="md"
      isOpen={showModal}
      toggle={() => setShowModal(false)}>

      <Card className="mx-2" >
        <CardHeader>
          <h5>Cambiar Fecha de Finalizado</h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="12">
              { response.message && <Alert><strong> { response.message } </strong></Alert>}
              <FormGroup>
                <label><strong>NUEVA FECHA DE FINALIZACIÓN (inclusiva)</strong></label>
                <Input  
                    className="text-dark"
                    name="endAt"
                    value={endAt}
                    onChange={(e) => setEndAt(e.target.value)}
                    type="date"
                    invalid={!!errors.endAt}
                    required/>
                <FormFeedback>{errors.endAt}</FormFeedback>
              </FormGroup>
              <Button color="warning" size="sm" onClick={()=>handleClick()}>Guardar</Button>
              { response.loading && <Spinner className="ml-3"/>}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Modal>
  )
}
