import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Badge, Button, Card, CardBody, CardHeader, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Spinner, Table } from 'reactstrap';
import { getCursosAsignablesByProceso } from 'redux/actions/asignaciones.actions';
import moment from 'moment';
import { TooltipItem } from 'components/utils/TooltipItem';
import { ModalAddAsignable } from './ModalAddAsignable';
import { ModalChangeDateEndAt } from './ModalChangeDateEndAt';
import { downloadExcelAsignados } from 'redux/actions/asignaciones.actions';
import { Link } from 'react-router-dom';

export const DetalleProceso = () => {

  const location = useLocation(); // Para acceder a la ruta completa

  //const { id } = useParams();
  const parts = location.pathname.split('/');
  const id = parts[parts.length - 1];
  const [ proceso, setProceso ] = useState({});
  const [ response, setResponse ] = useState({});
  const [ cursosAsignables, setCursosAsignables ] = useState([]);
  const [ showModal, setShowModal ] = useState(false);
  const [ showModalDate, setShowModalDate ] = useState( false );
  const [ idsAsignables, setIdsAsignables ] = useState([]);

  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const dropdownToggle = (e) => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleButtonAdd = ()=>{
    const asignablesIDs = cursosAsignables.map( c => c.id );
    setIdsAsignables( asignablesIDs );
    setShowModal( true );
  }

  useEffect( ()=>{
    getInfoProceso();
  }, []);

  const getInfoProceso = ()=>{
    getCursosAsignablesByProceso( id )
    .then( res => {
      setProceso( res.data.proceso );
      setCursosAsignables( res.data.cursosAsignables );
    }).catch( err => {
      console.log(err)
    })
  }

  const handleDownload = ( cursoId ) => {
    setResponse({ loading: true });
    downloadExcelAsignados( proceso.id, cursoId )
    .then( res => {
      console.log( res.data.message );
      setResponse({});
    }).catch(err =>{
      console.log(err);
      setResponse({ color: "danger", message: err.response.data.message })
    });
  }

  return (
    <div className="content">
      <Card className=" mb-5">
        <CardBody className="mx-3 pt-1 pb-4">
          <Row >
            <Col>
              <h5 className="mb-1">Proceso de Asignación - {proceso.carrera}</h5>
              <p className="my-0">
                <strong> Año: { proceso.anio } </strong> | 
                 <strong> Semestre: {proceso.semestre} </strong> | 
                 <strong> Periodo: {proceso.periodo} </strong></p>

            </Col>
            <Col className="text-right" style={{ alignItems: 'center'}} >
              <Row className="mr-1" style={{justifyContent:'flex-end', alignItems:'center'}} >
                <Badge className="p-1 mb-2" color={proceso.finalizado ? "danger" : "success"}>
                  <h6 className="mb-0">{proceso.finalizado ? "Finalizado" : "Abierto"}</h6>
                </Badge>
                
              </Row>
              <p className="text-muted my-0">Creado: { moment(proceso.createdAt).format("DD/MM/YYYY")}</p>
              <p className="text-muted my-0">Termina:  { moment(proceso.endAt).format("DD/MM/YYYY")}</p> 
            </Col>

            <div>
            <Dropdown
              nav
              isOpen={dropdownOpen}
              toggle={(e) => dropdownToggle(e)}
            >
              <DropdownToggle nav>
                <i className="nc-icon  nc-settings-gear-65" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem tag="a" onClick={()=>setShowModalDate(true)}>Cambiar Fecha Finalizado</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            </div>
          </Row>
        </CardBody>
      </Card>
      <h5>Cursos Asignables</h5>
      <Card>
        <CardHeader>
          <Row className="mx-2" style={{ justifyContent: 'flex-end'}}>
            { response.loading && <Spinner className="mr-4"/>}
            { proceso.finalizado === false &&
            <Button color='primary' size="sm" onClick={()=>handleButtonAdd()}
             style={{ backgroundColor:"#31708f"}}>Agregar</Button>}
          </Row>
        </CardHeader>
        <CardBody className="pt-0">
          <Table striped>
            <thead>
              <tr>
                <th>#</th>
                <th>Código</th>
                <th>Curso</th>
                <th>Carrera</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              { cursosAsignables.map( (c, i) => 
                <tr key={c.id} >
                  <td>{i+1}</td>
                  <td>{c.codigo}</td>
                  <td>{c.nombre}</td>
                  <td>{c.carrera}</td>
                  <td>{
                    <>
                      <>
                      <Button className="mr-1" onClick={()=>handleDownload(c.id)}
                      id={`add-${c.id}`} color="primary" size="sm">
                        <div className="stats" >
                          <i className="fas fa-download"></i>                                           
                        </div>
                      </Button>
                      <TooltipItem target={`add-${c.id}`} title={"Descargar Respuestas"}/>
                      </>
                      <>
                      <Link to={`/admin/asignaciones/proceso/${proceso.id}/curso/${c.id}`}>
                        <Button id={`config-${c.id}`} color="success" size="sm">
                          <div className="stats" >
                            <i className="fas fa-edit"></i>                                           
                          </div>
                        </Button>
                        <TooltipItem target={`config-${c.id}`} title={"Gestionar"}/>
                      </Link>
                      </>
                    </>
                  }</td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      { showModal && 
        <ModalAddAsignable
          updateInfoCallback={ getInfoProceso } 
          procesoId={ proceso.id }
          carrera={ { id: proceso.carreraId, nombre: proceso.carrera}}
          idsAsignables = {idsAsignables}
          showModal={showModal}
          setShowModal={setShowModal}/>}
      {
        showModalDate &&
        <ModalChangeDateEndAt
          setProceso={ setProceso } 
          procesoId={ proceso.id }
          showModal={showModalDate}
          setShowModal={setShowModalDate}/> 
      }
    </div>
  )
}
