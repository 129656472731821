import { CarreraList } from 'components/programas/CarreraList';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { getProgramas } from 'redux/actions/programa.actions';

export const Programas = () => {

  const [ programas, setProgramas ] = useState([]);
  const [ , setResponse ] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getProgramas()
    .then( res => setProgramas( res.data.programas ))
    .catch( err => {
      setProgramas([]);
      setResponse({ type:"danger", message: err.response.data.message})
    } )
  }, [])
  
  
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              <CardHeader>
                <Row className="ml-0 mr-2" style={{ alignItems: 'center'}}>
                  <Col md="4" style={{ alignItems: 'center' }}>
                    <h3 className="mb-1">Carreras</h3>
                  </Col>
                  <Col  md="8" className="text-right">
                    <Button
                    size="sm"
                    style={{ backgroundColor:"#31708f"}}
                      color="primary"
                      onClick={ () => navigate("programas/new")}>
                      Nueva Carrera
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="mt-2 ml-2 mr-3">
              { programas.length > 0 ?  
                <CarreraList programas={programas}/>
                : <Alert color="info">
                <strong>No hay carreras en el sistema.</strong>
                </Alert>
              }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}
