import { TooltipItem } from 'components/utils/TooltipItem'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Table } from 'reactstrap'

export const CarreraList = ({programas}) => {
  return (
    <Table className="m-3" responsive striped>
        <thead className="text-secundary">
            <tr>
                <th>#</th>
                <th>Código</th>
                <th>Carrera</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            { programas.map( (programa, i) => 
            <tr key={programa.id}> 
                <td>{i+1}</td>
                <td>{programa.codigo}</td>
                <td>{programa.nombre}</td>
                <td>
                    <Link to={`programas/${programa.id}`}>
                        <Button id={`config-${programa.id}`} color="warning" size="sm">
                          <div className="stats" >
                            <i className="fas fa-edit"></i>                                           
                          </div>
                        </Button>
                        <TooltipItem target={`config-${programa.id}`} title={"Ver"}/>
                    </Link>
                </td>
            </tr>)}
        </tbody>
    </Table>
  )
}
