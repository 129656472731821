import { CarreraListInscripcion } from 'components/inscripciones/CarreraListInscripcion';
import { ModalAddProgramaInscripcion } from 'components/inscripciones/ModalAddProgramaInscripcion';
import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { getProgramasByInscription } from 'redux/actions/programa.actions';

export const Inscripciones = () => {

  const [ showModal, setShowModal ] = useState( false );
  const [ programas, setProgramas ] = useState( [] );

  useEffect( ()=>{
    getProgramasActivos();
  }, [])

  const getProgramasActivos = () => {
    getProgramasByInscription( true )
    .then( (res) => setProgramas( res.data.programas))
    .catch( (err) => console.log(err))
  }

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card className="card-user">
            <CardHeader>
              <Row>
                <Col md="4">
                  <h5>Carreras con Inscripción Activa</h5>
                </Col>
                <Col  md="8" className="text-right">
                  <Button
                    color="primary"
                    onClick={ ()=>setShowModal(true) }>
                    Configurar
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
            { programas.length > 0 ? 
              <CarreraListInscripcion programas={programas}/>
              : <Alert color="info">
                <strong>No hay carreras con inscripción activa.</strong>
                </Alert>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>

      { showModal && 
        <ModalAddProgramaInscripcion
          showModal={showModal}
          setShowModal={ setShowModal}
          setProgramasCallback={ getProgramasActivos }
        />
      }

    </div>
  )
}
