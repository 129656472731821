import { TooltipItem } from 'components/utils/TooltipItem'
import moment from 'moment'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Table } from 'reactstrap'
import { ModalDeshabilitarInscripcion } from './ModalDeshabilitarInscripcion'

export const CarreraListInscripcion = ({programas}) => {
  
  const [ showModal, setShowModal ] = useState( false );
  const [ programaToDisable, setProgramaToDisable ] = useState( {} );

  const handleDeshabilitar = (programaId, programaNombre ) => {
    setProgramaToDisable( { id: programaId, nombre: programaNombre } );
    setShowModal( true );
  }
  return (
    <>
    <Table className="m-3" responsive striped>
        <thead className="text-secundary">
            <tr>
            <th>Código</th>
            <th>Carrera</th>
            <th>Fecha de Activación</th>
            <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            { programas.map( programa => 
            <tr key={programa.id}> 
                <td>{programa.codigo}</td>
                <td>{programa.nombre}</td>
                <td>{ moment( programa.fechaInscripcion ).format( "DD/MM/YYYY") }</td>
                <td >
                    <Link to={`inscripciones/programa/${programa.id}`}>
                        
                        <Button id={`edit-${programa.id}`} color="success" size="sm">
                          <div className="stats" >
                            <i className="far fa-eye"></i>                                            
                          </div>
                        </Button>
                        <TooltipItem target={`edit-${programa.id}`} title={"Ver"}/>
                    </Link>
                    <Button className="ml-1" id={`disable-${programa.id}`} color="danger" size="sm"
                      onClick={ () => handleDeshabilitar( programa.id, programa.nombre )}>
                      <div className="stats" >
                        <i className="far fa-window-close"></i>                                            
                      </div>
                    </Button>
                    <TooltipItem target={`disable-${programa.id}`} title={"Deshabilitar"}/>
                </td>
            </tr>)}
        </tbody>
    </Table>
    {
      showModal === true &&
    <ModalDeshabilitarInscripcion 
      programaId={programaToDisable.id}
      programaNombre={programaToDisable.nombre}
      showModal={showModal}
      setShowModal={setShowModal} />
    }
    </>
  )
}
