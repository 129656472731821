import React from 'react'
import { Button, Card, CardBody, CardHeader, Col, Modal, Row } from 'reactstrap'

export const ModalConfirmarAtendida = ({ showModal, setShowModal, funcCallback }) => {

  const handleClick = ()=>{
      funcCallback();
  }
  
  return (
    <Modal className="modal-dialog-centered "
      size="sm"
      isOpen={showModal}
      toggle={() => setShowModal(false)}>
      <Card>
        <CardHeader>
            <h6>¿Has atendido la solicitud por completo?.</h6>
        </CardHeader>
        <CardBody>
            <p>Confirma unicamente si has atendido por completo esta solicitud.</p>
            <Row>
              <Col className="text-right">
                <Button color="primary" size="sm" 
                    onClick={()=>handleClick()} style={{ backgroundColor:"#31708f"}}>Confirmar</Button>
                <Button color="danger" size="sm" onClick={()=>setShowModal(false)}> Cancelar  </Button>
              </Col>
            </Row>
        </CardBody>
      </Card>

    </Modal>
  )
}
